import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import { formatCurrency } from 'src/utils/myUtils'
import { useHistory } from 'react-router-dom'
import { useCart } from './CartContext'
import SideScreen from '../Modal/SideScreen'
import AddressModal from 'src/pages/Location/AddressModal'
import { AddressPos } from 'src/models/AddressPos'
import { Box } from '@mui/material'
import { useBrand } from 'src/pages/provider'
import { MenuItem } from 'src/models/MenuItem'
import CartItem from './CartItem'
import { useLocation } from 'react-router-dom'
import Loading from 'src/components/Loading'
import CartGroup from './CartGroupSingleStore'
import StoreChecker from './StoreChecker'
import OpenTimeCheck from 'src/components/OpenTimeCheck'

const Cart: React.FC = () => {
  const history = useHistory()
  const [showAddress, setShowAddress] = useState<boolean>(false)
  const [showOpenTimeCheck, setShowOpenTimeCheck] = useState<boolean>(false)
  const {
    cart,
    data,
    dataTotal,
    openCart,
    loading,
    valid,
    toggleCart,
    setAddress,
    prepareOrder,
    remove,
    checkBoxItem,
    checkBoxAllItems,
    updateMenuItem,
    viewingStore,
    checkStore,
    setCheckStore,
  } = useCart()
  const location = useLocation()
  const { pre } = useBrand()

  useEffect(() => {
    const rawData = localStorage.getItem('data')
    const data = rawData && JSON.parse(rawData)

    if (!data?.address?.latitude) {
      setTimeout(() => {
        setShowAddress(true)
      }, 1500)
    }
  }, [])

  const handleClick = () => {
    const path = location.pathname.split('/')
    if (path[path.length - 1] === 'thanh-toan') {
      prepareOrder()
    }
    toggleCart(false)
    history.push(`${pre}/thanh-toan`)
  }

  return (
    <>
      <SideScreen open={openCart} onClose={() => toggleCart(false)} defaultClose={true}>
        <Wrap>
          <Head>Giỏ hàng</Head>
          {data.items.length > 0 ? (
            <>
              {loading && <Loading height={300} />}
              {!loading && (
                <>
                  <Content>
                    <CartWrap>
                      {/* {data.items.map((item: MenuItem, index: number) => (
                        <CartItem
                          key={index}
                          checkBox={(value) => checkBoxItem(index, value)}
                          remove={() => remove(index)}
                          updateItem={(item) => updateMenuItem(index, item)}
                          data={item}
                        />
                      ))} */}
                      {cart.orders.map((data,index) => (
                        <CartGroup
                          key={index}
                          data={data}
                          canEdit={true}
                          openAddress={() => {setShowAddress(true)}}
                          removeItem={(index) => {
                            remove(index)
                            prepareOrder()
                          }}
                          checkBoxItem={(index, checked) => {
                            checkBoxItem(index, checked)
                            prepareOrder()
                          }}
                          checkBoxAllItems={(checked) => {
                            checkBoxAllItems(checked)
                            prepareOrder()
                          }}
                          updateMenuItem={(index, item) => {
                            updateMenuItem(index, item)
                            prepareOrder()
                          }}
                        />
                      ))}
                      
                    </CartWrap>
                  </Content>
                  <ButtonPanel>
                    {/* <More>
                      <div style={{fontSize: 14}}>Tạm tính</div>
                      <Price>{formatCurrency(dataTotal)}</Price>
                    </More> */}
                    <HightOutlinedButton
                      disabled={!valid() || loading}
                      // onClick={handleClick}
                      onClick={() => setShowOpenTimeCheck(true)}
                      style={{ height: 56, width: '100%' }}
                    >
                      Xác nhận
                    </HightOutlinedButton>
                  </ButtonPanel>
                </>
              )}
            </>
          ) : (
            <Box textAlign="center" mt={8}>
              Bạn chưa có món ăn nào trong giỏ hàng!
            </Box>
          )}
        </Wrap>
      </SideScreen>
      <AddressModal
        open={showAddress}
        onClose={() => {
          setShowAddress(false)
        }}
        onOk={(pos: AddressPos) => {
          setAddress({
            placeId: pos.placeId,
            text: pos.text,
            pos: {
              lat: pos.pos.lat,
              lng: pos.pos.lng
            }
          })
          setShowAddress(false)
        }}
      />
      <StoreChecker
        store={{
          _id: viewingStore?._id || '',
          slug: viewingStore?.slug || '',
          name: viewingStore?.name || '',
          url: viewingStore ? `/thuc-don?${viewingStore.type}=${viewingStore.slug}` : '',
        }}
        open={checkStore.open}
        onClose={() => {setCheckStore({ ...checkStore, open: false })}}
        onOk={() => {checkStore.cb()}}
      />
      <OpenTimeCheck
        open={showOpenTimeCheck}
        onClose={() => {setShowOpenTimeCheck(false)}}
        onOK={handleClick}
      />
    </>
  )
}

const Wrap = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const CartWrap = styled('div')`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    border-radius: 0;
  }
`

const Content = styled('div')({
  padding: '0 12px 12px 12px',
  height: 'calc(var(--vh, 1vh) * 100 - 140px)',
  overflowY: 'scroll',
  backgroundColor: '#f2f2f2'
})

const Head = styled('div')({
  height: 64,
  flexShrink: 0,
  fontSize: 24,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'white',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  '@media (max-width: 599px)': {
    fontSize: 16,
  }
})

const ButtonPanel = styled('div')({
  background: 'white',
  boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.1)',
  flexShrink: 0,
  padding: 10
})

const More = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1
})

const Price = styled('div')({
  fontSize: 32,
  fontWeight: 700,
  '@media (max-width: 599px)': {
    fontSize: 24,
  }
})
export default Cart
