import {useModalState} from "../../utils/hooks/useModalState"
import {IconButton, Tooltip} from "@mui/material"
import React from "react"
import {ReactComponent as DocumentCopyIcon} from "src/assets/icons/doc-copy.svg"

interface DocumentCopyPropType {
  text: string | number
}

const DocumentCopy: React.FC<DocumentCopyPropType> = ({text}) => {
  const {onClose, isOpen, onOpen} = useModalState()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text.toString())
    onOpen()
  }

  return <Tooltip
    title="Copied"
    open={isOpen}
    onClose={onClose}
    PopperProps={{
      disablePortal: true
    }}
  >
    <IconButton sx={{px: 0.65, py: 0}} onClick={copyToClipboard}>
      <DocumentCopyIcon/>
    </IconButton>
  </Tooltip>
}

export default DocumentCopy