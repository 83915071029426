import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import menuReducer from './features/menu/slice'
import brandReducer from './features/brand/slice'
import configReducer from './features/appConfig/slice'
import orderReducer from './features/order/slice'
import storeReducer from './features/store/slice'
import majorBrandReducer from './features/majorBrand/slice'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whiteList: ['order'],
}

const orderConfig = {
    key: 'order',
    version: 1,
    storage,
    whiteList: ['paymentMethod', 'customerName', 'customerPhone', 'customerEmail'],
}

const rootReducer = combineReducers({
    brand: brandReducer,
    config: configReducer,
    menu: menuReducer,
    order: orderReducer,
    store: storeReducer,
    majorBrand: majorBrandReducer
})

// const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
