import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { formatStore } from 'src/models/Store'

interface MajorBrandSliceInterface {
    logoUrl: string
    domain?: string
    slug?: string
}
const initialState: MajorBrandSliceInterface = {
    logoUrl: '',
}

export const majorBrandSlice = createSlice({
    name: 'majorBrand',
    initialState,
    reducers: {
        setLogoUrl: (state, action: PayloadAction<string>) => {
            state.logoUrl = action.payload
        },
        setDomain: (state, action: PayloadAction<string>) => {
            state.domain = action.payload
        },
        setSlug: (state, action: PayloadAction<string>) => {
            state.slug = action.payload
        },
    },
})
export const { setLogoUrl, setDomain, setSlug } = majorBrandSlice.actions
export default majorBrandSlice.reducer
