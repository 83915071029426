import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "src/components/HomeHeader";
import MyOrder from "src/components/MyOrder";

const MyOrderPage: React.FC = () => {
  return (
    <>
      <HomeHeader showTop={false} showMenu={false} hideBorder={true}/>
      <MyOrder></MyOrder>
    </>
  )
};

export default MyOrderPage;