import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import React from 'react'
import styled from 'styled-components'

interface InputProps {
    label?: string
    required?: boolean
    placeholder?: string
    value: string
    onChange?: (value: string) => void
    onBlur?: () => void
    onFocus?: () => void
    name?: string
    className?: string
    disabled?: boolean
    type?: string
    errorMsg?: string
    endIcon?: any
    startIcon?: any
}
const Wrap = styled.div`
    margin: 16px 0px;
    width: 100%;
    > p {
        margin-bottom: 4px;
        > span {
            color: #ef5467;
        }
    }
    > p.errMsg {
        margin-top: 4px;
        color: #ef5467;
    }

    &.err {
        .MuiInput-root:before {
            border-color: #ef5467;
        }
    }
`
const Input: React.FC<InputProps> = (props) => {
    return (
        <Wrap className={typeof props.errorMsg != 'undefined' ? 'err' : ''}>
            <p>
                {props.label} {props.required ? <span>*</span> : null}
            </p>
            <TextField
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                className={props.className}
                disabled={props.disabled}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                fullWidth={true}
                value={props.value}
                onChange={(e) =>
                    props.onChange ? (e.target.value != ' ' ? props.onChange(e.target.value) : props.onChange('')) : null
                }
                variant='standard'
                autoComplete='off'
                InputProps={{
                    
                    disableUnderline: true,
                    endAdornment:
                        typeof props.endIcon != 'undefined' ? (
                            <InputAdornment position='end'>{props.endIcon}</InputAdornment>
                        ) : null,
                    startAdornment:
                        typeof props.startIcon != 'undefined' ? (
                            <InputAdornment position='start'>{props.startIcon}</InputAdornment>
                        ) : null,
                }}
  
            />
            {typeof props.errorMsg != 'undefined' ? <p className='errMsg caption'>{props.errorMsg}</p> : null}
        </Wrap>
    )
}
export default Input
