import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import { Title, Wrap, Point } from "./components";
interface props{
  data: {
    name: string
    address: string
  }
}
const Address: React.FC<props> = ({data}) => {
  return (
    <Wrap>
      <Title>Địa chỉ lấy hàng</Title>
      <AddressWrap>
        <Point></Point>
        <div>
          <Store>{data.name}</Store>
          <div>
            {data.address}
          </div>
        </div>
      </AddressWrap>
    </Wrap>
  );
};

const AddressWrap = styled("div")`
  display: flex;
  margin-top: 8px;
`;

const Store = styled("div")`
  font-weight: 700;
`;
export default Address;
