import { GOOGLE_MAP_KEY } from 'src/utils/constants'
import GoogleMapReact from 'google-map-react'
import StoreIcon from 'src/assets/images/storeMarker.png'
import MarkerIcon from 'src/assets/images/markerIcon.png'
import { useEffect, useState } from 'react'
interface Point {
    lat: number
    lng: number
}
const Map = (props: { origin?: Point; destination?: Point }) => {
    const [m, setM] = useState<any>(null)
    const [d, setD] = useState<any>(null)
    const [oMarker, setOMarker] = useState<any>(null)
    const [dMarker, setDMarker] = useState<any>(null)
    const handleApiLoaded = (map?: any) => {
        if (m == null) {
            setM(map)
        }
        if (props.origin && props.destination) {
            const directionsRenderer = new google.maps.DirectionsRenderer({
                suppressMarkers: true,
            })
            const directionsService = new google.maps.DirectionsService()
            directionsService
                .route({
                    origin: props.origin,
                    destination: props.destination,
                    travelMode: google.maps.TravelMode['DRIVING'],
                })
                .then((response) => {
                    if (d != null) {
                        d.setMap(null)
                    }
                    directionsRenderer.setDirections(response)
                })
                .catch((e) => console.log('Directions request failed'))
            directionsRenderer.setMap(map)
            setD(directionsRenderer)
        } else {
            if (d != null) {
                d.setMap(null)
            }
        }
        if (props.origin) {
            const oM = new google.maps.Marker({
                position: props.origin,
                icon: StoreIcon,
                map: map,
            })
            if (oMarker != null) {
                oMarker.setMap(null)
            }
            setOMarker(oM)
        }
        if (props.destination) {
            const dM = new google.maps.Marker({
                position: props.destination,
                icon: MarkerIcon,
                map: map,
            })
            if (dMarker != null) {
                dMarker.setMap(null)
            }
            setOMarker(dM)
        }
    }
    useEffect(() => {
        if (m != null) {
            handleApiLoaded(m)
        }
    }, [props.origin, props.destination])
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                defaultCenter={
                    props.origin ||
                    props.destination || {
                        lat: 21.025007,
                        lng: 105.848294,
                    }
                }
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map)}></GoogleMapReact>
        </div>
    )
}

export default Map
