import {useAppDispatch, useAppSelector} from 'src/store/hooks'
import {PAYMENT_METHOD} from 'src/utils/constants'
import styled from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'
import { changePaymentMethod } from 'src/store/features/order/slice'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { formatCurrency } from 'src/utils/myUtils'
import { useEffect } from 'react'

const Wrap = styled.div`
    display: flex;
`
const Choice = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 16px 16px 0px 0px;
    cursor: pointer;
    flex-direction: column;
    background: #f2f4f4;
    border: 1px solid rgba(71, 95, 123, 0.4);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 6px 16px;
    text-align: center;
    width: 190px;
    overflow: hidden;
    > svg {
        font-size: 16px;
        margin: 0px 8px;
        opacity: 0.7;
    }
    > p {
        font-size: 12px;
        line-height: 16px;
        margin: auto;
    }
    &.active {
        background: #fff;
        border: 1px solid ${(props) => props.theme.tintColor};
    }
`
const Check = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    border-top: 32px solid ${(props) => props.theme.tintColor};
    border-left: 32px solid transparent;
    > svg {
        position: absolute;
        top: -28px;
        right: 4px;
        font-size: 12px;
        color: #fff;
    }
`
const BankInfo = styled.div`
    > div {
        background: ${(props) => props.theme.tintColor};
        opacity: 0.1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    margin: 16px 0px;
    padding: 16px;
`
const PaymentMethod = () => {
    const paymentMethod = useAppSelector((state) => state.order.paymentMethod)
    const orderData = useAppSelector((state) => state.order)
    const storeData = useAppSelector((state) => state.store.selectedStore)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(changePaymentMethod(PAYMENT_METHOD.CASH))
    }, [])
    return (
        <>
            <Wrap>
                <Choice
                    className={paymentMethod === PAYMENT_METHOD.CASH ? 'active' : ''}
                    onClick={() => dispatch(changePaymentMethod(PAYMENT_METHOD.CASH))}>
                    {paymentMethod === PAYMENT_METHOD.CASH ? (
                        <Check>
                            <CheckIcon />
                        </Check>
                    ) : null}
                    <LocalAtmIcon />
                    <p>Thanh toán khi nhận hàng</p>
                </Choice>
                {storeData.bankInfo ? (
                  <Choice
                    className={paymentMethod === PAYMENT_METHOD.BANKING ? 'active' : ''}
                    onClick={() => dispatch(changePaymentMethod(PAYMENT_METHOD.BANKING))}>
                      {paymentMethod === PAYMENT_METHOD.BANKING ? (
                        <Check>
                            <CheckIcon/>
                        </Check>
                      ) : null}
                      <CreditCardIcon/>
                      <p>Chuyển khoản</p>
                  </Choice>
                ) : null}
            </Wrap>
            {paymentMethod === PAYMENT_METHOD.BANKING ? (
              <BankInfo>
                  <div></div>
                  <p>{storeData.bankInfo?.accountName}</p>
                  <p>
                      Tài khoản: <strong>{storeData.bankInfo?.accountNumber}</strong>{' '}
                  </p>
                  <p>
                      Tại: Ngân hàng <strong>{storeData.bankInfo?.bankName}</strong>
                  </p>
                  <p>
                        Số tiền: <strong>{formatCurrency(orderData.priceDetail?.finalPrice || 0)}</strong>
                    </p>
                    <p>Nội dung: TÊN CỦA BẠN [số điện thoại đặt hàng]</p>
                </BankInfo>
            ) : null}
            {paymentMethod === PAYMENT_METHOD.CASH ? (
                <BankInfo>
                    <div></div>

                    <p>
                        Quý khách vui lòng chuẩn bị trước{' '}
                        <strong>{formatCurrency(orderData.priceDetail?.finalPrice || 0)}</strong> để thanh toán khi nhận
                        hàng!
                    </p>
                </BankInfo>
            ) : null}
        </>
    )
}
export default PaymentMethod
