import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Button from 'src/components/Button'
import { useAppSelector } from 'src/store/hooks'
import styled from 'styled-components'

const Wrap = styled.div`
    background: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    > div.text {
        margin: 0px 16px 32px 16px;
        text-align: center;
    }
    margin-bottom: 16px;
`
const CheckedIcon = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.tintColor};
    > svg {
        color: ${(props) => props.theme.tintColor};
        width: 24px;
        height: 24px;
    }
`
interface SuccessOrderInterface {
    onClose: () => void
}
const SuccessOrder: React.FC<SuccessOrderInterface> = (props) => {
    const storeData = useAppSelector((state) => state.store)
    return (
        <Wrap>
            <p className='heading-3'>THÀNH CÔNG</p>
            <CheckedIcon>
                <CheckRoundedIcon />
            </CheckedIcon>
            <div className='text'>
                <p>Chúng tôi sẽ xử lý yêu cầu và liên hệ với bạn trong thời gian sớm nhất</p>
            </div>
            <Button onClick={() => props.onClose()}>
                <p>OK</p>
            </Button>
        </Wrap>
    )
}
export default SuccessOrder
