import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Footer from 'src/components/Footer'
import HomeHeader from 'src/components/HomeHeader'

const Service: React.FC = () => {
  return (
    <>
      <HomeHeader showTop={true} />
      <Container>
        <Box
          my={3}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={2}
          bgcolor="white"
          height="100vh"
        >
          <Typography variant="body1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ullam, recusandae ratione
            provident quibusdam nulla dolores quae officiis ut nihil similique id fugiat dicta,
            exercitationem ducimus maiores illo ad! Blanditiis!
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default Service
