import { createGlobalStyle } from 'styled-components'
const InstantMenuGlobalStyles = createGlobalStyle`
@media screen and (min-width: 800px) {
    body {
        max-width: 370px !important;
    }
    .MuiBackdrop-root {
        width: 370px;
        margin: auto;
    }
}

`

export default InstantMenuGlobalStyles
