import React, { useEffect, useState } from 'react'
import { PAYMENT_METHOD, PAYMENT_METHOD_LABEL, PAYMENT_STATE } from 'src/utils/constants'
import { Title, Wrap } from './components'
import { Box, Stack, Typography } from '@mui/material'
import { formatCurrency } from '../../utils/myUtils'
import { green, red } from '../../utils/color'
import DocumentCopy from './DocumentCopy'
import QRCode from 'qrcode.react'
import { _getQrCode } from '../../utils/api'

interface MethodPayPropType {
  method: string
  paymentState: string
  finalPrice: number
  bankInfo: any
  addInfo: string
}

const MethodPay: React.FC<MethodPayPropType> = ({
  bankInfo,
  addInfo,
  method,
  paymentState,
  finalPrice
}) => {
  const [qrCode, setQrCode] = useState<string>()

  const Qrdata = {
    accountNo: bankInfo?.accountNumber,
    accountName: bankInfo?.accountName,
    acqId: bankInfo?.bankId,
    addInfo: addInfo,
    amount: finalPrice,
    template: 'qr_only',
    bankName: bankInfo?.bankName
  }

  const getQrCode = async () => {
    try {
      const res = await _getQrCode(Qrdata)
      setQrCode(res.data.data.qrCode)
    } catch (e) {
      console.log('generate Qr code failed!')
    }
  }

  useEffect(() => {
    if (method === PAYMENT_METHOD.BANKING && bankInfo) {
      getQrCode()
    }
  }, [method, bankInfo])
  return (
    <Wrap>
      <Title>Phương thức thanh toán</Title>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={{xs: 14, sm: 16}}>{PAYMENT_METHOD_LABEL[method]}</Typography>
        <Typography color={paymentState === PAYMENT_STATE.PAID ? green[500] : red[500]} fontSize={{xs: 14, sm: 16}}>
          {paymentState === PAYMENT_STATE.PAID ? 'Đã thanh toán' : 'Chưa thanh toán'}
        </Typography>
      </Box>
      {paymentState !== PAYMENT_STATE.PAID && bankInfo &&
        (method === PAYMENT_METHOD.BANKING ? (
          <Box mt={2.5}>
            <Typography textAlign="center" fontSize={{xs: 14, sm: 16}}>
              Vui lòng quét mã QR hoặc chuyển khoản <strong>{formatCurrency(finalPrice)}</strong>{' '}
              cho đơn hàng của bạn vào tài khoản dưới đây
            </Typography>

            <Box my={2} display="flex" justifyContent="center">
              <QRCode id="qrcode" value={qrCode || 'Vui lòng liên hệ admin!'} size={162}/>
            </Box>

            <Typography textAlign="center" fontWeight={700} mt={{ xs: 1, sm: 0 }} fontSize={{xs: 14, sm: 16}}>
              {bankInfo?.accountName}
            </Typography>

            <Stack direction="row" justifyContent="space-between" my={1}>
              <Typography fontSize={{xs: 14, sm: 16}}> Tài khoản: </Typography>
              <Box display="flex">
                <Typography bgcolor={green[200]} px={1} borderRadius={1} fontSize={{xs: 14, sm: 16}}>
                  <strong>{bankInfo?.accountNumber}</strong>
                </Typography>
                <DocumentCopy text={bankInfo?.accountNumber}/>
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between" mb={1}>
              <Typography fontSize={{xs: 14, sm: 16}}> Ngân hàng: </Typography >
              <Typography>
                <strong>{bankInfo?.bankName}</strong>
              </Typography>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={1}>
              <Typography fontSize={{xs: 14, sm: 16}}> Nội dung: </Typography>
              <Box display="flex">
                <Typography bgcolor={green[200]} px={1} borderRadius={1} fontSize={{xs: 14, sm: 16}}>
                  {addInfo}
                </Typography>
                <DocumentCopy text={addInfo} />
              </Box>
            </Stack>
          </Box>
        ) : (
          <Typography mt={1} fontSize={{xs: 14, sm: 16}}>
            Vui lòng thanh toán <strong>{formatCurrency(finalPrice)}</strong> cho nhân viên giao
            hàng khi nhận đơn
          </Typography>
        ))}
    </Wrap>
  )
}

export default MethodPay
