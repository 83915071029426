import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import HorizontalMenu from './HorizontalMenu'
import OrderItem from './OrderItem'
import { useCart } from 'src/components/Cart/CartContext'
import { _getOrder } from 'src/utils/api'
import Loading from 'src/components/Loading'
import { OutlinedButton } from 'src/components/Button/OutlinedButton'
import { Alert, Button, Snackbar } from '@mui/material'
import NavLeftImg from 'src/assets/icons/nav-left.svg'
import { useHistory, useLocation } from 'react-router-dom'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import SideScreen from '../Modal/SideScreen'
import OrderDetail from '../OrderDetail'
interface Params {
  limit: number
  offset: number
  status: string
}

const MyOrder: React.FC = () => {
  const { uuid } = useCart()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>([])
  const [end, setEnd] = useState<boolean>(false)
  const history = useHistory()
  const windowSize = useWindowSize()
  const [openOrder, setOpenOrder] = useState<boolean>(false)
  const [id, setId] = useState<string>('')
  const [lastStatus, setLastStatus] = useState<string>('new')
  const [params, setParams] = useState<Params>({
    limit: 10,
    offset: 0,
    status: 'new'
  })
  const [openSnackBar, setOpenSB] = useState<boolean>(false)
  
  const location = useLocation();

  useEffect(() => {
    (async () => {
      setLoading(true)
      const result = await getData(uuid)
      if (lastStatus !== params.status) {
        setData([...result.data])
        setLastStatus(params.status)
      } else {
        setData([...data, ...result.data])
      }
      setEnd(result.data.length === 0)
      setLoading(false)
    })()
  }, [params, uuid])

  

  const loadMore = () => {
    setParams({ ...params, offset: params.offset + params.limit })
  }

  const getData = async (uuid: string) => {
    const reqData = { ...params, uuid }
    const query = Object.entries(reqData)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    const result: any = await _getOrder(query)
    return result
  }

  const handleClickOrder = (id: string) => {
    if (windowSize && windowSize.width && windowSize.width >= 600) {
      setId(id)
      setOpenOrder(true)
    } else {
      history.push(`don-hang/${id}`)
    }
  }

  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSB(false)
  }

  return (
    <>
      <Top gapheader={`${location.pathname.includes('/don-hang') ? '1' : ''}`}>
        <Head>
          {history.action !== 'POP' && windowSize && windowSize.width && windowSize.width < 600 ? (
            <IconButton onClick={() => history.goBack()} sx={{paddingLeft: 0}}>
              <NavLeftIcon />
            </IconButton>
          ) : (
            <></>
          )}
          Đơn hàng của bạn
        </Head>
        <HorizontalMenu
          id={params.status}
          select={(id: string) => setParams({ ...params, status: id, offset: 0 })}
        />
      </Top>
      <WrapItem>
        {!loading &&
          data.map((item: any, index: number) => (
            <div key={index} onClick={() => handleClickOrder(item._id)}>
              <OrderItem data={item} />
            </div>
          ))}
        {!loading && data.length === 0 && <Empty>Bạn chưa có đơn hàng nào</Empty>}
        {!loading && !end && data.length >= 10 && (
          <OutlinedButton onClick={(e) => loadMore()}>Xem thêm</OutlinedButton>
        )}
        {loading && <Loading height={400} />}
      </WrapItem>

      <SideScreen open={openOrder} onClose={() => setOpenOrder(false)} defaultClose={false}>
        <OrderDetail
          id={id}
          onClose={(showSnackBar) => {
            setOpenOrder(false)
            setData([])
            setParams({ ...params, offset: 0 })
            if (showSnackBar) setOpenSB(true)
          }}
        />
      </SideScreen>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
          Hủy đơn thành công!
        </Alert>
      </Snackbar>
    </>
  )
}

const WrapItem = styled('div')`
  padding: 12px;
  height: calc(var(--vh, 1vh) * 100 - 118px);
  overflow-y: scroll;
  background-color: #f2f2f2;
`

const Head = styled('div')`
  font-weight: 700;
  font-size: 24px;
  height: 64px;
  background: white;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  position: relative;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Top = styled('div')<{gapheader: string}>(({gapheader = ''}) => ({
  '@media (max-width: 600px)': {
    position: 'sticky',
    top: gapheader ? '72px' : '0px',
  }
}))

const IconButton = styled(Button)`
  width: 48px;
  height: 48px;
  position: absolute;
  left: 12px;
  top: 4px;
  min-width: 48px !important;
  &:hover {
    background-color: #feede9 !important;
  }
  &:active {
    background-color: #feede9 !important;
  }
  > .MuiTouchRipple-root span {
    background-color: #feede9;
  }
`
const NavLeftIcon = styled('div')`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${NavLeftImg}');
`

const Empty = styled('div')`
  text-align: center;
  margin-top: 50px;
`
export default MyOrder
