import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BrandInterface } from 'src/models/Brand'
import { LocationInterface } from "src/models/Location"
import { _getConfig, _getHomeConfig, _getHubs } from 'src/utils/api'

interface BrandContextType {
  loading: boolean
  brand?: BrandInterface
  brandSlug: string
  pre: string
  isSub: boolean
  hubs: LocationInterface[]
}

let BrandContext = createContext<BrandContextType>(null!)
const useBrand = () => {
  return useContext(BrandContext)
}

const BrandProvider: React.FC = ({ children }) => {
  
  const [brand, setBrand] = useState<BrandInterface>()
  const [loading, setLoading] = useState<boolean>(true)
  const [brandSlug, setBrandSlug] = useState<string>('')
  const [isSub, setIsSub] = useState<boolean>(false)
  const [pre, setPre] = useState<string>('')
  const brandProvider = useBrand()
  const location = useLocation()
  const [hubs, setHubs] = useState<LocationInterface[]>([])
  

  const init = async () => {
    setLoading(true)
    let slug = ''
    const ds = process.env.REACT_APP_PLSORDER_ORIGIN?.split('/')
    const domain = ds && ds[2] ? ds[2] : process.env.REACT_APP_PLSORDER_ORIGIN_HOSTNAME
    const hostname = window.location.hostname != 'localhost' ? window.location.hostname : domain
    if (hostname !== domain) {
      const data = await _getConfig('domain=' + hostname)
      slug = data.data.slug
      setIsSub(true)
    } else {
      const brandSlug = location.pathname.split('/')
      if (brandSlug[1]) {
        slug = brandSlug[1]
      }
      setPre(`/${slug}`)
      setIsSub(false)
    }
    setBrandSlug(slug)
    const res = await _getHomeConfig(slug)
    setBrand(res.data)

    const hubsData: { data: LocationInterface[] } = await _getHubs(slug);
    hubsData.data.forEach((item: LocationInterface) => {
      if (item.stores) {
        item.numBrand = item.stores.length;
      } else {
        item.numBrand = 1;
      }

      item.dis = 0;
    });
    setHubs(hubsData.data)

    setLoading(false)
  }
  useEffect(() => {
    if (!brand) {
      init()
    }
  }, [])

  let value = {
    loading,
    brand,
    brandSlug,
    isSub,
    pre,
    hubs,
  }
  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

export default BrandProvider
export { useBrand }
