import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Title, Wrap, Price, Line } from "./components";
import CartItem from "../Cart/CartItem";
import { formatCurrency } from "src/utils/myUtils";

const ListOrder: React.FC<{ data: any }> = ({ data }) => {
  const [ok, setOk] = useState<boolean>(false);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  useEffect(() => {
    if (data) {
      if (data.store && data.store.brand) {
        data.brandName = data.store.brand.name;
      }
      let totalQuantity = 0;
      data.items.forEach((item: any) => {
        item.status = 'active';
        item.selected = true;
        totalQuantity += item.quantity;
        item.totalPerItem = item.itemPrice + item.optionPrice;
        item.total = item.itemPrice * item.quantity;
        item.name = item.itemName;
        item.brandName = data.brandName;
        item.userNote = item.note;
      });
      setTotalQuantity(totalQuantity);
      setOk(true);
    }
  }, []);

  const deliFee = data.deliveryType === 'ship' ? data.deliveryFee : 0
  return (
    <Wrap>
      <Title>Danh sách món đặt</Title>
      {ok &&
        data &&
        data.items.map((item: any, index: number) => (
          <CartItem
            key={index}
            canEdit={false}
            data={item}
            checkBox={() => {}}
            remove={() => {}}
          />
        ))}
      <TotalPanel>
        <LineStyled>
          <div>Tạm tính ({totalQuantity} món)</div>
          <Price>{formatCurrency(data.itemOriginalPrice)}</Price>
        </LineStyled>
        {
          data.promotion && <LineStyled>
          <div>Giảm giá ({data.promotion.promoCode})</div>
          <Price>-{formatCurrency(data.promotion.promotionFee)}</Price>
        </LineStyled>
        }

        {deliFee > 0 && (
          <LineStyled>
            <div>Phí giao hàng tạm tính</div>
            <Price>{formatCurrency(deliFee)}</Price>
          </LineStyled>
        )}
        <LineStyled>
          <div>Tổng tiền</div>
          <Price style={{ fontSize: 24 }}>
            {formatCurrency(data.finalPrice + deliFee)}
          </Price>
        </LineStyled>

        {data.note && <LineStyled>
          <div>Ghi chú</div>
          <div>{data.note}</div>
        </LineStyled>}

        <div>
          Vui lòng thanh toán{" "}
          <span style={{ fontWeight: 700 }}>
            {formatCurrency(data.finalPrice)}
          </span>{" "}
          khi nhận hàng
        </div>

      </TotalPanel>
    </Wrap>
  );
};

const TotalPanel = styled("div")`
  border-top: 1px solid #eee;
  padding-top: 20px;
`;
const LineStyled = styled(Line)`
  color: black;
`;
export default ListOrder;
