import ReactDOM from 'react-dom'
import App from './App'
import './style.css'
import { store } from './store'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
export var theme = {
    tintColor: '#6B0772',
    background: '#FFFFFF',
    textColor: '#475F7B',
}
let persistor = persistStore(store)
ReactDOM.render(
    <>
        <Provider store={store}>
          <App/>
        </Provider>
    </>,
    document.getElementById('root')
)
