import React, { useEffect, useState } from "react";
import PointImg from "src/assets/icons/point.svg";
import GPSImg from "src/assets/icons/gps.svg";
import MapImg from "src/assets/icons/map.svg";
import PinIcon from "src/assets/icons/pin.svg";
import Popup from "../../components/Modal/Popup";
import {
  Button,
  IconButton
} from "@mui/material";
import { styled } from "@mui/system";
import { HightOutlinedButton } from "src/components/Button/OutlinedButton";
import AutoComplete from "react-google-autocomplete";
import SMap from "src/components/SMap";
import { AddressPos } from "src/models/AddressPos";
import LocationInput from "src/components/LocationInput";
import { GoogleMapPlaceDetail } from "src/models/GoogleMapPlace";
import { useCart } from "src/components/Cart/CartContext";
import { grey, red } from "src/utils/color";
import { _getLocationDetailFromLocation } from 'src/utils/api'

const AddressModal: React.FC<any> = ({ onClose, onOk, open }) => {
  const {cart} = useCart();
  const [showMap, setShowMap] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [mapApi, setMapApi] = useState<any>();
  const [pos, setPos] = useState<AddressPos>({
    text: '',
    pos: {
      lat: 21.038841478968774,
      lng: 105.83430278169783,
    }
  });
  const handleClose = () => {
    setOpenModal(false);
    onClose(false);
  };

  useEffect(() => {
    setOpenModal(open);
    if (cart.address?.text) {
      setPos({
        placeId: cart.address.placeId,
        text: cart.address.text,
        pos: {
          lat: cart.address.latitude,
          lng: cart.address.longitude,
        }
      })
    }
  }, [open]);

  const setCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { data: location } = await _getLocationDetailFromLocation(
          `${position.coords.latitude},${position.coords.longitude}`
        )
          setPos({
            placeId: location.place_id,
            text: location.formatted_address,
            pos: {
              lat: location.geometry.location.lat,
              lng: location.geometry.location.lng,
            }
          });
        // const latlng = new mapApi.LatLng(position.coords.latitude, position.coords.longitude);
        // const geocoder = new mapApi.Geocoder
        // geocoder.geocode({
        //   'latLng': latlng
        // }, function (results: any, status: any) {
        //   if (results && results.length > 0 && results[0].formatted_address) {
        //     setPos({
        //       text: results[0].formatted_address,
        //       pos: {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //       }
        //     });
        //   }
        // });
      });
    }
  };
  
  const onPick = (data: any) => {
    setPos({
      placeId: data.place_id,
      text: data.formatted_address,
      pos: {
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lng,
      }
    })
  };

  const handleOk = () => {
    onOk(pos);
  }
  return (
    <Popup
      children={
        <>
          <div>Giúp lựa chọn cửa hàng phù hợp cũng như tính phí giao hàng chính xác hơn!</div>
          <Search>
            <WrapInput>
              <PointIcon></PointIcon>
              <LocationInput value={pos.text} placeholder={'Nhập địa chỉ'} onChange={(place: GoogleMapPlaceDetail) => {
                if (place && place.geometry && place.geometry.location) {
                  setPos({
                    placeId: place.place_id,
                    text: place.formatted_address,
                    pos: {
                      lat: place.geometry.location.lat,
                      lng: place.geometry.location.lng,
                    }
                  })
                }
              }}></LocationInput>
             <IconButton
                onClick={() => setCurrentLocation()}
                style={{ width: 45, height: 45 }}
              >
                <GPSIcon></GPSIcon>
              </IconButton>
            </WrapInput>
          </Search>
          {
            showMap && <div
              style={{
                height: 400,
                marginTop: 10,
                position: "relative",
              }}
            >
              <SMap pos={pos} onPick={(data: any) => onPick(data)} apiLoaded={(map: any, mapApi: any) => {
                setMapApi(mapApi);
              }}></SMap>
              <Marker
                style={{
                  backgroundImage: `url(${PinIcon})`,
                  width: 48,
                  height: 48,
                }}
              ></Marker>
            </div>
          }
          <MapTitle style={{display: showMap ? 'none' : 'flex'}} onClick={() => setShowMap(true)}>
            <MapIcon></MapIcon>
            <Label>Tìm trên bản đồ</Label>
          </MapTitle>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CancelButton style={{marginRight: 10}} onClick={handleClose}>Để sau</CancelButton>
            <OkButton onClick={handleOk} disabled={!pos.text}>Xong</OkButton>
          </div>
        </>
      }
      open={openModal}
      title={
        'Nhập địa chỉ của bạn'
      }
      onClose={handleClose}
      defaultClose={true}
      height={'unset'}
    />
  );
};

const MapTitle = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: 10,
});

const PointIcon = styled("div")({
  width: 30,
  height: 48,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url('${PointImg}')`,
  marginLeft: 10,
  marginRight: 5
});

const GPSIcon = styled("div")({
  width: 24,
  height: 24,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url('${GPSImg}')`,
});

const Label = styled("div")({
  textDecoration: "underline",
  color: red[500],
  marginLeft: 10,
  cursor: 'pointer'
});
const MapIcon = styled("div")({
  width: 24,
  height: 24,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url('${MapImg}')`,
});

const Search = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
});

const WrapInput = styled("div")({
  border:  `1px solid ${grey[300]}`,
  borderRadius: "8px",
  height: "48px",
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
});

const OkButton = styled(HightOutlinedButton)({
  height: 40,
  width: 126,
  cursor: 'pointer'
});

const CancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: 8,
  color: red[500],
  width: 126
});

const Marker = styled("div")({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
});

const AutoCompleteStyled = styled(AutoComplete)({
  width: '100%', 
  border: 'none', 
  fontSize: 16,
  '&:focus-visible': {
    outline: 'none'
 },
})

export default AddressModal;
