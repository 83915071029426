import React, { useMemo } from 'react'
import {styled} from '@mui/system'
import {formatCurrency} from 'src/utils/myUtils'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import CartItem from 'src/components/Cart/CartItem'
import {MenuItem} from 'src/models/MenuItem'
import {useCart} from 'src/components/Cart/CartContext'
import {grey, red} from 'src/utils/color'
import {Box, IconButton, Stack, Checkbox} from '@mui/material'
import {ReactComponent as ArrowRightIcon} from 'src/assets/images/arrowRight.svg'
import {useBrand} from 'src/pages/provider'
import {useHistory} from 'react-router-dom'
import Coupon from 'src/pages/Checkout/Coupon'
import { CartItemInterface } from 'src/models/Cart'
interface Props {
  canEdit?: boolean
  openAddress: () => void
  removeItem: (index: number) => void
  checkBoxItem: (index: number, checked: boolean) => void
  checkBoxAllItems: (checked: boolean) => void
  updateMenuItem: (index: number, item: MenuItem) => void
  data: CartItemInterface
}

const CartGroup: React.FC<Props> = ({
  canEdit,
  data,
  openAddress,
  removeItem,
  checkBoxItem,
  checkBoxAllItems,
  updateMenuItem,
}) => {
  const {setNote, cart, data: cartData, toggleCart, currentStore } = useCart()
  const history = useHistory()
  const {pre} = useBrand()

  const handleClickGroup = () => {
    history.push(
      `${pre}/thuc-don?${currentStore?.type}=${currentStore?.slug}`
    )
    toggleCart(false)
  }

  const isCheckStore = cartData.items.reduce((isCheck, item) => {
    return isCheck && item.selected
  }, true)

  return (
    <Wrap>
      <Title>
        {canEdit && (
          <CheckboxWrap>
            <Checkbox
              style={{ paddingTop: 0, paddingLeft: 0 }}
              sx={{
                color: grey[400],
                '&.Mui-checked': {
                  color: red[500]
                }
              }}
              onChange={(e) => {
                checkBoxAllItems(e.target.checked)
              }}
              checked={isCheckStore}
            />
          </CheckboxWrap>
        )}
        <Box width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Name>
                {currentStore?.name}
              </Name>
              <Address>{currentStore?.address}</Address>
            </Box>
            <IconButton>
              <ArrowRightIcon onClick={handleClickGroup}/>
            </IconButton>
          </Stack>
        </Box>
      </Title>
      {cartData.items.map((item: MenuItem, itemIndex: number) => {
        return (
          <CartItem
            key={itemIndex}
            checkBox={(value) => {checkBoxItem(itemIndex, value)}}
            remove={() => {removeItem(itemIndex)}}
            data={item}
            canEdit={canEdit}
            updateItem={(item: MenuItem) => {updateMenuItem(itemIndex, item)}}
          />
        )
      })}
      <WrapPrice>
        {/* <Note>Yêu cầu đặc biệt</Note>
        <TextareaAutosizeStyled
          aria-label="note"
          placeholder="Ghi chú"
          minRows={2}
          value={cart.orders[0]?.note}
          onChange={(e) => setNote(0, e.target.value)}
        /> */}
        
        <Line>
          <LineText>Tạm tính</LineText>
          <Price>{formatCurrency(data.totalItemPrice)}</Price>
        </Line>
        {cart.couponData && 
        <Line>
          <LineText>
            <div style={{marginRight: 8}}>Giảm giá</div>
            {cart.orderTotalDirectPromotionValue > 0 && <Coupon code={cart.couponData.code}/>}
          </LineText>
          <Price>-{formatCurrency( cart.orderTotalDirectPromotionValue > 0 ? cart.orderTotalDirectPromotionValue : 0)}</Price>
        </Line>
        }
        {cart.needShip && (
          <Line>
            <LineText>Phí giao hàng tạm tính</LineText>
            {cart.address?.text ? (
              <Price>{formatCurrency(data.shippingFeeDisplay)}</Price>
            ) : (
              <Fee
                onClick={() => {
                  openAddress()
                }}
              >
                Vui lòng nhập địa chỉ
              </Fee>
            )}
          </Line>
        )}
        <Line style={{borderTop: `1px solid ${grey[200]}`, marginTop: '8px', alignItems: 'center'}}>
          <LineText>Tổng tiền</LineText>
          <Price size={24}>
            {formatCurrency(data.totalItemPrice + data.shippingFeeDisplay)}
          </Price>
        </Line>
      </WrapPrice>
    </Wrap>
  )
}

const Wrap = styled('div')`
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 500px) {
    border-radius: 0;
  }
`

const Title = styled('div')({
  display: 'flex',
  borderBottom: `1px solid ${grey[200]}`,
  padding: '10px 0px',
  alignItems: 'center'
})

const CheckboxWrap = styled('div')({
  flexShrink: 0,
  marginRight: 5,
  alignSelf: 'flex-start',
})

const Name = styled('div')({
  fontSize: 24,
  fontWeight: 700,
  textTransform: 'capitalize',
  '@media (max-width: 599px)': {
    fontSize: 16,
    lineHeight: '24px',
  }
})

const Address = styled('div')({
  fontSize: 14
})

const WrapPrice = styled('div')({
  borderTop: `1px solid ${grey[200]}`
})

const Line = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0px',

})

const LineText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

const Fee = styled('div')({
  color: red[500],
  cursor: 'pointer',
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

const Price = styled('div')<{ size?: number }>(({ theme, size = 16 }) => ({
  fontSize: size + 'px',
  fontWeight: 700,
  '@media (max-width: 599px)': {
    fontSize: size === 16 ? 14 : 16,
  }
}))

const Note = styled('div')({
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 10,
  marginTop: 24,
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

const TextareaAutosizeStyled = styled(TextareaAutosize)({
  width: '-webkit-fill-available',
  border: `1px solid ${grey[300]}`,
  borderRadius: 8,
  padding: 5,
  resize: 'vertical',
  '&:focus-visible': {
    outline: 'none'
  }
})

export default CartGroup
