import { PICKUP_TYPE } from 'src/utils/constants'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { _getPriceDetail, _saveOrder } from 'src/utils/api'
import { arrayEqual } from 'src/utils/myUtils'
import { OrderState } from './slice'

interface Props {
    orderData: OrderState
}

export const saveOrder = createAsyncThunk('order/save_order', async (props: Props) => {
    let itemList: Array<any> = []
    props.orderData.items.forEach((item) => {
        const op = item.options.map((o) => {
            return {
                _id: o.id,
                choices: o.choices.map((c) => {
                    return {
                        _id: c.id,
                        quantity: c.quantity,
                    }
                }),
            }
        })
        let foundItem = itemList.find((o) => o.itemId === item.itemId && arrayEqual(o.options, op))
        if (foundItem) {
            foundItem.quantity += item.quantity
        } else {
            const newItem = {
                quantity: item.quantity,
                note: null,
                itemId: item.itemId,
                options: op,
            }
            itemList.push(newItem)
        }
    })
    const params = {
        customer: {
            name: props.orderData.customerName,
            phone: props.orderData.customerPhone,
            address: props.orderData.cusAddress,
            email: props.orderData.customerEmail,
        },
        note: '',
        items: itemList,
    }

    const response = await _saveOrder(params)
    return { data: response.data }
})

export const createOrder = createAsyncThunk('order/create_order', async (props: Props) => {
    let itemList: Array<any> = []
    props.orderData.items.forEach((item) => {
        const op = item.options.map((o) => {
            return {
                _id: o.id,
                choices: o.choices.map((c) => {
                    return {
                        _id: c.id,
                        quantity: c.quantity,
                    }
                }),
            }
        })
        let foundItem = itemList.find((o) => o.itemId === item.itemId && arrayEqual(o.options, op))
        if (foundItem) {
            foundItem.quantity += item.quantity
        } else {
            const newItem = {
                quantity: item.quantity,
                note: null,
                itemId: item.itemId,
                options: op,
            }
            itemList.push(newItem)
        }
    })
    const params =
        props.orderData.pickupType == PICKUP_TYPE.SHIPPING
            ? {
                  customer: {
                      name: props.orderData.customerName,
                      phone: props.orderData.customerPhone,
                      address: props.orderData.customerAddress?.formatted_address,
                      email: props.orderData.customerEmail,
                  },
                  paymentMethod: props.orderData.paymentMethod,
                  dropPoint: {
                      lat: props.orderData.customerAddress?.geometry.location.lat,
                      lng: props.orderData.customerAddress?.geometry.location.lng,
                  },
                  note: props.orderData.note,
                  items: itemList,
                  deliveryTime: props.orderData.time,
              }
            : {
                  customer: {
                      name: props.orderData.customerName,
                      phone: props.orderData.customerPhone,
                      address: props.orderData.customerAddress?.formatted_address,
                      email: props.orderData.customerEmail,
                  },
                  paymentMethod: props.orderData.paymentMethod,
                  note: props.orderData.note,
                  items: itemList,
                  pickupTime: props.orderData.time,
              }

    const response = await _saveOrder(params)
    return { data: response.data }
})

export const getOrderPriceDetail = createAsyncThunk('order/get_price_detail', async (props: Props) => {
    let itemList: Array<any> = []
    props.orderData.items.forEach((item) => {
        const op = item.options.map((o) => {
            return {
                _id: o.id,
                choices: o.choices.map((c) => {
                    return {
                        _id: c.id,
                        quantity: c.quantity,
                    }
                }),
            }
        })
        let foundItem = itemList.find((o) => o.itemId === item.itemId && arrayEqual(o.options, op))
        if (foundItem) {
            foundItem.quantity += item.quantity
        } else {
            const newItem = {
                quantity: item.quantity,
                note: null,
                itemId: item.itemId,
                options: op,
            }
            itemList.push(newItem)
        }
    })

    const params =
        props.orderData.pickupType == PICKUP_TYPE.SHIPPING && props.orderData.customerAddress
            ? {
                  items: itemList,
                  dropPoint: {
                      lat: props.orderData.customerAddress?.geometry.location.lat,
                      lng: props.orderData.customerAddress?.geometry.location.lng,
                  },
              }
            : {
                  items: itemList,
              }

    const response = await _getPriceDetail(params)
    return { data: response.data }
})
