import styled from 'styled-components'
const BG1 = styled.div`
    position: fixed;
    width: 463px;
    height: 463px;
    left: -400.73px;
    background: #ffba00;
    mix-blend-mode: normal;
    opacity: 0.05;
    border-radius: 112px;
    transform: rotate(-30deg);
`
const BG2 = styled.div`
    position: fixed;
    width: 463px;
    height: 463px;
    left: 622px;
    background: #ffba00;
    mix-blend-mode: normal;
    opacity: 0.05;
    border-radius: 112px;
    transform: rotate(-30deg);
`
const BG3 = styled.div`
    position: fixed;
    width: 240px;
    height: 240px;
    left: 294.08px;
    top: 594.08px;
    background: ${(props) => props.theme.tintColor};
    mix-blend-mode: normal;
    opacity: 0.05;
    border-radius: 58.0562px;
    transform: rotate(-30deg);
`
const BG4 = styled.div`
    position: fixed;
    width: 240px;
    height: 240px;
    right: -100.73px;
    top: 594.08px;
    background: ${(props) => props.theme.tintColor};
    mix-blend-mode: normal;
    opacity: 0.05;
    border-radius: 58.0562px;
    transform: rotate(-30deg);
`
const Background = () => {
    return (
        <>
            <BG1 />
            <BG2 />
            <BG3 />
            <BG4 />
        </>
    )
}
export default Background
