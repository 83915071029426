import React from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import MenuItem from 'src/pages/InstantSell/Menu/MenuItem'
import FloatingButton from 'src/components/Button/FloatingButton'
import { formatCurrency } from 'src/utils/myUtils'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useEffect } from 'react'
import { fetchMenuItems } from 'src/store/features/menu/thunks'
import { fetchStoreInfo } from 'src/store/features/store/thunks'
import Header from 'src/components/Header'
import InstantMenuGlobalStyles from '../instantSell'
const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    flex: 1;
    padding: 16px;
    position: relative;
    padding-top: 72px;
`
const CategoryLabel = styled.p`
    opacity: 0.4;
    margin-top: 16px;
    text-transform: uppercase;
    font-weight: bold !important;
`
const ButtonStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    p,
    svg {
        color: #fff;
    }
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            margin-left: 4px;
        }
    }
`
const Menu: React.FC = () => {
    const history = useHistory()
    const location = useLocation<string>()
    const query = queryString.parse(location.search)
    const menu = useAppSelector((state) => state.menu)
    const storeName = useAppSelector((state) => state.store.selectedStore.name)
    const orderItems = useAppSelector((state) => state.order.items)
    const dispatch = useAppDispatch()
    let price = 0
    let countOrder = 0
    orderItems.forEach((item) => {
        countOrder += item.quantity
        if (item.price) {
            price += item.price * item.quantity
        }
    })
    useEffect(() => {
        dispatch(
            fetchMenuItems(
                typeof query.s === 'string'
                    ? 's=' + query.s
                    : typeof query.c === 'string'
                    ? 'c=' + query.c
                    : typeof query.i === 'string'
                    ? 'i=' + query.i
                    : ''
            )
        )
        dispatch(
            fetchStoreInfo(
                typeof query.s === 'string'
                    ? 's=' + query.s
                    : typeof query.c === 'string'
                    ? 'c=' + query.c
                    : typeof query.i === 'string'
                    ? 'i=' + query.i
                    : ''
            )
        )
    }, [])
    return (
        <>
            <InstantMenuGlobalStyles />
            <Header />
            <Wrap>
                <p className='heading-3' style={{ margin: '8px 0px' }}>
                    Đặt món
                </p>
                <p style={{ margin: '16px 0px 24px' }}>Chọn & đặt món nhanh từ {storeName}!</p>
                {menu.category.map((c, index) => {
                    return (
                        <div key={index}>
                            {menu.category.length > 1 ? (
                                <CategoryLabel className='caption'>
                                    {index + 1}. {c.name}
                                </CategoryLabel>
                            ) : (
                                <CategoryLabel className='caption'>{c.name}</CategoryLabel>
                            )}
                            {c.items.map((item, index) => {
                                return <MenuItem key={index} item={item} categoryId={c.id} />
                            })}
                        </div>
                    )
                })}

                {orderItems.length > 0 ? <div style={{ minHeight: 122 }} /> : null}
            </Wrap>
            <FloatingButton shouldDisplay={countOrder > 0} onClick={() => history.push('/order/customer-info')}>
                <p className='caption'>{countOrder} món</p>
                <ButtonStyle>
                    <p className='heading-3'>{formatCurrency(price)}</p>
                    <div>
                        <p className='heading-3'>Xác nhận</p>
                        <ArrowForwardRoundedIcon />
                    </div>
                </ButtonStyle>
            </FloatingButton>
        </>
    )
}
export default Menu
