import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import CartItem from 'src/components/Cart/CartItem'
import { formatCurrency } from 'src/utils/myUtils'
import { ORDER_STATUS } from 'src/utils/constants'

const OrderItem: React.FC<any> = ({ data }) => {
  const [ok, setOk] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [orderStatus, setOrderStatus] = useState<any>({})

  useEffect(() => {
    let orderStatus: any = {}
    ORDER_STATUS.forEach((item: any) => (orderStatus[item.id] = item.name))
    setOrderStatus({ ...orderStatus })
    if (data) {
      if (data.store && data.store.brand) {
        data.brandName = data.store.brand.name
      }
      data.items.forEach((item: any) => {
        item.status = 'active'
        item.selected = true
        item.totalPerItem = item.itemPrice + item.optionPrice
        item.total = item.itemPrice * item.quantity
        item.name = item.itemName
        item.brandName = data.brandName
        item.userNote = item.note
      })
      setOk(true)
    }
  }, [])

  return (
    <Wrap>
      <Title>
        {data.hub && data.hub.name}
        {!data.hub &&
          data.store &&
          data.store.name &&
          `${data?.store?.brand?.name} - ${data.store.name}`}
        <Status>{orderStatus[data.state]}</Status>
      </Title>
      {ok &&
        data &&
        data.items.map((item: any, index: number) => {
          if (open || index < 1)
            return (
              <CartItem
                canEdit={false}
                data={item}
                checkBox={() => {}}
                remove={() => {}}
                key={`order_item_${item._id}`}/>
            )
        })}
      {!open && data.items.length > 1 && (
        <More
          onClick={(e) => {
            // setOpen(true)
            // e.stopPropagation()
          }}
        >
          {data.items.length - 1} Món khác
        </More>
      )}
      <Sum>
        Tổng tiền
        <Num>
          {formatCurrency(data.finalPrice + (data.deliveryType === 'ship' ? data.deliveryFee : 0))}
        </Num>
      </Sum>
    </Wrap>
  )
}

const Wrap = styled('div')`
  padding: 0px 12px;
  background: white;
  border-radius: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  @media (max-width: 500px) {
    margin: 0px -12px 12px;
    border-radius: 0;
  }
`
const Title = styled('div')`
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 0px;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  justify-content: space-between;
  @media (max-width: 599px) {
    font-size: 16px;
    lint-height: 24px;
  }
`

const Status = styled('div')`
  color: #f05123;
  border-radius: 16px;
  font-size: 12px;
  border: 1px solid #f05123;
  font-weight: normal;
  padding: 5px 10px;
  text-align: center;
  min-width: 100px;
`

const More = styled('div')`
  color: #f05123;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-top: 1px solid #eee;
  padding: 16px;
  cursor: pointer;
  margin-left: 10px;
`

const Sum = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
  padding: 16px 0px;
  @media (max-width: 599px) {
    font-size: 14px;
  }
`

const Num = styled('div')`
  font-weight: 700;
  font-size: 24px;
  @media (max-width: 599px) {
    font-size: 16px;
  }
`
export default OrderItem
