import React from 'react'
import {styled} from '@mui/system'
import {formatCurrency} from 'src/utils/myUtils'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import CartItem from 'src/components/Cart/CartItem'
import {MenuItem} from 'src/models/MenuItem'
import {useCart} from 'src/components/Cart/CartContext'
import {grey, red} from 'src/utils/color'
import {Box, IconButton, Stack} from '@mui/material'
import {ReactComponent as ArrowRightIcon} from 'src/assets/images/arrowRight.svg'
import {useBrand} from 'src/pages/provider'
import {useHistory} from 'react-router-dom'
import Coupon from 'src/pages/Checkout/Coupon'
import { Cart, CartItemInterface } from 'src/models/Cart'
import { MENUITEM_STATUS } from 'src/utils/constants'
interface Props {
  data: CartItemInterface
  cart: Cart
  index: number
  openAddress: () => void
}

const CartGroup: React.FC<Props> = ({ data, openAddress, cart, index }) => {
  const {setNote, currentStore } = useCart()
  const history = useHistory()
  const {pre} = useBrand()

  const handleClickGroup = () => {
    history.replace(
      `${pre}/thuc-don?${currentStore?.type}=${currentStore?.slug}`
    )
  }

  return (
    <Wrap>
      <Title>
        <Box width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Name>
                {currentStore?.name}
              </Name>
              <Address>{currentStore?.address}</Address>
            </Box>
            <IconButton>
              <ArrowRightIcon onClick={handleClickGroup}/>
            </IconButton>
          </Stack>
        </Box>
      </Title>
      {data.items.map((item: MenuItem, itemIndex: number) => {
        item.status = MENUITEM_STATUS.ACTIVE
        return (
          <CartItem
            key={itemIndex}
            checkBox={(value) => {}}
            remove={() => {}}
            data={item}
            canEdit={false}
            updateItem={(item: MenuItem) => {}}
          />
        )
      })}
      <WrapPrice>
        <Note>Yêu cầu đặc biệt</Note>
        <TextareaAutosizeStyled
          aria-label="note"
          placeholder="Ghi chú"
          minRows={2}
          value={data.note}
          onChange={(e) => setNote(index, e.target.value)}
        />
        
        {/* <Line style={{marginTop: 24}}>
          <LineText>Tạm tính</LineText>
          <Price>{formatCurrency(data.totalItemPrice)}</Price>
        </Line>
        {data.coupon_applied && 
        <Line>
          <LineText>
            <div style={{marginRight: 8}}>Giảm giá</div>
            {data.promotionPrice > 0 && <Coupon code={data.coupon}/>}
          </LineText>
          <Price>-{formatCurrency( data.promotionPrice > 0 ? data.promotionPrice : 0)}</Price>
        </Line>
        }
        {cart.needShip && (
          <Line>
            <LineText>Phí giao hàng tạm tính</LineText>
            {cartData.address?.text ? (
              <Price>{formatCurrency(data.shippingFeeDisplay)}</Price>
            ) : (
              <Fee
                onClick={() => {
                  openAddress()
                }}
              >
                Vui lòng nhập địa chỉ
              </Fee>
            )}
          </Line>
        )}
        <Line style={{
          borderTop: `1px solid ${grey[200]}`,
          padding: '16px 0px 0px 0px',
          marginTop: '8px', alignItems: 'center',
        }}>
          <LineText>Tổng tiền</LineText>
          <Price size={24}>
            {formatCurrency(data.totalPrice + (cart.needShip ? data.shippingFeeDisplay : 0))}
          </Price>
        </Line> */}
      </WrapPrice>
    </Wrap>
  )
}

const Wrap = styled('div')`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 500px) {
    margin: 0px -12px 16px;
    border-radius: 0;
  }
`

const Title = styled('div')({
  display: 'flex',
  borderBottom: `1px solid ${grey[200]}`,
  padding: '0 0 10px 0px',
  alignItems: 'center'
})

const Name = styled('div')({
  fontSize: 24,
  fontWeight: 700,
  textTransform: 'capitalize',
  '@media (max-width: 599px)': {
    fontSize: 16,
    lineHeight: '24px',
  }
})

const Address = styled('div')({
  fontSize: 14
})

const WrapPrice = styled('div')({
  borderTop: `1px solid ${grey[200]}`
})

const Line = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0px',

})

const LineText = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

const Fee = styled('div')({
  color: red[500],
  cursor: 'pointer'
})

const Price = styled('div')<{ size?: number }>(({ theme, size = 16 }) => ({
  fontSize: size + 'px',
  fontWeight: 700
}))

const Note = styled('div')({
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 10,
  marginTop: 24
})

const TextareaAutosizeStyled = styled(TextareaAutosize)({
  width: '-webkit-fill-available',
  border: `1px solid ${grey[300]}`,
  borderRadius: 8,
  padding: 5,
  resize: 'vertical',
  '&:focus-visible': {
    outline: 'none'
  }
})

export default CartGroup
