import { OptionChoice } from "./OptionChoice";
import {_format as _choiceFormat} from './OptionChoice'
export interface ItemOption {
    id: string
    _id: string
    name: string
    maxCount: number
    minCount: number
    choices: Array<OptionChoice>
}

export const _format = (data:any):ItemOption => {
    return {
        id: data._id,
        _id: data._id,
        name: data.name,
        maxCount: data.maxCount,
        minCount: data.minCount,
        choices: data.choices.map((c:any) => _choiceFormat(c))
    }
}