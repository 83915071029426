import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import HomeHeader from 'src/components/HomeHeader'
import BrandItem from './BrandItem'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { HomeConfig, subBrand } from 'src/models/Brand'
import SwiperStyled from './SwiperSlide'
import { UI } from 'src/utils/constants'
import Footer from 'src/components/Footer'
import Loading from 'src/components/Loading'
import CartBar from './CartBar'
import { useBrand } from '../provider'

const OrderNow = styled.div`
  background-color: ${(props) => props.theme.mainColor};
  color: white;
  padding: 50px 10px;
  text-align: center;
`
const OrderNowBrand = styled.div`
  background-color: #edf8e8;
  padding: 60px 10px;
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`
const Intro = styled.div`
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const WrapImg = styled.div`
  position: absolute;
  top: -120px;
  left: 0;

  @media (max-width: 899px) {
    left: 300px;
  }
  @media (max-width: 700px) {
    left: 200px;
  }
  @media (max-width: 600px) {
    left: 100px;
  }
  @media (max-width: 500px) {
    left: 50px;
  }
  @media (max-width: 400px) {
    left: 0px;
  }
`

const Home: React.FC = () => {
  const [homeData, setHomeData] = useState<HomeConfig[]>([])
  const [subBrand, setSubBrand] = useState<subBrand[]>([])
  const { brand, loading } = useBrand()

  useEffect(() => {
    if (brand) {
      setSubBrand(brand.subBrands)
      setHomeData(
        brand.homeConfig?.sort((con1: HomeConfig, con2: HomeConfig) => con1.pos - con2.pos)
      )
    }
  }, [brand])
  return (
    <>
      <HomeHeader showTop={true} showMenu={true} subBrand={subBrand} />
      {loading && <Loading height={400} />}
      {!!homeData &&
        homeData.map((config: HomeConfig, index: number) => {
          const ui: string = config.ui

          if (ui === UI.BANNER && !!config.banners.length) {
            return <SwiperStyled key={index} index={index} banners={config.banners} />
          }

          if (ui === UI.BOTH) {
            return (
              <BrandItem
                configIndex={index}
                key={index}
                link={config.link}
                btnLabel={config.btnLabel}
                btnLink={config.btnLink}
                banners={config.banners}
                title={config.title}
                menuItems={config.menuItems}
              />
            )
          }
          return <p key={index}></p>
        })}

      {/* <OrderNowBrand style={{ marginTop: '60px' }}>
        <Container>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              item
              md={7}
              order={{ xs: 2, md: 1 }}
              sx={{ paddingTop: { xs: '200px !important', md: '0px !important' } }}
            >
              <Title>Thích món này thèm món kia?</Title>
              <Intro>Chọn nhiều món từ nhiều thương hiệu với 1 lượt đặt!</Intro>
              <HightOutlinedButton variant="outlined" style={{ marginTop: 16, minWidth: 260 }}>
                Đặt món ngay
              </HightOutlinedButton>
            </Grid>
            <Grid item md={5} order={{ xs: 1, md: 2 }} style={{ position: 'relative' }}>
              <WrapImg>
                <img src={BrandImg} alt={BrandImg} />
              </WrapImg>
            </Grid>
          </Grid>
        </Container>
      </OrderNowBrand>
      <OrderNow>
        <Title>Thích món này thèm món kia?</Title>
        <Intro>Chọn nhiều món từ nhiều thương hiệu với 1 lượt đặt!</Intro>
        <HightOutlinedButton style={{ marginTop: 20 }}>Đặt món ngay</HightOutlinedButton>
      </OrderNow> */}

      <Footer />
      <CartBar />
    </>
  )
}

export default Home
