import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { PICKUP_TYPE } from 'src/utils/constants'
import { changePickupType } from 'src/store/features/order/slice'
const Wrap = styled.div`
    margin: 16px;
    padding: 8px 4px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    display: flex;
`
const Choice = styled.div`
    width: 50%;
    padding: 8px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    &.active {
        background: ${(props) => props.theme.tintColor};
        > p {
            color: #fff;
            font-weight: bold;
        }
    }
`
const PickupType = () => {
    const pickupType = useAppSelector((state) => state.order.pickupType)
    const dispatch = useAppDispatch()
    const changeHandler = (type: string): void => {
        if (pickupType != type) {
            dispatch(changePickupType(type))
        }
    }
    return (
        <Wrap>
            <Choice
                className={pickupType === PICKUP_TYPE.SHIPPING ? 'active' : ''}
                onClick={() => changeHandler(PICKUP_TYPE.SHIPPING)}>
                <p>Giao hàng</p>
            </Choice>
            <Choice
                className={pickupType === PICKUP_TYPE.TAKEAWAY ? 'active' : ''}
                onClick={() => changeHandler(PICKUP_TYPE.TAKEAWAY)}>
                <p>Tự đến lấy</p>
            </Choice>
        </Wrap>
    )
}
export default PickupType
