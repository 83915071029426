import { fetchStoreInfo } from './../store/thunks'
import { formatStore, StoreInterface } from 'src/models/Store'
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { GoogleMapPlaceDetail } from 'src/models/GoogleMapPlace'
import { OrderItem } from 'src/models/OrderItem'
import { PAYMENT_METHOD, PICKUP_TYPE } from 'src/utils/constants'
import { arrayEqual, objectsEqual } from 'src/utils/myUtils'
import { createOrder, getOrderPriceDetail, saveOrder } from './thunks'
import { PriceDetail } from 'src/models/PriceDetail'
import produce from 'immer'
export interface OrderState {
    loading: boolean
    saveSuccess: boolean
    saveFail: boolean
    items: Array<OrderItem>
    pickupType: string
    customerName: string
    customerPhone: string
    cusAddress: string
    customerAddress: GoogleMapPlaceDetail | null
    customerEmail: string
    note: string
    paymentMethod: string
    priceDetail?: PriceDetail
    time: string
}

const initialState: OrderState = {
    loading: false,
    saveSuccess: false,
    saveFail: false,
    items: [],
    pickupType: PICKUP_TYPE.SHIPPING,
    customerName: '',
    customerPhone: '',
    cusAddress: '',
    customerAddress: null,
    customerEmail: '',
    note: '',
    paymentMethod: PAYMENT_METHOD.CASH,
    time: new Date().toISOString(),
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        changePaymentMethod: (state, action: PayloadAction<string>) => {
            state.paymentMethod = action.payload
        },
        changePickupType: (state, action: PayloadAction<string>) => {
            state.pickupType = action.payload
        },
        changeCustomerName: (state, action: PayloadAction<string>) => {
            state.customerName = action.payload
        },
        changeCustomerPhone: (state, action: PayloadAction<string>) => {
            state.customerPhone = action.payload
        },
        changeCustomerAddress: (state, action: PayloadAction<GoogleMapPlaceDetail>) => {
            state.customerAddress = action.payload
        },
        changeCusAddress: (state, action: PayloadAction<string>) => {
            state.cusAddress = action.payload
        },
        changeCustomerEmail: (state, action: PayloadAction<string>) => {
            state.customerEmail = action.payload
        },
        changeNote: (state, action: PayloadAction<string>) => {
            state.note = action.payload
        },
        changeTime: (state, action: PayloadAction<string>) => {
            state.time = action.payload
        },
        addItem: (state, action: PayloadAction<OrderItem>) => {
            state.items = state.items.length
                ? state.items.find(
                      (item) => item.itemId === action.payload.itemId && item.categoryId === action.payload.categoryId
                  )
                    ? arrayEqual(
                          state.items.find(
                              (item) =>
                                  item.itemId === action.payload.itemId && item.categoryId === action.payload.categoryId
                          )?.options || [],
                          action.payload.options
                      )
                        ? state.items.map((item) =>
                              item.itemId === action.payload.itemId && item.categoryId === action.payload.categoryId
                                  ? {
                                        itemId: item.itemId,
                                        note: action.payload.note,
                                        options: action.payload.options,
                                        quantity: item.quantity + action.payload.quantity,
                                        price: action.payload.price,
                                        name: action.payload.name,
                                        categoryId: action.payload.categoryId,
                                    }
                                  : item
                          )
                        : state.items.concat({
                              itemId: action.payload.itemId,
                              note: action.payload.note,
                              options: action.payload.options,
                              quantity: action.payload.quantity,
                              price: action.payload.price,
                              name: action.payload.name,
                              categoryId: action.payload.categoryId,
                          })
                    : state.items.concat({
                          itemId: action.payload.itemId,
                          note: action.payload.note,
                          options: action.payload.options,
                          quantity: action.payload.quantity,
                          price: action.payload.price,
                          name: action.payload.name,
                          categoryId: action.payload.categoryId,
                      })
                : [
                      {
                          itemId: action.payload.itemId,
                          note: action.payload.note,
                          options: action.payload.options,
                          quantity: action.payload.quantity,
                          price: action.payload.price,
                          name: action.payload.name,
                          categoryId: action.payload.categoryId,
                      },
                  ]
        },
        removeItem: (state, action: PayloadAction<{ itemId: string; categoryId?: string | null }>) => {
            let done = false
            state.items = state.items
                .map((i) => {
                    if (
                        i.itemId === action.payload.itemId &&
                        ((typeof action.payload.categoryId != 'undefined' &&
                            i.categoryId === action.payload.categoryId) ||
                            typeof action.payload.categoryId === 'undefined') &&
                        !done
                    ) {
                        done = true
                        return {
                            itemId: i.itemId,
                            note: i.note,
                            options: i.options,
                            quantity: i.quantity - 1,
                            price: i.price,
                            name: i.name,
                            categoryId: i.categoryId,
                        }
                    }
                    return i
                })
                .filter((i) => i.quantity > 0)
        },
        editItem: (state, action: PayloadAction<{ currentItem: OrderItem; newItem: OrderItem }>) => {
            state.items = state.items
                .map((i) => {
                    console.log(
                        objectsEqual(
                            {
                                itemId: action.payload.currentItem.itemId,
                                cateId: action.payload.currentItem.categoryId || '',
                                options: action.payload.currentItem.options,
                            },
                            {
                                itemId: i.itemId,
                                cateId: i.categoryId || '',
                                options: i.options.concat(),
                            }
                        )
                    )
                    if (
                        objectsEqual(
                            {
                                itemId: action.payload.currentItem.itemId,
                                cateId: action.payload.currentItem.categoryId || '',
                                options: action.payload.currentItem.options,
                            },
                            {
                                itemId: i.itemId,
                                cateId: i.categoryId || '',
                                options: i.options.concat(),
                            }
                        )
                    ) {
                        return action.payload.newItem
                    }
                    return i
                })
                .filter((i) => i.quantity > 0)
        },
        clearState: (state) => {
            state.loading = false
            state.saveFail = false
            state.saveSuccess = false
            state.items = []
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(saveOrder.pending, (state) => {
                state.loading = true
            })
            .addCase(saveOrder.fulfilled, (state) => {
                state.loading = false
                state.saveFail = false
                state.saveSuccess = true
            })
            .addCase(saveOrder.rejected, (state) => {
                state.loading = false
                state.saveFail = true
                state.saveSuccess = false
            })
            .addCase(createOrder.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrder.fulfilled, (state) => {
                state.loading = false
                state.saveFail = false
                state.saveSuccess = true
            })
            .addCase(createOrder.rejected, (state) => {
                state.loading = false
                state.saveFail = true
                state.saveSuccess = false
            })
            .addCase(getOrderPriceDetail.fulfilled, (state, { payload }) => {
                state.priceDetail = payload.data.data
            })
    },
})

export const {
    changePaymentMethod,
    changePickupType,
    changeCustomerName,
    changeCustomerPhone,
    changeCusAddress,
    changeCustomerAddress,
    changeCustomerEmail,
    changeNote,
    clearState,
    addItem,
    removeItem,
    editItem,
    changeTime,
} = orderSlice.actions

export default orderSlice.reducer
