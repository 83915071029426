import {AxiosResponse} from 'axios'
import {_get, _post, _postBankInfo, _put} from './request'
import {HubPageInterface} from 'src/models/HubPage'
import {LocationInterface} from 'src/models/Location'

export const _getLocationDetailFromId = (id: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/map/v1/geocode?placeID=${id}`)
}

export const _getLocationDetailFromLocation = (letlng: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/map/v1/geocode?latlng=${letlng}`)
}

export const _getLocationFromAddress = (
  query: string,
  session: string
): Promise<AxiosResponse<any>> => {
  return _get(`/api/map/v1/places/autocomplete?query=${query}&sessiontoken=${session}`)
}

export const _getDistance = (
  startLatlng: string,
  endLatlng: string,
): Promise<AxiosResponse<any>> => {
  return _get(`/api/map/v1/routes/distancematrix?startLatlng=${startLatlng}&endLatlng=${endLatlng}`)
}

export const _fetchMenuItems = (param: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v1/menu?${param}`)
}

export const _saveOrder = (orderData: any): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/v1/order`, orderData)
}

export const _getPriceDetail = (orderData: any): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/v1/order/check-pricing`, orderData)
}

export const _fetchStoreInfo = (param: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v1/stores?${param}`)
}

export const _getSchedule = (storeId: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v1/schedules?storeId=${storeId}&datetime=${new Date().toISOString()}`)
}

export const _getMajorBrandSiteConfig = (param: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v1/brands/major?${param}`)
}

export const _getConfig = (param: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v1/brands/details?${param}`)
}

export const _getHubs = (brandSLug: string): Promise<{ data: LocationInterface[] }> => {
  return _get(`/api/brand/v2/hubs?brandSlug=${brandSLug}`)
}

export const _getHubPage = (webId: string, hugSlug: string): Promise<{ data: HubPageInterface }> => {
  return _get(`/api/brand/v2/hubs/${hugSlug}/${webId}`)
}

export const _getBrandPage = (webId: string, brandSlug: string): Promise<{ data: HubPageInterface }> => {
  return _get(`/api/brand/v2/brands/${brandSlug}/${webId}`)
}

export const _getStorePage = (webId: string, storeSlug: string): Promise<{ data: HubPageInterface }> => {
  return _get(`/api/brand/v2/stores/${storeSlug}/${webId}`)
}

export const _getHomeConfig = (brandSLug: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v2/brands/${brandSLug}/home-configs`)
}

export const _calShippingFee = (data: any): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/v2/orders/prepare-order`, data)
}

// export const _prepareOrder = (data: any): Promise<AxiosResponse<any>> => {
//   return _post(`/api/brand/v2/orders/prepare2`, data)
// }

export const _prepareOrder = (data: any): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/plsorder/v1/orders/prepare2`, data)
}

// export const _createOrder = (data: any): Promise<AxiosResponse<any>> => {
//   return _post(`/api/brand/v2/orders`, data)
// }

export const _createOrder = (data: any): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/plsorder/v1/orders/create`, data)
}

export const checkItem = (data: { ids: string[] }): Promise<AxiosResponse<any>> => {
  return _post(`/api/brand/v2/orders/check-item`, data)
}

export const _getOrder = (params: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v2/orders/list?${params}`)
}

export const _getOrderDetail = (id: string, uuid: string): Promise<AxiosResponse<any>> => {
  return _get(`/api/brand/v2/orders/${id}?id=${uuid}`)
}

export const _destroyOrder = (id: string, body: any): Promise<AxiosResponse<any>> => {
  return _put(`/api/brand/v2/orders/destroy/${id}`, body)
}

export const _getQrCode = (body: any): Promise<AxiosResponse<any>> => {
  return _postBankInfo(`generate`, body)
}
