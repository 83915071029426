import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import Checkbox from '@mui/material/Checkbox'
import { formatCurrency } from 'src/utils/myUtils'
import TrashImg from 'src/assets/icons/trash.svg'
import SideScreen from 'src/components/Modal/SideScreen'
import ItemDetail from 'src/components/ItemDetail'
import ItemDetailProvider from 'src/components/ItemDetail/ItemDetailContext'
import { MenuItem } from 'src/models/MenuItem'
import { ItemOption } from 'src/models/ItemOption'
import { OptionChoice } from 'src/models/OptionChoice'
import { Box, Button } from '@mui/material'
import Img from 'src/components/Img'
import { MENUITEM_STATUS } from 'src/utils/constants'
import { red } from 'src/utils/color'
interface Props {
  canEdit?: boolean
  data: MenuItem
  checkBox: (value: boolean) => void
  remove: () => void
  updateItem?: (item: MenuItem) => void
}
const CartItem: React.FC<Props> = ({ canEdit = true, data, remove, checkBox, updateItem }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [itemText, setItemText] = useState<string>('')
  useEffect(() => {
    if (data && data.options) {
      let choiceNames: string[] = []
      data.options.forEach((option: ItemOption) => {
        if (!option.choices) return
        option.choices.forEach((c: OptionChoice) => {
          if (c.quantity) choiceNames.push(c.name)
        })
      })
      setItemText(choiceNames.join(' / '))
    }
  }, [data])

  const handleClick = () => {
    if (data.status !== MENUITEM_STATUS.ACTIVE) return;
    if (!canEdit) return
    setOpen(true)
  }
  return (
    <Wrap style={{opacity: data.status === MENUITEM_STATUS.ACTIVE ? '1' : '0.4'}}>
      {canEdit && (
        <CheckboxWrap>
          <Checkbox
            style={{ paddingTop: 0, paddingLeft: 0 }}
            sx={{
              color: '#a9a9a9',
              '&.Mui-checked': {
                color: '#F05123'
              }
            }}
            disabled={data.status !== MENUITEM_STATUS.ACTIVE}
            onChange={(e) => checkBox(e.target.checked)}
            checked={data.selected}
          />
        </CheckboxWrap>
      )}
      <Img style={{
        width: 56,
        height: 56,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 12,
        cursor: 'pointer'
      }} onClick={handleClick} src={data.image} />
      <Content onClick={handleClick}>
        <DetailWrap>
          <Detail>
            <Brand>{data?.brand?.name ?? data?.store?.brand?.name}</Brand>
            <Name>{data.itemName ? data.itemName : data.name}</Name>
            <Option>{itemText}</Option>
            <Note>{data?.userNote}</Note>
          </Detail>
          {canEdit && (
            <DelWrap>
              <IconButton
                onClick={(e) => {
                  remove()
                  e.stopPropagation()
                }}
              >
                <TrashIcon />
              </IconButton>
            </DelWrap>
          )}
        </DetailWrap>
        <PriceWrap>
          <NumWrap>
            <Num>{data.quantity}</Num> x {formatCurrency(data?.totalPerItem)}
          </NumWrap>
        </PriceWrap>
        
        {data.status !== MENUITEM_STATUS.ACTIVE && <Disable>Sản phẩm không còn hoạt động</Disable>}
      </Content>
      <SideScreen open={open} defaultClose={false}>
        <ItemDetailProvider isCreate={false} item={data}>
          <ItemDetail
            onClose={() => setOpen(false)}
            updateItem={(item: MenuItem) => {
              if (updateItem) updateItem(item)
            }}
          />
        </ItemDetailProvider>
      </SideScreen>
    </Wrap>
  )
}

const Wrap = styled('div')({
  display: 'flex',
  padding: '16px 0px'
})

const CheckboxWrap = styled('div')({
  flexShrink: 0,
  marginRight: 5
})

const Content = styled('div')({
  cursor: 'pointer',
  flexGrow: 1
})

const DetailWrap = styled('div')({
  display: 'flex'
})

const Detail = styled('div')({
  flexGrow: 1
})

const Brand = styled('div')({
  fontSize: 12,
  color: '#39B54A'
})

const Name = styled('div')({
  fontWeight: 'bold',
  // marginTop: 5,
  '@media (max-width: 599px)': {
    fontSize: 14,
    lineHeight: '20px',
  },
})

const Option = styled('div')({
  fontSize: 14,
  color: '#909090',
  // marginTop: 10
})

const Note = styled('div')({
  fontSize: 12,
  color: '#909090',
  // marginTop: 10
})

const PriceWrap = styled('div')({
  display: 'flex',
  fontWeight: 700,
  marginTop: 16
})

const Num = styled('div')({
  borderRadius: 12,
  color: 'white',
  background: 'black',
  padding: '0px 10px',
  marginRight: 5
})

const NumWrap = styled('div')({
  flexGrow: 1,
  display: 'flex',
  '@media (max-width: 599px)': {
    fontSize: 14,
    lineHeight: '20px',
  }
})

const DelWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 1
})

const TrashIcon = styled('div')({
  backgroundImage: `url(${TrashImg})`,
  width: 24,
  height: 24,
  cursor: 'pointer'
})

const Disable = styled('div')`
  font-size: 14px;
  margin-top: 10px;
  color: #F05123;
`

const IconButton = styled(Button)`
  width: 48px;
  height: 48px;
  min-width: 48px !important;
  &:hover {
    background-color: #feede9 !important;
  }
  &:active {
    background-color: #feede9 !important;
  }
  > .MuiTouchRipple-root span {
    background-color: #feede9;
  }
`
export default CartItem
