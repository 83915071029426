import React, { useRef, useEffect, MutableRefObject, Ref } from 'react'

export default function useClickOutSide(ref: any, onOutSide = () => {}) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutSide()
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}
