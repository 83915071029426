import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FloatingButton from 'src/components/Button/FloatingButton'
import DatePicker from 'src/components/DateTimePicker/DatePicker'
import TimePicker from 'src/components/DateTimePicker/TimePicker'
import BrandHeader from 'src/components/Header/BrandHeader'
import Input from 'src/components/Input'
import LocationInput from 'src/components/LocationInput'
import Map from 'src/components/Map'
import FullScreen from 'src/components/Modal/FullScreen'
import HalfScreen from 'src/components/Modal/HalfScreen'
import Popup from 'src/components/Modal/Popup'
import SelectInput from 'src/components/SelectInput'
import { OrderItem } from 'src/models/OrderItem'
import {
    changeCustomerAddress,
    changeCustomerEmail,
    changeCustomerName,
    changeCustomerPhone,
    changeNote,
    changeTime,
    clearState,
    removeItem,
} from 'src/store/features/order/slice'
import { changeStore } from 'src/store/features/store/slice'
import { createOrder, saveOrder } from 'src/store/features/order/thunks'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { PICKUP_TYPE } from 'src/utils/constants'
import { arrayEqual, formatCurrency, objectsEqual, validateEmail, validatePhoneNumber } from 'src/utils/myUtils'
import styled from 'styled-components'
import EditItem from './EditItem'
import PaymentMethod from './PaymentMethod'
import PickupType from './PickupType'
import PriceDetail from './PriceDetail'
import SuccessOrder from './SuccessOrder'

const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    flex: 1;
    position: relative;
    padding-top: 56px;
`
const MapSection = styled.div`
    width: 100%;
    padding-top: 50%;
    position: relative;
    > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`
const ItemSection = styled.div`
    background: #fff;
    padding: 16px;
    color: #475f7b;
    div.d-f {
        display: flex;
        align-items: center;
    }
    > div.header {
        margin: -16px -16px 0px -16px;
        > svg {
            padding: 16px;
        }
    }
`
const ContactSection = styled.div`
    margin: 24px 16px;
    > div {
        margin: 16px 0px;
    }
    svg {
        opacity: 0.4;
        font-size: 18px;
    }
`
const PaymentSection = styled.div`
    margin: 24px 16px;
`
const Loading = styled.div`
    margin: -5px;
    display: flex;
    justify-content: center;
    > div {
        width: 24px !important;
        height: 24px !important;
        color: #fff;
    }
`
const ButtonStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    p,
    svg {
        color: #fff;
    }
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            margin-left: 4px;
        }
    }
`
const DateTime = styled.div`
    margin-top: 16px;
    > div {
        display: flex;
        > span {
            width: 16px;
        }
    }
`
const Header = styled.div`
    display: flex;
    align-items: center;
    margin-left: -5px;
    padding: 16px;
    background: #fafafa;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99;
`
interface ErrInterface {
    name: boolean
    phone: boolean
    email: boolean
    address: boolean
}
const OrderInfo: React.FC = () => {
    const history = useHistory()
    const orderData = useAppSelector((state) => state.order)
    const storeData = useAppSelector((state) => state.store)
    const menuData = useAppSelector((state) => state.menu)
    const dispatch = useAppDispatch()
    const [detailPopupOpen, setDetailPopupOpen] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<OrderItem | null>(null)
    const [err, setErr] = useState<ErrInterface>({
        name: false,
        phone: false,
        email: false,
        address: false,
    })
    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [disableOrderButton, setDisableOrderButton] = useState<boolean>(true)
    let countOrder = 0
    let orderList: Array<OrderItem> = []
    const itemData = editItem
        ? menuData.category.find((c) => c.id === editItem.categoryId)?.items.find((i) => i.id === editItem.itemId)
        : null
    orderData.items.forEach((item) => {
        countOrder += item.quantity
        orderList.push(item)
    })
    useEffect(() => {
        setErr({
            name: false,
            phone: false,
            email: false,
            address: false,
        })
        setDisableOrderButton(!(orderData.customerAddress && orderData.customerName && orderData.customerPhone))
    }, [orderData.customerAddress, orderData.customerName, orderData.customerPhone])
    useEffect(() => {
        if (countOrder === 0) {
            history.goBack()
        }
    }, [countOrder])
    useEffect(() => {
        if (orderData.saveSuccess) {
            setOpenSuccess(true)
        }
    }, [orderData])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const orderHandler = () => {
        let nextErr = err
        if (!orderData.customerAddress) {
            nextErr.address = true
        }
        if (!orderData.customerName) {
            nextErr.name = true
        }
        if (!orderData.customerPhone || !validatePhoneNumber(orderData.customerPhone)) {
            nextErr.phone = true
        }
        if (orderData.customerEmail && !validateEmail(orderData.customerEmail)) {
            nextErr.email = true
        }
        if (objectsEqual(nextErr, err)) {
            dispatch(createOrder({ orderData: orderData }))
        }
    }

    const selectStoreHandler = (value: string) => {
        const st = storeData.storeList.find((s) => s.id === value)
        if (!st) {
            return
        }
        dispatch(changeStore(st))
    }

    return (
        <>
            <BrandHeader />
            <Wrap>
                <PickupType />
                <MapSection>
                    <div className='limit-width'>
                        {useMemo(
                            () => (
                                <Map
                                    origin={
                                        storeData.selectedStore
                                            ? {
                                                  lat: storeData.selectedStore?.lat,
                                                  lng: storeData.selectedStore?.lng,
                                              }
                                            : undefined
                                    }
                                    destination={
                                        orderData.customerAddress && orderData.pickupType == PICKUP_TYPE.SHIPPING
                                            ? {
                                                  lat: orderData.customerAddress?.geometry.location.lat,
                                                  lng: orderData.customerAddress?.geometry.location.lng,
                                              }
                                            : undefined
                                    }
                                />
                            ),
                            [orderData.customerAddress, orderData.pickupType]
                        )}
                    </div>
                </MapSection>
                <ContactSection>
                    {orderData.pickupType === PICKUP_TYPE.SHIPPING ? (
                        <p className='heading-3'>Giao hàng</p>
                    ) : (
                        <p className='heading-3'>Thông tin lấy hàng</p>
                    )}
                    {orderData.pickupType === PICKUP_TYPE.SHIPPING && (
                        <LocationInput
                            label='Địa chỉ giao hàng'
                            placeholder='Số 1 ngõ 234 Cầu Giấy, quận Cầu Giấy, Hà Nội'
                            value={orderData.customerAddress?.formatted_address}
                            onChange={(value) => dispatch(changeCustomerAddress(value))}
                            errorMsg={err.address ? 'Vui lòng chọn địa chỉ nhận hàng.' : undefined}
                        />
                    )}
                    {/* <SelectInput
                        value={storeData.selectedStore?._id || ''}
                        label={orderData.pickupType === PICKUP_TYPE.SHIPPING ? 'Giao từ cơ sở' : 'Nhận tại cơ sở'}
                        option={storeData.storeList.map((store) => {
                            return {
                                value: store._id,
                                name: store.address,
                            }
                        })}
                        onSelect={selectStoreHandler}
                    /> */}
                    <DateTime>
                        <p>Thời gian giao hàng</p>
                        <div>
                            <DatePicker
                                date={new Date(orderData.time)}
                                onChange={(time: string) => dispatch(changeTime(time))}
                                endIcon={<CalendarTodayRoundedIcon style={{ opacity: 0.2 }} />}
                            />
                            <span />
                            <TimePicker
                                date={new Date(orderData.time)}
                                onChange={(time: string) => dispatch(changeTime(time))}
                                endIcon={<ScheduleRoundedIcon style={{ opacity: 0.2 }} />}
                            />
                        </div>
                    </DateTime>
                    <Input
                        required
                        value={orderData.customerName}
                        onChange={(value) => dispatch(changeCustomerName(value))}
                        label={orderData.pickupType === PICKUP_TYPE.SHIPPING ? 'Người nhận' : 'Người lấy'}
                        placeholder='Nguyễn Văn A'
                        name='fullname'
                        endIcon={<PersonOutlineRoundedIcon />}
                        errorMsg={
                            err.name
                                ? orderData.pickupType === PICKUP_TYPE.SHIPPING
                                    ? 'Vui lòng nhập tên người nhận'
                                    : 'Vui lòng nhập tên người lấy'
                                : undefined
                        }
                    />
                    <Input
                        required
                        value={orderData.customerPhone}
                        onChange={(value) => dispatch(changeCustomerPhone(value))}
                        label='Điện thoại'
                        placeholder='0987 654 321'
                        name='phone'
                        type='tel'
                        endIcon={<PhoneEnabledOutlinedIcon />}
                        errorMsg={err.phone ? 'Số điện thoại không hợp lệ, vui lòng kiểm tra lại.' : undefined}
                    />
                    <Input
                        value={orderData.customerEmail}
                        onChange={(value) => dispatch(changeCustomerEmail(value))}
                        label='Email'
                        placeholder='mail@domain.com'
                        name='email'
                        endIcon={<EmailOutlinedIcon />}
                        errorMsg={err.email ? 'Email không hợp lệ, vui lòng kiểm tra lại.' : undefined}
                    />
                    <Input
                        value={orderData.note}
                        onChange={(value) => dispatch(changeNote(value))}
                        label='Ghi chú'
                        name='note'
                        endIcon={<StickyNote2OutlinedIcon />}
                    />
                </ContactSection>
                <PaymentSection>
                    <p className='heading-3'>Hình thức thanh toán</p>
                    <PaymentMethod />
                </PaymentSection>
                <ItemSection>
                    <p className='heading-3'>Món chọn</p>
                    {orderList.map((item, index) => (
                        <ItemOrder
                            key={index}
                            item={item}
                            onEdit={(item) => {
                                setEditItem(item)
                                setDetailPopupOpen(true)
                            }}
                        />
                    ))}
                    <div
                        style={{
                            borderBottom: ' 1px solid rgb(89 89 89 / 20%)',
                            marginRight: 57,
                            paddingBottom: 16,
                            cursor: 'pointer',
                        }}
                        onClick={() => history.goBack()}>
                        <p style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold' }}>
                            Đặt thêm món
                        </p>
                    </div>
                    <PriceDetail />
                </ItemSection>
                <div style={{ minHeight: 122 }} />
                {disableOrderButton ? (
                    <FloatingButton shouldDisplay={true} btnStyle={{ borderRadius: 16 }} onClick={orderHandler}>
                        <ButtonStyle>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <p style={{ color: '#fff', marginBottom: 5, lineHeight: '17px' }}>{countOrder} món</p>
                                <p className='heading-3'>{formatCurrency(orderData.priceDetail?.finalPrice || 0)}</p>
                            </div>
                            <div>
                                <p className='heading-3' style={{ opacity: 0.2, lineHeight: '18px' }}>
                                    ĐẶT HÀNG
                                </p>
                                <ArrowForwardRoundedIcon style={{ opacity: 0.2 }} />
                            </div>
                        </ButtonStyle>
                    </FloatingButton>
                ) : (
                    <FloatingButton shouldDisplay={true} btnStyle={{ borderRadius: 16 }} onClick={orderHandler}>
                        {orderData.loading ? (
                            <ButtonStyle>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <p style={{ color: '#fff', marginBottom: 5, lineHeight: '17px' }}>
                                        {countOrder} món
                                    </p>
                                    <p className='heading-3'>
                                        {formatCurrency(orderData.priceDetail?.finalPrice || 0)}
                                    </p>
                                </div>
                                <div>
                                    <p className='heading-3' style={{ lineHeight: '18px' }}>
                                        ĐẶT HÀNG
                                    </p>
                                    <CircularProgress size={24} style={{ marginLeft: 16 }} />
                                </div>
                            </ButtonStyle>
                        ) : (
                            <ButtonStyle onClick={orderHandler}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <p style={{ color: '#fff', marginBottom: 5, lineHeight: '17px' }}>
                                        {countOrder} món
                                    </p>
                                    <p className='heading-3'>
                                        {formatCurrency(orderData.priceDetail?.finalPrice || 0)}
                                    </p>
                                </div>
                                <div>
                                    <p className='heading-3' style={{ lineHeight: '18px' }}>
                                        ĐẶT HÀNG
                                    </p>
                                    <ArrowForwardRoundedIcon />
                                </div>
                            </ButtonStyle>
                        )}
                    </FloatingButton>
                )}
                <Popup
                    open={openSuccess}
                    onClose={() => {
                        setOpenSuccess(false)
                    }}>
                    <div style={{ backgroundColor: '#fff' }}>
                        <SuccessOrder
                            onClose={() => {
                                dispatch(clearState())
                            }}
                        />
                    </div>
                </Popup>
                <HalfScreen open={detailPopupOpen} onClose={() => setDetailPopupOpen(false)}>
                    <>
                        <Header>
                            <CloseRoundedIcon
                                onClick={() => setDetailPopupOpen(false)}
                                style={{ fontSize: 24, color: '#475f7b', marginRight: 12 }}
                            />
                            <p className='heading-3'>Sửa món</p>
                        </Header>
                        {editItem && itemData && (
                            <EditItem
                                currentItem={editItem}
                                item={itemData}
                                onClose={() => setDetailPopupOpen(false)}
                            />
                        )}
                    </>
                </HalfScreen>
            </Wrap>
        </>
    )
}

const ItemOrder: React.FC<{ item: OrderItem; onEdit?: (item: OrderItem) => void }> = (props) => {
    const ItemWrap = styled.div`
        display: flex;
        margin: 16px 0px;
        > div {
            padding-bottom: 9px;
        }
        p.delete {
            color: #e73948;
            cursor: pointer;
        }
        .item-name {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 70%;
        }
        .caption {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 4px;
        }
    `
    const Edit = styled.div`
        border: 1px solid ${(props) => props.theme.tintColor};
        border-radius: 4px;
        padding: 3px 10px;
        cursor: pointer;
        > p {
            color: ${(props) => props.theme.tintColor};
        }
    `
    let choiceOrder = ''
    if (props.item.options && props.item.options.length > 0) {
        props.item.options.forEach((op, oi) =>
            op.choices.forEach((c, ci) => {
                if (!(oi === 0 && ci === 0)) {
                    choiceOrder += ' + '
                }
                if (op.maxCount === 1) {
                    choiceOrder += c.name
                } else {
                    choiceOrder += c.quantity + 'x' + c.name
                }
            })
        )
    }
    const dispatch = useAppDispatch()
    return (
        <>
            <ItemWrap>
                <div
                    style={{ paddingRight: 16, borderBottom: ' 1px solid rgb(89 89 89 / 20%)' }}
                    onClick={() => dispatch(removeItem({ itemId: props.item.itemId }))}>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>{props.item.quantity}</span>{' '}
                        <span style={{ opacity: 0.5 }}>x</span>
                    </p>
                </div>
                <div
                    style={{
                        flex: 1,
                        overflow: ' hidden',
                        marginRight: 16,
                        borderBottom: ' 1px solid rgb(89 89 89 / 20%)',
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className='item-name'>{props.item.name}</p>
                        <p>{formatCurrency(props.item.price * props.item.quantity || 0)}</p>
                    </div>
                    <p className='caption' style={{ opacity: 0.5 }}>
                        {choiceOrder}
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Edit onClick={() => props.onEdit && props.onEdit(props.item)}>
                        <p>Sửa</p>
                    </Edit>
                    <div style={{ marginTop: 9 }} onClick={() => dispatch(removeItem({ itemId: props.item.itemId }))}>
                        <p className='delete'>Xóa</p>
                    </div>
                </div>
            </ItemWrap>
        </>
    )
}

export default OrderInfo
