import { GOOGLE_MAP_KEY } from 'src/utils/constants'
import GoogleMapReact, { Coords } from 'google-map-react'
import SmallPinIcon from 'src/assets/icons/small-pin.svg'
import PinIcon from 'src/assets/icons/pin.svg'
import { styled } from "@mui/system";
import { LocationInterface } from 'src/models/Location';

const GMap: React.FC<{
    data: LocationInterface[],
    select: Function,
    hub?: LocationInterface,
    myLocation?: {lat: number, lng: number},
}> = ({ data, select, hub, myLocation }) => {
    // const [currentPosition, setCurrentPosition] = useState<Coords>({
    //     lat: 59.955413,
    //     lng: 30.337844
    // });

    const currentPosition: Coords = {
        lat: 21.0278,
        lng: 105.8342,
    }

    if (hub) {
        currentPosition.lat = hub.latitude
        currentPosition.lng = hub.longitude
    } else if (myLocation) {
        currentPosition.lat = myLocation.lat
        currentPosition.lng = myLocation.lng
    } else if (data[0]) {
        currentPosition.lat = data[0].latitude
        currentPosition.lng = data[0].longitude
    }

    // useEffect(() => {
    //     if (data.length > 0) {
    //         setCurrentPosition({
    //             lat: data[0].latitude,
    //             lng: data[0].longitude
    //         })
    //     }
        
    // }, [data])
    // useEffect(() => {
    //     if (myLocation) {
    //         setTimeout(() => {
    //             setCurrentPosition({
    //                 lat: myLocation.lat,
    //                 lng: myLocation.lng
    //             })
    //         },1000)
            
    //     }
    // }, [myLocation])
    
    // useEffect(() => {
    //     if (hub) {
    //         setCurrentPosition({
    //             lat: hub.latitude,
    //             lng: hub.longitude
    //         })
    //     }
    // }, [hub])

    return (
        <GoogleMapReact
            
            bootstrapURLKeys={{key: GOOGLE_MAP_KEY}}
            defaultCenter={currentPosition}
            center={currentPosition}
            defaultZoom={16}
            options={{ zoomControlOptions: { position: 7 } }}
        >
            {data.map((item: LocationInterface, index: number) => <MarkerComponent
                key={index}
                lat={item.latitude}
                lng={item.longitude}
                selected={item._id === hub?._id}
                select={() => select(item, index)}
                index={index}
            />)}

            {myLocation && <MyLocation lat={myLocation.lat} lng={myLocation.lng}></MyLocation>}
        </GoogleMapReact>
    )
}
const Marker = styled('div')({
    backgroundRepeat: 'no-repeat',
    webkitTransform: 'translate(-50%,-50%)',
    msTransform: 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)',
})
    
const MyLocationPoint = styled('div')({
    width: 15,
    height: 15,
    background: '#39B54A',
    borderRadius: '50%',
    border: '2px solid white',
    outline: '8px solid #39b54a7d'
});
const MyLocation = ({lat, lng} : {lat: number, lng: number}) => <MyLocationPoint/>
const MarkerComponent = ({ selected, select, index }: { lat:number, lng: number, selected?: boolean, select: Function, index: number}) => 
    <Marker onClick={() => select(index)} style={{backgroundImage: `url(${selected ? PinIcon : SmallPinIcon})`, width: selected ? '48px' : '24px', height: selected ? '48px' : '24px'}}></Marker>;
export default GMap
