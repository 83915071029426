import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
const Wrap = styled.div`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 99;
    height: 96px;
    display: flex;
    align-items: center;
    > div {
        width: 100%;
        align-items: center;
        display: flex;
        padding: 0px 150px;
        > img {
            height: 48px;
            margin-right: 64px;
            cursor: pointer;
        }
        > p {
            margin: 0px 24px;
            cursor: pointer;
        }
    }
`
const NavHeader: React.FC<{
    logo: string
    onClickOrder?: () => void
    onClickHome?: () => void
    onClickIntro?: () => void
}> = ({ logo, onClickOrder, onClickHome, onClickIntro }) => {
    return (
        <>
            <Wrap>
                <div className='container'>
                    <img src={logo} alt='logo' onClick={onClickHome} />
                    <p onClick={onClickHome}>Trang chủ</p>
                    <p onClick={onClickOrder}>Đặt hàng</p>
                    <p onClick={onClickIntro}>Giới thiệu</p>
                </div>
            </Wrap>
            <div style={{ height: 102 }} />
        </>
    )
}
export default NavHeader
