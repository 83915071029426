import CustomerInfo from 'src/pages/InstantSell/CustomerInfo'
import InstantMenu from 'src/pages/InstantSell/Menu'
import OrderInfo from 'src/pages/OrderInfo'
import OrderInfoPC from 'src/pages/OrderInfoPc'
import BrandSite from 'src/pages/BrandSite'
import BrandSitePC from 'src/pages/BrandSitePC'
import Home from 'src/pages/Home'
import Location from 'src/pages/Location'
import Wrapper from 'src/pages/Wrapper'
import Checkout from 'src/pages/Checkout'
import OrderSuccess from 'src/pages/OrderSuccess'
import MyOrderPage from 'src/pages/MyOrderPage'
import OrderDetailPage from 'src/pages/OrderDetailPage'
import Service from 'src/pages/Service'
import HomeWrapper from 'src/pages/HomeWrapper'
interface Route {
  path?: string
  component: any
  componentPC?: any
}
const routes: Array<Route> = [
  {
    path: '/menu',
    component: InstantMenu
  },
  {
    path: '/order/customer-info',
    component: CustomerInfo
  },
  {
    path: '/:majorBrand/:brand/order-information',
    component: OrderInfo,
    componentPC: OrderInfoPC
  },
  {
    path: '/:brand/order-information',
    component: OrderInfo,
    componentPC: OrderInfoPC
  },
  {
    path: '/',
    component: HomeWrapper,
    componentPC: HomeWrapper
  },
  {
    path: '/don-hang',
    component: MyOrderPage
  },
  {
    path: '/don-hang/:id',
    component: OrderDetailPage
  },
  {
    path: '/thanh-toan',
    component: Checkout
  },
  {
    path: '/dat-hang-thanh-cong',
    component: OrderSuccess
  },
  {
    path: '/:brandSlug',
    component: HomeWrapper,
    componentPC: HomeWrapper
  },
  {
    path: '/:brandSlug/ban-do',
    component: Location,
    componentPC: Location
  },
  {
    path: '/:brandSlug/thanh-toan',
    component: Checkout
  },

  {
    path: '/:brandSlug/dat-hang-thanh-cong',
    component: OrderSuccess
  },
  {
    path: '/:brandSlug/don-hang',
    component: MyOrderPage
  },
  {
    path: '/:brandSlug/don-hang/:id',
    component: OrderDetailPage
  },
  {
    path: '/:brandSlug/thuc-don',
    component: Wrapper,
    componentPC: Wrapper
  },

  {
    path: '/:majorBrand',
    component: BrandSite,
    componentPC: BrandSitePC
  },
  {
    path: '/:brandSlug/service',
    component: Service,
    componentPC: Service
  },
  {
    path: '/:brandSlug/service-2',
    component: Service,
    componentPC: Service
  },
  {
    path: '/:brandSlug/service-3',
    component: Service,
    componentPC: Service
  },
  {
    path: '/:brandSlug/service-4',
    component: Service,
    componentPC: Service
  }
]

export default routes
