import React, { Dispatch, useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { subBrand } from 'src/models/Brand'
import LeftSideScreen from '../Modal/LeftSideScreen'
import {
  Avatar,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as CloseIcon } from 'src/assets/icons/Union.svg'
import { useBrand } from 'src/pages/provider'
import { Item } from '.'
import { ButtonStyled } from './userPopup'
import { ReactComponent as LogOutIcon } from 'src/assets/icons/log-out.svg'
import { ReactComponent as MyOrderIcon } from 'src/assets/icons/my-order.svg'
import { ReactComponent as MyUserIcon } from 'src/assets/icons/user.svg'
import SideScreen from '../Modal/SideScreen'
import MyOrder from '../MyOrder'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import StoreChecker from 'src/components/Cart/StoreChecker'

const DialogTitleStyled = styled(DialogTitle)({
  padding: '16px 20px 16px 12px !important',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)'
})

interface HamburgerMenuProps {
  open: boolean
  setOpen: Dispatch<React.SetStateAction<boolean>>
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ open, setOpen }) => {
  const location = useLocation()
  const history = useHistory()
  const windowSize = useWindowSize()
  const { brand, pre } = useBrand()
  const [openMyOrder, setOpenMyOder] = useState<boolean>(false)
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)
  const [selectBrand, setSelectBrand] = useState<subBrand | undefined>()

  const handleClickUser = () => {
    if (windowSize && windowSize.width && windowSize.width >= 600) {
      setOpenMyOder(true)
    } else {
      history.push(`${pre}/don-hang`)
    }
  }
  const handleCloseMenu = () => {
    setOpen(false)
  }
  const handleSelectBrand = (e: any, brand: subBrand) => {
    e.preventDefault()
    setSelectBrand(brand)
    setOpenStoreCheck(true)
  }

  return (
    <LeftSideScreen open={open} width={356} onClose={() => setOpen(false)} defaultClose={true}>
      <DialogTitleStyled>
        <Stack direction="row" justifyContent="space-between">
          <Link to={`${pre}`}>
            <img
              width="auto"
              height="100%"
              style={{ maxHeight: 32 }}
              src={brand?.logo}
              alt={brand?.name}
            />
          </Link>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>
      </DialogTitleStyled>

      <DialogContent sx={{ '>div': { borderTop: 'none' } }}>
        {/* <ButtonStyled>
          <Avatar
            alt=""
            src={require('../../assets/images/avatar.svg').default}
            sx={{ width: 56, height: 56, border: '1px solid #00000033' }}
          />
          <Box textAlign="start">
            <Typography variant="body1" fontWeight="bold" color={'#F05123'}>
              Đăng nhập
            </Typography>
            <Typography fontSize={12}>để có trải nghiệm tốt hơn</Typography>
          </Box>
        </ButtonStyled> */}

        {/* <ButtonStyled>
          <MyUserIcon />
          <Typography>Tài khoản</Typography>
        </ButtonStyled> */}

        <ButtonStyled onClick={handleClickUser} sx={{ mb: 4 }}>
          <MyOrderIcon />
          <Typography>Đơn hàng</Typography>
        </ButtonStyled>

        <Divider sx={{ mx: 3 }} />

        <Stack direction="column" spacing={4} px={3} mt={4} mb={15}>
          <Item style={{ margin: 0 }}>
            <Link
              className={['/', pre].includes(location.pathname) ? 'active' : ''}
              to={`${pre}`}
              onClick={handleCloseMenu}
            >
              Trang chủ
            </Link>
          </Item>

          <Item>
            <Link
              className={location.pathname.includes(`${pre}/ban-do`) ? 'active' : ''}
              to={`${pre}/ban-do`}
            >
              Tất cả điểm bán
            </Link>
          </Item>
          {!!brand?.subBrands &&
            brand?.subBrands.map((brand: subBrand, index: number) => (
              <Item key={index}>
                <Link
                  className={location.search.includes(brand.slug as string) ? 'active' : ''}
                  to={`${pre}/thuc-don?b=` + brand.slug}
                  onClick={(e) => {
                    handleSelectBrand(e, brand)
                  }}
                >
                  {brand.name}
                </Link>
              </Item>
            ))}
          <Divider />
          <Item>
            <Link
              className={location.pathname === `${pre}/service` ? 'active' : ''}
              to={`${pre}/service`}
              onClick={handleCloseMenu}
            >
              Mua nhượng quyền
            </Link>
          </Item>
          <Item>
            <Link
              className={location.pathname === `${pre}/service-2` ? 'active' : ''}
              to={`${pre}/service-2`}
              onClick={handleCloseMenu}
            >
              Cấp nhượng quyền
            </Link>
          </Item>
          <Item>
            <Link
              className={location.pathname === `${pre}/service-3` ? 'active' : ''}
              to={`${pre}/service-3`}
              onClick={handleCloseMenu}
            >
              Chính sách
            </Link>
          </Item>
          <Item>
            <Link
              className={location.pathname === `${pre}/service-4` ? 'active' : ''}
              to={`${pre}/service-4`}
              onClick={handleCloseMenu}
            >
              Điều kiện
            </Link>
          </Item>
        </Stack>

        {/* <ButtonStyled>
          <LogOutIcon />
          <Typography>Đăng xuất</Typography>
        </ButtonStyled> */}

        <SideScreen open={openMyOrder} onClose={() => setOpenMyOder(false)} defaultClose={true}>
          <MyOrder />
        </SideScreen>
      </DialogContent>

      {selectBrand && <StoreChecker
        open={openStoreCheck}
        store={{
          _id: selectBrand._id || '',
          name: selectBrand.name,
          slug: selectBrand.slug || '',
          url: `${pre}/thuc-don?b=${selectBrand.slug}`
        }}
        onOk={() => {
          setOpen(false)
          history.push(`${pre}/thuc-don?b=${selectBrand.slug}`)
        }}
        onClose={() => {
          setOpenStoreCheck(false)
        }}
      />}
    </LeftSideScreen>
  )
}

export default HamburgerMenu
