import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import HalfScreen from 'src/components/Modal/HalfScreen'
import { StoreInterface } from 'src/models/Store'
import { changeStore } from 'src/store/features/store/slice'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { calculateDistance } from 'src/utils/myUtils'
import styled from 'styled-components'

const Wrap = styled.div`
    display: flex;
    align-items: center;
    > svg {
        font-size: 14px;
        margin-right: 4px;
        color: #040004;
        opacity: 0.5;
    }
    > p {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 20px;
    }
    > div {
        cursor: pointer;
        > p {
            white-space: nowrap;
            color: ${(props) => props.theme.tintColor};
        }
    }
`
const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    background: #fafafa;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    > p {
        text-align: center;
        width: 100%;
        margin-left: -36px;
        text-transform: uppercase;
    }
`
const Stores: React.FC = (props) => {
    const [popupOpen, setPopupOpen] = useState(false)
    const store = useAppSelector((state) => state.store)
    const dispatch = useAppDispatch()
    return (
        <>
            <Wrap>
                <LocationOnOutlinedIcon />
                <p className='caption'>{store.selectedStore.address}</p>
                {store.storeList.length - 1 > 0 && (
                    <div onClick={() => setPopupOpen(true)}>
                        <p className='caption'>{`${store.storeList.length - 1} cơ sở khác >`}</p>
                    </div>
                )}
            </Wrap>
            <HalfScreen open={popupOpen} onClose={() => setPopupOpen(false)} height={90}>
                <>
                    <Header>
                        <CancelRoundedIcon
                            onClick={() => setPopupOpen(false)}
                            style={{ fontSize: 28, zIndex: 9, marginRight: 12 }}
                        />
                        <p className='heading-3'>{`${store.storeList.length} cơ sở`}</p>
                    </Header>
                    {store.storeList.map((store, index) => (
                        <Store
                            key={index}
                            data={store}
                            onSelect={() => {
                                dispatch(changeStore(store))
                                setPopupOpen(false)
                            }}
                        />
                    ))}
                </>
            </HalfScreen>
        </>
    )
}
interface St {
    data: StoreInterface
    onSelect: () => void
}
const Store = (props: St) => {
    const StoreWrap = styled.div`
        display: flex;
        margin: 16px;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        > img {
            width: 114px;
            height: 114px;
            object-fit: fill;
        }
        > div {
            padding: 10px;
            flex: 1;
            > div {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 2px;
            }
        }
    `
    const brand = useAppSelector((state) => state.brand)

    const history = useHistory()
    const [dis, setDis] = useState<string>('')
    const calculateDis = (pos: any) => {
        const myLocation = { lat: pos.coords.latitude, lng: pos.coords.longitude }
        calculateDistance(myLocation, { lat: props.data.lat, lng: props.data.lng }, (d) => setDis(d))
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(calculateDis)
    }, [])
    return (
        <StoreWrap
            onClick={() => {
                props.onSelect()
                window.location.pathname = window.location.pathname.replace(
                    window.location.pathname.split('/').slice(-1)[0],
                    props.data.slug
                )
            }}>
            <img src={brand.logo} />
            <div>
                <p className='heading-3'>{props.data.name}</p>
                <p className='caption' style={{ color: '#999999', marginTop: 4 }}>
                    {props.data.address}
                </p>
                <div>
                    {props.data.isOpen ? (
                        <p className='caption' style={{ fontWeight: 'bold', color: '#3DBD00' }}>
                            Đang mở cửa
                        </p>
                    ) : (
                        <p className='caption' style={{ fontWeight: 'bold', color: '#E02020' }}>
                            Tạm nghỉ
                        </p>
                    )}
                    <p className='caption'>{dis}</p>
                </div>
            </div>
        </StoreWrap>
    )
}
export default Stores
