import React, { ReactElement } from 'react'
import styled from 'styled-components'
const Container = styled.div`
    width: 100%;
    padding: 0px 16px;
`
const Wrap = styled.div`
    background: ${(props) => props.theme.tintColor};
    border-radius: 16px;
    padding: 13px 16px;
    z-index: 99;
    width: 100%;
    cursor: pointer;
    > p {
        color: #fff;
        text-align: center;
        font-weight: bold;
    }
`

interface Props {
    children?: JSX.Element[] | ReactElement
    btnStyle?: React.CSSProperties
    onClick?: () => void
    className?: string
}
const Button: React.FC<Props> = (props) => {
    return (
        <Container className={props.className}>
            <Wrap style={props.btnStyle} onClick={props.onClick}>{props.children}</Wrap>
        </Container>
    )
}
export default Button
