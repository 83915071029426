import React from 'react'
import { useAppSelector } from 'src/store/hooks'
import styled from 'styled-components'

const Wrap = styled.div`
    background: ${(props) => props.theme.tintColor};
    padding: 12px 15px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1999;
`
const Shortname = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 8px;
    background: #ffc34b;
    > p {
        font-weight: 700;
        color: #000;
    }
`
const Name = styled.p`
    color: #fff;
    margin-left: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
const Header: React.FC = () => {
    const storeName = useAppSelector((state) => state.store.selectedStore.name)
    const arrName =  storeName.split(' ')
    const shortName = arrName[0][0] + (arrName[1] ? arrName[1][0] : arrName[0][1])

    return (
        <Wrap className='limit-width'>
            <Shortname>
                <p>{shortName||''}</p>
            </Shortname>
            <Name className='heading-3'>{storeName}</Name>
        </Wrap>
    )
}
export default Header
