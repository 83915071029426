import React, { useState } from 'react'
import { ORDER_STATUS } from 'src/utils/constants'
import styled from 'styled-components'
interface props {
  id: string
  select: (id: string) => void
}
const HorizontalMenu: React.FC<props> = ({ id, select }) => {
  return (
    <Wrap>
      <Menu>
        {ORDER_STATUS.map((item, index: number) => (
          <Item
            key={index}
            onClick={() => select(item.id)}
            className={id === item.id ? 'scroll-menu-active' : ''}
          >
            <a href="#">{item.name}</a>
          </Item>
        ))}
      </Menu>
    </Wrap>
  )
}
const Wrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e7e6;
  position: sticky;
  background: white;
  z-index: 1;
`
const Menu = styled.ul`
  padding-inline-start: 0px;
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  background: white;
`

const Item = styled.li`
  list-style: none;
  display: inline;
  margin: 0px 15px;
  > a {
    text-decoration: none;
    color: black;
    padding: 16px 0px;
    display: inline-block;
    cursor: pointer;
  }
`
export default HorizontalMenu
