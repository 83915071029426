import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
const Wrap = styled.div`
        background: ${(props) => props.theme.background};
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `
const NotFound:React.FC = () => {
    return (
        <Wrap>
            <img src={UnlinkIcon} alt='404 Not Found' />
            <p style={{ margin: 16, fontWeight: 700 }}>404</p>
            <p>Not found page</p>
        </Wrap>
    )
}
export default NotFound
