import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from '@mui/material'
import ProductItem from './ProductItem'
import { OutlinedButton } from 'src/components/Button/OutlinedButton'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'
import { Banner } from 'src/models/Banner'
import { MenuItem } from 'src/models/MenuItem'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { Box } from '@mui/system'
import { Link, useHistory } from 'react-router-dom'
import ArrowRightImg from 'src/assets/images/arrowRight.svg'
import { Pagination } from 'swiper'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { isInternal } from 'src/utils/myUtils'
import { useBrand } from '../provider'
const WrapImg = styled.div`
  cursor: pointer;
  > img {
    width: 100%;
    max-height: 312px;
    object-fit: cover;
    border-radius: 16px;
    @media (max-width: 600px) {
      border-radius: 0px;
    }
  }
`
const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`
const Button = styled(OutlinedButton)`
  width: unset;
  padding: 10px 20px !important;
  > a {
    color: ${(props) => props.theme.mainColor};
    text-decoration: none;
  }
  &:hover {
    a {
      color: white;
    }
  }
`
const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const Arrow = styled.div`
  background-image: url('${ArrowRightImg}');
  width: 40px;
  height: 40px;
  background-position: center;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
  }
`

const SlideButton = styled.div`
  background: #00000066;
  width: 40px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  &:hover {
    background: #000000c4;
  }
`
const Right = styled(SlideButton)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: 1px;
  @media (max-width: 599px) {
    display: none;
  }
`

const Left = styled(SlideButton)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 1px;
  @media (max-width: 599px) {
    display: none;
  }
`

const WrapSlide = styled(Container)`
  padding-right: 0 !important;
  @media (min-width: 1200px) {
    padding-left: 0 !important;
    width: calc(100vw - (100vw - 1152px) / 2) !important;
    max-width: unset !important;
    margin-left: calc((100vw - 1152px) / 2) !important;
  }
`
const SmartWrap: React.FC<{ isWrap: boolean; children: JSX.Element | JSX.Element[] }> = ({
  isWrap,
  children
}) => {
  return isWrap ? (
    <WrapSlide>{children}</WrapSlide>
  ) : (
    <Container sx={{ pr: { xs: 0, sm: 3 } }}>{children}</Container>
  )
}
const SlideNextButton = () => {
  const swiper = useSwiper()
  return (
    <Right onClick={() => swiper.slideNext()}>
      <ArrowForwardIosIcon />
    </Right>
  )
}
const SlideBackButton = () => {
  const swiper = useSwiper()
  return (
    <Left onClick={() => swiper.slidePrev()}>
      <ArrowBackIosNewIcon />
    </Left>
  )
}
interface BrandItemProps {
  title: string
  link: string
  btnLabel: string
  btnLink: string
  menuItems: MenuItem[]
  banners: Banner[]
  configIndex: number
}

const BrandItem: React.FC<BrandItemProps> = ({
  title,
  link,
  menuItems,
  banners,
  btnLabel,
  btnLink,
  configIndex
}) => {
  const history = useHistory()
  const windowSize = useWindowSize()
  const [config, setConfig] = useState<any>()
  const [perView, setPerView] = useState(4.6)
  const { pre } = useBrand()
  const handleMoreBtnClick = () => {
    isInternal(link) ? history.push(pre + link) : window.open(link)
  }

  useEffect(() => {
    function handleResize() {
      const c = {
        breakpoints: {
          0: {
            slidesPerView: 2.1,
            spaceBetween: 16
          },
          320: {
            slidesPerView: 2.1,
            spaceBetween: 12
          },
          640: {
            slidesPerView: 2.1,
            spaceBetween: 12
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 24
          },
          1024: {
            slidesPerView:
              banners.length > 0
                ? 4
                : Number(((window.innerWidth - (window.innerWidth - 1152) / 2) / 287.5).toFixed(2)),
            spaceBetween: 24
          }
        }
      }
      setPerView(
        banners.length > 0
          ? 4
          : Number(((window.innerWidth - (window.innerWidth - 1152) / 2) / 287.5).toFixed(2))
      )
      setConfig(c)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleBannerClick = (link: string) => {
    isInternal(link) ? history.push(pre + link) : window.open(link)
  }
  return (
    <Box sx={{ mt: { md: 8, xs: 5 } }}>
      <Container>
        {title && (
          <Title>
            {title}
            {link && <Arrow onClick={handleMoreBtnClick} />}
          </Title>
        )}
      </Container>
      <Container sx={{ pl: { xs: 0, sm: 3 }, pr: { xs: 0, sm: 3 } }}>
        {banners.length > 0 && (
          <Box mb={4} mt={4}>
            <Swiper pagination={{ clickable: true }} modules={[Pagination]}>
              {banners.map((banner: Banner, index: number) => (
                <SwiperSlide key={index} onClick={() => handleBannerClick(banner.link)}>
                  <WrapImg>
                    <img
                      height={`${window.innerWidth / 3.4875}px`}
                      src={
                        windowSize.width && windowSize.width < 400
                          ? banner?.mobile_image_url
                          : banner?.desktop_image_url
                      }
                      alt={banner?.name}
                    />
                  </WrapImg>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        )}
      </Container>
      {config && (
        <SmartWrap isWrap={banners.length === 0}>
          <Swiper slidesPerView={perView} {...config}>
            {!!menuItems &&
              menuItems.map((item: MenuItem, index: number) => (
                <SwiperSlide key={index}>
                  <ProductItem brandIndex={index} item={item} configIndex={configIndex} />
                </SwiperSlide>
              ))}
            {banners.length > 0 && (
              <>
                <SlideBackButton />
                <SlideNextButton />
              </>
            )}
          </Swiper>
        </SmartWrap>
      )}
      <Container>
        {!!btnLabel && (
          <Center>
            <Button variant="outlined" onClick={handleMoreBtnClick}>
              {btnLabel}
            </Button>
          </Center>
        )}
      </Container>
    </Box>
  )
}

export default BrandItem
