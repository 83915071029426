import { _getSchedule } from 'src/utils/api';
import { calculateDistance } from 'src/utils/myUtils'
import { BrandInterface } from './Brand'
import { MenuItemCategory } from './MenuItemCategory';
import { OpenTime } from './OpenTime';
interface BankInfoInterface {
    accountName: string,
    accountNumber: string,
    bankName: string
}
export interface StoreInterface {
    name: string
    lat: number
    lng: number
    latitude: number
    longitude: number
    phone: string
    slug: string
    address: string
    id: string
    _id: string
    distance?: string
    announce?: string
    isOpen?: boolean
    bankInfo?: BankInfoInterface
    brand?: BrandInterface
    categories?: MenuItemCategory[] 
    open?: boolean
    openTimes?: OpenTime[]
    todayTime?: OpenTime
    hub?: {
        _id: string
        name: string
        address: string
    }
}

export function formatStore(data: any) {
    let store: StoreInterface = {
        name: data.name,
        address: data.address,
        slug: data.slug,
        id: data._id,
        lat: data.latitude,
        lng: data.longitude,
        latitude: data.latitude,
        longitude: data.longitude,
        phone: data.phone,
        announce: data.announce,
        bankInfo: data.bankInfo,
        distance: '-- km',
        isOpen: data.isOpen,
        open: data.open,
        todayTime: data.todayTime,
        _id: data._id
    }
    navigator.geolocation.getCurrentPosition((pos) => {
        calculateDistance(
            { lat: pos.coords.latitude, lng: pos.coords.longitude },
            { lat: data.latitude, lng: data.longitude },
            (dis) => {
                store = {...store, distance: dis}
            }
        )
    })
    return store
}
