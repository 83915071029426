import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
import { formatCurrency } from 'src/utils/myUtils'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getOrderPriceDetail } from 'src/store/features/order/thunks'
const Wrap = styled.div`
    > div {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
    }
`
const PriceDetail = () => {
    const dispatch = useAppDispatch()
    const orderData = useAppSelector((state) => state.order)
    useEffect(() => {
        if (orderData.items.length > 0) {
            dispatch(getOrderPriceDetail({ orderData }))
        }
    }, [orderData.customerAddress?.place_id, orderData.pickupType, orderData.items])
    return (
        <Wrap>
            <div>
                <p>Tổng cộng</p>
                <p>{formatCurrency(orderData.priceDetail?.itemTotalPrice || 0)}</p>
            </div>
            {orderData.priceDetail?.deliveryFee ? (
                <div>
                    <p>Phí giao hàng tạm tính</p>
                    <p>{formatCurrency(orderData.priceDetail?.deliveryFee)}</p>
                </div>
            ) : null}
        </Wrap>
    )
}
export default PriceDetail
