import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { formatStore, StoreInterface } from 'src/models/Store'
import { BrandInterface, formatBrand } from 'src/models/Brand'
import { getBrandInfo } from './thunks'

const initialState: BrandInterface = {
  cover: '',
  logo: '',
  name: '',
  id: '',
  slug: '',
  subBrands: [],
  homeConfig: []
}

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrandInfo.fulfilled, (state, { payload }) => {
      state.cover = formatBrand(payload.data.data).cover
      state.logo = formatBrand(payload.data.data).logo
      state.name = formatBrand(payload.data.data).name
      state.id = formatBrand(payload.data.data).id
    })
  }
})
export default brandSlice.reducer
