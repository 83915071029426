import { Button } from '@mui/material'
import styled from 'styled-components'

export const OutlinedButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.mainColor} !important;
  width: 100%;
  color: ${(props) => props.theme.mainColor} !important;
  text-transform: unset !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  &:hover {
    background: ${(props) => props.theme.mainColor} !important;
    color: white !important;
  }
`

export const HightOutlinedButton = styled(Button)`
  color: white !important;
  text-transform: unset !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  background: ${(props) => props.theme.mainColor} !important;
  padding: 10px 20px !important;
  min-width: 261px;
  &:hover {
    background: ${(props) => props.theme.mainColor} !important;
    color: white !important;
  }
`
