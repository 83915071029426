import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Alert, Button, Snackbar } from '@mui/material'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import NavLeftImg from 'src/assets/icons/nav-left.svg'
import OrderStatusBar from './OrderStatusBar'
import UserInfo from './UserInfo'
import Address from './Address'
import MethodPay from './MethodPay'
import Code from './Code'
import ListOrder from './ListOrder'
import { _getOrderDetail } from 'src/utils/api'
import Loading from 'src/components/Loading'
import { useCart } from '../Cart/CartContext'
import ShipBox from './ShipBox'
import LeftImg from 'src/assets/icons/nav-left.svg'
import Popup from '../Modal/Popup'
import DestroyOrder from './DestroyOrder'
import { ORDER_STATE } from 'src/utils/constants'
interface props {
  id: string
  onClose?: (showSnackBar?: boolean) => void
}
const OrderDetail: React.FC<props> = ({ id, onClose }) => {
  const history = useHistory()
  const windowSize = useWindowSize()
  const { uuid } = useCart()
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [address, setAddress] = useState<any>({ name: '', address: '' })
  const [openShip, setOpenShip] = useState<boolean>(false)
  const [showDestroyOrder, setShow] = useState<boolean>(false)
  const [openSnackBar, setOpenSB] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (id && uuid) {
        setLoading(true)
        const result = await _getOrderDetail(id, uuid)
        const data = result.data
        setData(data)
        if (data && data.hub) {
          setAddress({ name: data.hub.name, address: data.hub.address })
        } else {
          setAddress({
            name: `${data?.store?.brand?.name || 'N/A'} - ${data?.store?.name || 'N/A'}`,
            address: data?.store?.address || 'N/A'
          })
        }
        setLoading(false)
      }
    })()
  }, [id, uuid])

  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSB(false)
  }

  return (
    <Wrap>
      <Head>
        {history.action !== 'POP' && windowSize && windowSize.width && windowSize.width < 600 ? (
          <IconButton onClick={() => history.goBack()}>
            <NavLeftIcon />
          </IconButton>
        ) : (
          <BackIcon
            onClick={() => {
              if (onClose) onClose()
            }}
          />
        )}
        Chi tiết đơn hàng
      </Head>
      <WrapItem>
        {data && (
          <>
            <OrderStatusBar
              intendTime={data.intendTime}
              orderState={data?.state}
              delivery={data?.deliveryType}
            />
            <UserInfo data={data.customer} />
            {/* <ShipBox /> */}
            <Address data={address} />
            <MethodPay
              bankInfo={data?.hub?.bankInfo ?? data?.store?.bankInfo}
              addInfo={`${data.customer.name} ${data.customer.phone} - ${data.code}`}
              method={data.paymentMethod}
              paymentState={data.paymentState}
              finalPrice={data.finalPrice}
            />
            <ListOrder data={data} />
            <Code data={data} />
            {(data?.state === ORDER_STATE.NEW || data?.state === ORDER_STATE.PENDING) && (
              <div style={{ padding: 12 }}>
                <CancelButton onClick={() => setShow(true)}>Hủy đơn</CancelButton>
              </div>
            )}
          </>
        )}
        {loading && <Loading height={400} />}

        <Popup
          children={
            <DestroyOrder
              data={data}
              onReload={(showSnackBar) => !!onClose && onClose(showSnackBar)}
              onClose={(e) => {
                setShow(false)
                if (e?.response?.data?.error) {
                  setOpenSB(true)
                }
              }}
            />
          }
          open={showDestroyOrder}
          title={
            data?.state !== ORDER_STATE.CANCELED
              ? 'Bạn chắc chắn muốn hủy đơn hàng này?'
              : undefined
          }
          onClose={() => setShow(false)}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
            Hủy đơn không thành công!
          </Alert>
        </Snackbar>
      </WrapItem>
    </Wrap>
  )
}
const Wrap = styled('div')`
  linear-gradient(0deg, #F2F2F2, #F2F2F2), #FCFCFC;
`

const WrapItem = styled('div')`
  height: calc(var(--vh, 1vh) * 100 - 64px);
  overflow-y: scroll;
  background-color: #f2f2f2;
  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100 - 136px);
  }
`

const Head = styled('div')`
  font-weight: 700;
  font-size: 24px;
  height: 64px;
  background: white;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  position: relative;
  @media (max-width: 600px) {
    height: 56px;
    font-size: 16px;
  }
`
const NavLeftIcon = styled('div')`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${NavLeftImg}');
`

const CancelButton = styled(Button)`
  border: 1px solid #eb5757 !important;
  width: 100%;
  color: #eb5757 !important;
  text-transform: unset !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  &:hover {
    background: #eb5757 !important;
    color: white !important;
  }
`

const IconButton = styled(Button)`
  padding-left: 0;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 12px;
  top: 4px;
  min-width: 48px !important;
  &:hover {
    background-color: #feede9 !important;
  }
  &:active {
    background-color: #feede9 !important;
  }
  > .MuiTouchRipple-root span {
    background-color: #feede9;
  }
`

const BackIcon = styled('div')({
  width: 48,
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `url("${LeftImg}")`,
  flexShrink: 0,
  cursor: 'pointer',
  position: 'absolute',
  left: '12px',
  top: '4px'
})
export default OrderDetail
