export interface OptionChoice {
    _id?: string
    id: string
    name: string
    maxCount?: number
    price: number
    isDefault: boolean
    quantity?: number
}

export const _format = (data:any):OptionChoice => {
    return {
        id: data._id,
        name: data.name,
        maxCount: data.maxCount,
        price: data.price,
        isDefault: data.isDefault
    }
}