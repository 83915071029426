export default function flyToCart(id: string) {
  let addBtn = document.getElementById(id) as HTMLButtonElement
  let cart = document.querySelector('#cart-icon') as HTMLElement

  if (!addBtn) return

  let position: string = 'fixed'
  let speed = 500

  let btnY = position === 'fixed' ? addBtn?.getBoundingClientRect()?.top : addBtn.offsetTop,
    btnX = position === 'fixed' ? addBtn?.getBoundingClientRect()?.left : addBtn.offsetLeft
  let ball = document.querySelector(`.ball-${addBtn?.id}`)?.cloneNode() as HTMLElement
  if (!ball) return
  ball.style.display = 'block'

  ball.classList.add('flyingBtn')
  ball.style.position = position
  ball.style.top = `${btnY + 100}px`
  ball.style.left = `${btnX + 100}px`
  ball.style.opacity = '1'
  ball.style.transition = `all ${(speed * 2) / 1000}s ease, top ${(speed * 2) / 1000}s ease, left ${
    (speed * 4) / 1000
  }s ease, transform ${(speed * 2) / 1000}s ease ${(speed * 2 - 10) / 1000}s`
  document.body.appendChild(ball)

  ball.style.top = `${cart?.offsetTop + cart?.offsetHeight - 16}px`
  ball.style.left = `${cart?.offsetLeft + cart?.offsetWidth - 16}px`
  ball.style.height = '1rem'
  ball.style.width = '1rem'
  ball.style.transform = 'scale(0)'

  setTimeout(() => {
    ball?.removeAttribute('style')
    ball?.remove()
  }, speed * 4)
}
