import React from 'react'
import styled from 'styled-components'
import AddImg from 'src/assets/icons/add.svg'
import AddHoverImg from 'src/assets/icons/add-hover.svg'
import { MenuItem } from 'src/models/MenuItem'
import { formatCurrency } from 'src/utils/myUtils'
import { BallBox } from 'src/pages/Home/ProductItem'
import { CardActionArea, Typography } from '@mui/material'
import Img from 'src/components/Img'
import { grey } from 'src/utils/color'

interface Props {
  data: MenuItem | null
  brand?: string
  select: (item: MenuItem) => void
}

const ProductItem: React.FC<Props> = ({ data, brand, select }) => {
  if (!data) return <></>

  return (
    <CardActionArea
      id={data._id}
      className="fly-to-cart"
      onClick={() => {
        // if (brand) data.brandName = brand
        select(data)
      }}
      sx={{ mb: 2, borderRadius: 4 }}
    >
      <Wrap>
        <Picture>
          <Img src={data.image}/>
          <Status>✨ Mới</Status>
        </Picture>
        <Content>
          <Brand>{brand}</Brand>
          <Typography variant="body1" fontWeight="700" fontSize={{xs: '14px', sm: '16px'}}>
            {data.name}
          </Typography>
          <Type>{data.unit}</Type>
          {data.description && <Detail>{data.description}</Detail>}
          <Typography
            variant="body1"
            fontWeight="700"
            fontSize={{xs: '14px', sm: '16px'}}
            sx={{
              flexGrow: {
                xs: 1,
                sm: 1
              },
              alignItems: {
                xs: 'self-end',
                sm: 'self-end'
              },
              display: 'flex'
            }}
          >
            {formatCurrency(data.price)}
          </Typography>
          {/* <OldPrice>150.000đ</OldPrice> */}
          <BallBox className={`ball-${data._id}`}/>
        </Content>
        <IconWrap>
          <PlusIcon/>
        </IconWrap>
      </Wrap>
    </CardActionArea>
  )
}

const Wrap = styled.div`
  display: flex;
  padding: 12px;
  background-color: white;
  border-radius: 16px;
  justify-content: space-between;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  @media (max-width: 899px) {
    margin-bottom: 0px;
    border-bottom: 1px solid ${grey[300]};
    border-radius: 0px;
  }
`
const Picture = styled.div`
  margin-right: 12px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 154px;
  height: 154px;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  > img {
    transition: transform 0.3s;
    max-width: 100%;
  }

  @media (max-width: 599px) {
    width: 114px;
    height: 114px;
    // outline: 1px solid ${grey[900]};
  }
`
const Status = styled.div`
  font-family: Lexend;
  position: absolute;
  top: 12px;
  left: 12px;
  background: ${grey[50]};
  border-radius: 12px;
  padding: 4px 8px;
  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 16px;
    border-radius: 0;
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 12px;
  }
`
const Content = styled.div`
  flex-grow: 1;
  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const Brand = styled.div`
  font-family: 'Lexend';
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.mainColor};
`
const Type = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${grey[400]};
`
const Detail = styled.div`
  line-height: 24px;
  margin-right: 12px;
  display: -webkit-box;
  height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 599px) {
    display: none;
  }
`
const Price = styled.div`
  font-weight: 700;
  line-height: 24px;
  display: flex;
  font-size: 16px;
  @media (max-width: 899px) {
    flex-grow: 1;
    align-items: self-end;
  }
`
const OldPrice = styled.div`
  text-decoration-line: line-through;
  font-weight: 400;
  color: ${grey[400]};
  margin-left: 12px;
`
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 899px) {
    align-items: flex-end;
  }
`
const PlusIcon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${AddImg}');
  flex-shrink: 0;
  cursor: pointer;
  background-size: contain;

  &:hover {
    background-image: url('${AddHoverImg}');
  }

  @media (max-width: 899px) {
    width: 32px;
    height: 32px;
  }
`
export default ProductItem
