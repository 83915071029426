import { ItemOption } from './ItemOption'
import { _format as optionFormat } from './ItemOption'
export interface MenuItem {
    id: string
    _id: string
    name: string
    itemName?: string
    description: string
    image: string
    price: number
    itemPrice: number
    total: number
    totalPerItem: number
    options: Array<ItemOption>
    unit?: string
    note?: string
    sku?: string
    quantity: number
    userNote?: string
    status: string
    brand?: {
        _id: string
        name: string
        slug: string
    },
    selected: boolean
    source?: {
        store: {
            brand: {
                name: string
            }
        }
    },
    store?: {
        brand: {
            name: string
        }
    }
}

export const _format = (data: any): MenuItem => {
    return {
        id: data._id,
        _id: data._id,
        name: data.name,
        description: data.description,
        image: data.image,
        price: data.price,
        itemPrice: data.price,
        totalPerItem: data.price,
        total: data.price,
        quantity: 0,
        selected: false,
        status: data.status,
        options: data.options.map((o: any) => optionFormat(o)),
    }
}
