export function formatCurrency(currency: number): string {
    return (currency?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? '0') + 'đ'
}


export function formatDate(d: string): string {
    const date = new Date(d);
    return `${date.getFullYear()}-${genTwoNumber(date.getMonth() + 1)}-${genTwoNumber(date.getDate())} ${genTwoNumber(date.getHours())}:${genTwoNumber(date.getMinutes())}`;
}

function genTwoNumber(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
}
export function objectsEqual(object1: any, object2: any): boolean {
    if (object1 === object2) {
        return true
    } else if (typeof object1 == 'object' && object1 != null && typeof object2 == 'object' && object2 != null) {
        if (Object.keys(object1).length != Object.keys(object2).length) return false

        for (var prop in object1) {
            if (object2.hasOwnProperty(prop)) {
                if (!objectsEqual(object1[prop], object2[prop])) return false
            } else return false
        }

        return true
    } else return false
}
export function arrayEqual(array1: Array<any>, array2: Array<any>): boolean {
    return array1.length === array2.length && array1.every((o1) => array2.some((o2) => objectsEqual(o1, o2)))
}
export function validateEmail(email: string): boolean {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function validatePhoneNumber(phoneNumber: string): boolean {
    const re =
        /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/
    return re.test(String(phoneNumber).toLowerCase())
}

export const calculateDistance = (origin: any, destination: any, callback: (distance: string) => void) => {
    if (window.google) {
        let service = new google.maps.DistanceMatrixService()
        service.getDistanceMatrix(
            {
                origins: [origin.lat + ',' + origin.lng],
                destinations: [destination.latitude + ',' + destination.longitude],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
            },
            function (response, status) {
                if (
                    status == google.maps.DistanceMatrixStatus.OK &&
                    response &&
                    response.rows[0].elements[0].status != 'ZERO_RESULTS'
                ) {
                    callback(response?.rows[0]?.elements[0]?.distance?.text)
                }
            }
        )
    }
}

export const clamp = (value: number) => Math.max(0, value);

export const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;


const base = new URL(`${window.location.protocol}//${window.location.host}`)

export function isInternal(url: string): boolean {
	return new URL(url, base).hostname === base.hostname;
}

export function distance(lat1: number, lng1: number, lat2: number, lng2: number): number {
    const r = 6371000 // meters
    const rad = Math.PI / 180

    const o1 = lat1 * rad
    const o2 = lat2 * rad
    const dtO = (lat2 - lat1) * rad
    const dtN = (lng2 - lng1) * rad

    const a = Math.cos(o1) * Math.cos(o2) * Math.sin(dtN / 2) * Math.sin(dtN / 2)
                + Math.sin(dtO / 2) * Math.sin(dtO / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return r * c
}
