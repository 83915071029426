const red = {
  400: '#EB5757',
  500: '#F05123',
  700: '#d32f2f'
}

const grey = {
  50: '#fafafa',
  100: '#F0FFF3',
  200: '#eeeeee',
  300: '#cccccc',
  400: '#b1b1b1',
  500: '#888888',
  600: '#797979',
  900: '#1b1e25',
  A100: '#ececec',
  A200: '#f4f4f4',
  A400: '#afafaf'
}
const green = {
  200: '#39B54A33',
  500: '#39b54a'
}

const cancelOrder = {
  500: '#9F9D99'
}

export {red, grey, green, cancelOrder}
