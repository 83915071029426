import React from 'react'
import ItemPicker from 'src/components/ItemPicker'
import { MenuItem as ItemInterface } from 'src/models/MenuItem'
import { formatCurrency } from 'src/utils/myUtils'
import styled from 'styled-components'
import DishPlaceholder from 'src/assets/images/dishplaceholder.png'
import { ItemOption } from 'src/models/ItemOption'
import { useState } from 'react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Option as OrderItemOptionInterface, OrderItem } from 'src/models/OrderItem'
import FloatingButton from 'src/components/Button/FloatingButton'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useAppDispatch } from 'src/store/hooks'
import { addItem, editItem } from 'src/store/features/order/slice'

interface Interface {
    item: ItemInterface
    currentItem: OrderItem
    onClose: () => void
}

const MainInfoSection = styled.div`
    padding: 0px 16px;
    img.dish-image {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }
`
const ButtonStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    p,
    svg {
        color: #fff;
    }
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            margin-left: 4px;
        }
    }
`
const EditItem: React.FC<Interface> = (props) => {
    const dispatch = useAppDispatch()
    const [quantity, setQuantity] = useState<number>(props.currentItem.quantity)
    const [optionOrders, setOptionOrders] = useState<Array<OrderItemOptionInterface>>(props.currentItem.options)
    let choicePrice = 0
    let buttonTitle = `${quantity} món`
    optionOrders.forEach((op, oi) =>
        op.choices.forEach((c, ci) => {
            if (oi === 0 && ci === 0) {
                buttonTitle += ' - '
            } else {
                buttonTitle += ' + '
            }
            if (op.maxCount === 1) {
                buttonTitle += c.name
            } else {
                buttonTitle += c.quantity + 'x' + c.name
            }
            choicePrice += c.price * c.quantity
        })
    )
    const onChoiceClick = (optionId: string, choiceId: string, cPrice: number, cName: string): void => {
        const optionData = props.item.options.find((o) => o.id === optionId)
        if (optionOrders.find((option) => option.id === optionId)) {
            const currentOptionOrder = optionOrders
            const currentOptionData = currentOptionOrder.find((o) => o.id === optionId)
            if (optionData?.maxCount === 1) {
                const newOptionOrder = currentOptionOrder.map((o) => {
                    if (o.id === optionId) {
                        return {
                            id: optionId,
                            maxCount: 1,
                            choices: [
                                {
                                    id: choiceId,
                                    quantity: 1,
                                    price: cPrice,
                                    name: cName,
                                },
                            ],
                        }
                    } else {
                        return o
                    }
                })
                setOptionOrders(newOptionOrder)
            } else {
                let countChoice = 0
                currentOptionData?.choices.forEach((c) => (countChoice += c.quantity))
                if (typeof optionData?.maxCount != 'undefined' && countChoice < optionData?.maxCount) {
                    const newOptionOrder = currentOptionOrder.map((o) =>
                        o.id === optionId
                            ? {
                                  id: optionId,
                                  maxCount: optionData.maxCount,
                                  choices: o.choices.find((c) => c.id === choiceId)
                                      ? o.choices.map((c) =>
                                            c.id === choiceId
                                                ? { id: choiceId, quantity: c.quantity + 1, price: cPrice, name: cName }
                                                : c
                                        )
                                      : o.choices.concat([{ id: choiceId, quantity: 1, price: cPrice, name: cName }]),
                              }
                            : o
                    )
                    setOptionOrders(newOptionOrder)
                }
            }
        } else {
            const o = [
                {
                    id: optionId,
                    maxCount: optionData?.maxCount ? optionData.maxCount : 1,
                    choices: [
                        {
                            id: choiceId,
                            quantity: 1,
                            price: cPrice,
                            name: cName,
                        },
                    ],
                },
            ]
            setOptionOrders(optionOrders.concat(o))
        }
    }

    const onChoiceRemove = (optionId: string, choiceId: string): void => {
        const newOptionOrders = optionOrders.map((o) =>
            o.id === optionId
                ? { id: optionId, maxCount: o.maxCount, choices: o.choices.filter((c) => c.id != choiceId) }
                : o
        )
        setOptionOrders(newOptionOrders)
    }
    const editHandler = () => {
        const orderItem: OrderItem = {
            itemId: props.item.id,
            note: '',
            quantity: quantity,
            options: optionOrders,
            price: choicePrice + props.item.price,
            name: props.item.name,
            categoryId: props.currentItem.categoryId,
        }
        dispatch(
            editItem({
                currentItem: props.currentItem,
                newItem: orderItem,
            })
        )
        props.onClose()
    }
    return (
        <>
            <MainInfoSection>
                <div style={{ display: 'flex' }}>
                    {props.item.image ? (
                        <img
                            className='dish-image'
                            src={props.item.image.toString()}
                            alt={props.item.name.toString()}
                        />
                    ) : (
                        <img className='dish-image' src={DishPlaceholder} alt={props.item.name.toString()} />
                    )}
                    <p className='heading-4'>{props.item.name}</p>
                </div>
                <Description text={props.item.description} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p className='heading-4' style={{ marginTop: 4 }}>
                        {formatCurrency(props.item.price)}
                    </p>
                    <ItemPicker
                        buttonSize={32}
                        textSize={16}
                        value={quantity}
                        onIncrease={() => setQuantity(quantity + 1)}
                        onDecrease={() => {
                            if (quantity > 1) setQuantity(quantity - 1)
                        }}
                    />
                </div>
            </MainInfoSection>
            {props.item.options?.map((option, index) => (
                <Option
                    key={index}
                    {...option}
                    onClick={onChoiceClick}
                    onChoiceRemove={onChoiceRemove}
                    optionData={optionOrders.find((op) => (op.id === option.id ? op : null))}
                />
            ))}
            <FloatingButton shouldDisplay={quantity > 0} onClick={editHandler}>
                <p className='caption' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {buttonTitle}
                </p>
                <ButtonStyle>
                    <p className='heading-3'>{formatCurrency((choicePrice + props.item.price) * quantity)}</p>
                    <div>
                        <p className='heading-3'>Thêm vào giỏ</p>
                        <ArrowForwardRoundedIcon />
                    </div>
                </ButtonStyle>
            </FloatingButton>
            <div style={{ minHeight: 122, background: '#fafafa' }} />
        </>
    )
}
interface DescriptionInterface {
    text: String
}
const Description: React.FC<DescriptionInterface> = (props) => {
    const DescriptionWrap = styled.p`
        margin: 16px 0px;
        > .read-or-hide {
            display: inline-block;
            cursor: pointer;
            color: ${(props) => props.theme.tintColor};
        }
    `
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    if (!props.text) {
        return <DescriptionWrap />
    }
    return (
        <DescriptionWrap className='caption'>
            {isReadMore ? props.text.slice(0, 98) + '...' : props.text}
            <span onClick={toggleReadMore} className='read-or-hide'>
                {isReadMore ? ' Xem thêm⮟' : ' Ẩn bớt⮝'}
            </span>
        </DescriptionWrap>
    )
}
interface OptionInterface {
    onClick: (optionId: string, choiceId: string, choicePrice: number, choiceName: string) => void
    onChoiceRemove: (optionId: string, choiceId: string) => void
    optionData?: OrderItemOptionInterface | null
}
const Option: React.FC<ItemOption & OptionInterface> = (props) => {
    const OptionSection = styled.div`
        margin-top: 16px;
        > p.option-label {
            opacity: 0.4;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0px 0px 8px 16px;
        }
    `
    const ChoiceSection = styled.div`
        background: #fff;
        padding: 8px 0px;
    `
    const Choice = styled.div`
        display: flex;
        margin: 0px 16px;
        padding: 8px 0px;
        align-items: center;
    `
    const Selected = styled.div`
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        background: ${(props) => props.theme.tintColor};
        align-items: center;
        border-radius: 50%;
        > svg {
            color: #fff;
            width: 16px;
            height: 16px;
        }
    `
    const NotSelected = styled(Selected)`
        border: 2px solid rgb(71 95 123 / 20%);
        background: unset;
        > svg {
            color: #475f7b;
            opacity: 0.2;
        }
    `

    const Remove = styled(Selected)`
        border: 2px solid #e73948;
        background: #fafafa;
        > svg {
            color: #e73948;
        }
        &.disable {
            border: 2px solid rgb(231 57 72/ 20%);
            > svg {
                color: rgb(231 57 72/ 20%);
            }
        }
    `

    return (
        <OptionSection>
            <p className='option-label caption'>
                {props.name} ({props.maxCount === 1 ? 'Chọn 1' : 'Tối đa ' + props.maxCount})
            </p>
            <ChoiceSection>
                {props.choices.map((choice, index) => (
                    <Choice
                        key={index}
                        style={{
                            borderBottom: index + 1 === props.choices.length ? '' : '1px solid rgb(89 89 89 / 20%)',
                        }}>
                        <div
                            style={{ flex: 1 }}
                            onClick={() => props.onClick(props.id, choice.id, choice.price, choice.name)}>
                            <p className='heading-4'>{choice.name}</p>
                            <p>+{formatCurrency(choice.price)}</p>
                        </div>

                        {props.maxCount === 1 ? (
                            <>
                                {props.optionData?.choices.find((c) => c.id === choice.id) ? (
                                    <Selected>
                                        <CheckRoundedIcon />
                                    </Selected>
                                ) : (
                                    <NotSelected
                                        onClick={() => props.onClick(props.id, choice.id, choice.price, choice.name)}>
                                        <CheckRoundedIcon />
                                    </NotSelected>
                                )}
                            </>
                        ) : (
                            <>
                                {props.optionData?.choices.find((c) => c.id === choice.id) ? (
                                    <>
                                        <p style={{ marginRight: 6 }} className='heading-4'>
                                            {props.optionData?.choices.find((c) => c.id === choice.id)?.quantity}
                                        </p>
                                        <Remove onClick={() => props.onChoiceRemove(props.id, choice.id)}>
                                            <CloseRoundedIcon />
                                        </Remove>
                                    </>
                                ) : (
                                    <>
                                        <Remove className='disable'>
                                            <CloseRoundedIcon />
                                        </Remove>
                                    </>
                                )}
                            </>
                        )}
                    </Choice>
                ))}
            </ChoiceSection>
        </OptionSection>
    )
}

export default EditItem
