import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { StoreInterface } from 'src/models/Store'
import Store from './Store'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useCart } from 'src/components/Cart/CartContext'
import StoreChecker from 'src/components/Cart/StoreChecker'
interface Props {
  onClose: Function
  open: boolean
  stores: StoreInterface[]
  name: string
  logo: string
}
const PickStoreModal: React.FC<Props> = ({ onClose, open, stores, name, logo }) => {
  const history = useHistory()
  const { data, currentStore } = useCart()

  const [openModal, setOpenModal] = useState(true)
  const [openPopup, setOpenPopup] = useState(false)
  const [selectStore, setSelectStore] = useState<StoreInterface | undefined>()
  const { brandSlug } = useParams<{ brandSlug: string }>()

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  const handleClose = () => {
    setOpenModal(false)
    onClose(false)
  }

  const select = (store: StoreInterface) => {
    setOpenModal(false)
    onClose(false)

    if (currentStore?._id !== store._id && data.items.length) {
      setSelectStore(store)
      setOpenPopup(true)
      return
    }

    history.push(`/${brandSlug}/thuc-don?st=${store.slug}`)
  }

  return (
    <>
      <Dialog onClose={handleClose} open={openModal}>
        <TitleWrap>
          <IconButtonPos onClick={handleClose}>
            <CloseIcon />
          </IconButtonPos>
          <Title>
            {name} - {stores.length} điểm bán
          </Title>
        </TitleWrap>
        <DialogContent sx={{ width: { xs: 'unset', md: 564 } }}>
          <div style={{ borderTop: 'none' }}>
            {stores.map((item: StoreInterface, index: number) => (
              <Store
                key={index}
                select={(store: StoreInterface) => select(store)}
                logo={logo}
                store={item}
              />
            ))}
          </div>
        </DialogContent>
      </Dialog>
      {selectStore && <StoreChecker
        open={openPopup}
        store={{
          _id: selectStore._id,
          name: selectStore.name,
          slug: selectStore.slug,
          url: `/${brandSlug}/thuc-don?st=${selectStore.slug}`
        }}
        onClose={() => {
          setOpenPopup(false)
        }}
        onOk={() => {
          history.push(`/${brandSlug}/thuc-don?st=${selectStore!.slug}`)
        }}
      />}
    </>
  )
}

const TitleWrap = styled(DialogTitle)({
  position: 'relative'
})

const Title = styled('div')({
  fontWeight: 700,
  fontSize: 16,
  textAlign: 'center',
  marginLeft: 40,
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

const IconButtonPos = styled(IconButton)({
  position: 'absolute',
  left: 20,
  top: 10
})

export default PickStoreModal
