export const PICKUP_TYPE = {
  SHIPPING: 'ship',
  TAKEAWAY: 'takeAway'
}
export const PAYMENT_METHOD = {
  CASH: 'cash',
  BANKING: 'banking'
}
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY ?? ''

export const WEEKDAY: { [key: number]: string } = {
  0: 'Chủ nhật',
  1: 'Thứ hai',
  2: 'Thứ ba',
  3: 'Thứ tư',
  4: 'Thứ năm',
  5: 'Thứ sáu',
  6: 'Thứ bảy'
}
export const BACKEND_WEEKDAY: {[key: number]: string} = {
  0: 'CN',
  1: 'T2',
  2: 'T3',
  3: 'T4',
  4: 'T5',
  5: 'T6',
  6: 'T7',
}
export const MENUITEM_STATUS = {
  ACTIVE: 'active'
}
export const noticeData = [
  {
    title: '📦️ Lưu ý tách đơn',
    detail:
      'Giỏ hàng của bạn đang gồm các món được phục vụ bởi 2 điểm bán khác nhau, vì vậy đơn của bạn sẽ được tách & giao qua 2 đơn giao hàng khác nhau.'
  }
]

export const NO_DELIVERY_ADDRESS = {
  title: '‼️️ Chưa có địa chỉ giao hàng',
  detail: '',
  showIcon: true
}

export const SEPARATE_ORDER_OUTLET = {
  showIcon: true,
  title: '📦️ Lưu ý tách đơn',
  detail:
    'Giỏ hàng của bạn đang gồm các món được phục vụ bởi 2 điểm bán khác nhau, vì vậy đơn của bạn sẽ được tách & giao qua 2 đơn giao hàng khác nhau.'
}

export const payMethods = [
  // {
  //   name: 'Chuyển khoản',
  //   selected: false,
  //   id: 'banking'
  // },
  {
    name: 'Tiền mặt khi giao hàng',
    selected: true,
    id: 'cash'
  }
]

export const UI = {
  BANNER: 'BANNER',
  BOTH: 'BOTH'
}

export const ORDER_STATUS = [
  {
    name: 'Chờ xác nhận',
    id: 'new'
  },
  {
    name: 'Chờ giao',
    id: 'pending'
  },
  {
    name: 'Đang giao',
    id: 'delivering'
  },
  {
    name: 'Đã giao',
    id: 'done'
  },
  {
    name: 'Đã hủy',
    id: 'canceled'
  }
]

export const PAYMENT_METHOD_LABEL: any = {
  cash: 'Tiền mặt khi giao hàng',
  banking: 'Chuyển khoản'
}

export const PAYMENT_STATE = {
  PAID: 'paid',
  NONE: 'none'
}

export const ORDER_STATE = {
  PENDING: 'pending',
  NEW: 'new',
  CANCELED: 'canceled',
  DONE: 'done',
  DELIVERY: 'delivering'
}

export const PLSORDER_DOMAIN = process.env.REACT_APP_PLSORDER_ORIGIN
