import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { _getBrandPage, _getHubPage, _getStorePage } from 'src/utils/api';
import HubPage from '../HubPage';
import HomeHeader from 'src/components/HomeHeader'
import Footer from 'src/components/Footer'
import { HubPageInterface } from 'src/models/HubPage';
import { StoreInterface } from 'src/models/Store';
import Loading from 'src/components/Loading';
import {useBrand} from "../provider";
import { useCart } from 'src/components/Cart/CartContext';
import CartBar from 'src/components/Cart/CartBar'

export const TYPE = {
    HUB: 'hub',
    STORE: 'store',
    BRAND: 'brand'
}
const Wrapper: React.FC = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<(HubPageInterface) | null>(null);
    const brandProvider = useBrand()
    const { setViewingStore } = useCart()

    useEffect(() => {
        (async () => {
            if (!brandProvider.brand?._id) return
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const hubSlug = params.get('h');
            const storeSlug = params.get('st');
            const brandSlug = params.get('b');
            setLoading(true);
            if (hubSlug) {
                const data: {data: HubPageInterface} = await _getHubPage(brandProvider.brand?._id ?? '', hubSlug);
                data.data.type = TYPE.HUB;
                setData(data.data);
                setViewingStore({
                    _id: data.data._id || '-1',
                    name: data.data.name,
                    slug: data.data.slug,
                    type: 'h',
                    address: data.data.address,
                    brand: '',
                    location: {lat: data.data.latitude, lng: data.data.longitude},
                    open: !!data.data.open,
                    openTimes: data.data.openTimes || [],
                })
            }else if (storeSlug) {
                const data: {data: HubPageInterface} = await _getStorePage(brandProvider.brand?._id ?? '', storeSlug);
                data.data.type = TYPE.BRAND;
                const store = data.data.stores?.find((store: StoreInterface) => store.slug === storeSlug);
                if (store) data.data.activeStore = store;
                setData(data.data);
                setViewingStore(data.data.activeStore && {
                    _id: data.data.activeStore._id,
                    name: data.data.activeStore.name,
                    slug: data.data.activeStore.slug,
                    type: 'st',
                    address: data.data.activeStore.address,
                    brand: data.data.activeStore.brand?._id || data.data.activeStore._id,
                    location: {
                        lat: data.data.activeStore.latitude,
                        lng: data.data.activeStore.longitude,
                    },
                    open: !!data.data.activeStore.open,
                    openTimes: data.data.activeStore.openTimes || [],
                })
            }else if (brandSlug) {
                const data: {data: HubPageInterface} = await _getBrandPage(brandProvider.brand?._id ?? '', brandSlug);
                data.data.type = TYPE.BRAND;
                if (data.data.stores && data.data.stores?.length > 0) data.data.activeStore = data.data.stores[0];
                setData(data.data);
                setViewingStore(data.data.activeStore && {
                    _id: data.data.activeStore._id,
                    name: data.data.activeStore.name,
                    slug: data.data.activeStore.slug,
                    type: 'st',
                    address: data.data.activeStore.address,
                    brand: data.data.activeStore.brand?._id || data.data.activeStore._id,
                    location: {lat: data.data.activeStore.latitude, lng: data.data.activeStore.longitude},
                    open: !!data.data.activeStore.open,
                    openTimes: data.data.activeStore.openTimes || [],
                })
            }

            setLoading(false);
        })();
        
    }, [brandProvider.brand?._id, location])

    return (
        <>
            <HomeHeader></HomeHeader>
            {loading && <Loading height={400}></Loading>}
            {(data !== null) && <HubPage data={data}/>}
            <CartBar />
            <Footer></Footer>
        </>
    );
}
export default Wrapper;
