import { useState } from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { BrandItem } from './LocationItem'
import { useHistory } from 'react-router-dom'
import StoreChecker from 'src/components/Cart/StoreChecker'

interface BrandModalProps {
  handleClose: () => void
  brands: BrandItem[]
}

const BrandModal: React.FC<BrandModalProps> = ({ handleClose, brands }) => {
  return (
    <Box>
      <Box sx={{ 'div:last-child': { borderBottom: 'none' } }}>
        {brands &&
          brands.map((brand: BrandItem, index: number) => (
            <Brand logo={brand.logo} name={brand.name} slug={brand.slug} _id={brand._id}/>
          ))}
      </Box>
    </Box>
  )
}

const Brand: React.FC<BrandItem> = ({ logo, name, slug, _id }) => {
  const history = useHistory()
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)

  const handleClickBrand = () => {
    // setOpenStoreCheck(true)
    // history.push('/airkitchen/thuc-don?b=' + slug)
  }
  return (
    <Card elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>
      <CardActionArea
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          py: 2,
          px: 1.5
        }}
        onClick={handleClickBrand}
      >
        <CardMedia
          style={{flexWrap: 'wrap', width: '80px'}}
          component="img"
          height="80px"
          width="80px"
          image={logo}
          alt={name}
          sx={{
            borderRadius: 2,
            border: '1px solid rgba(0, 0, 0, 0.2)',
            width: { md: 140 },
            height: { md: 140 },
            objectFit: 'contain'
          }}
        />
        <CardContent>
          <Typography gutterBottom component="div" fontWeight="bold" fontSize={{xs: 16, md: 24}} flexGrow={1}>
            {name}
          </Typography>
          <StoreChecker
            open={openStoreCheck}
            store={{
              _id: _id || '',
              name,
              slug,
              url: '/airkitchen/thuc-don?b=' + slug,
            }}
            onOk={() => {
              history.push('/airkitchen/thuc-don?b=' + slug)
            }}
            onClose={() => {
              setOpenStoreCheck(false)
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BrandModal
