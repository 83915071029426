import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import styled from 'styled-components'
import Button, { ButtonProps } from '@mui/material/Button';
interface props {
    sm: boolean
    value: boolean
    loading: boolean
    onShipChange: (value: boolean) => void
}
const SwitchButton: React.FC<props> = ({sm, value, loading, onShipChange}) => {
    return (
        <Wrap>
            <ButtonGroup style={{width: '100%'}} disableElevation variant="contained">
                <ColorButton disabled={loading} onClick={() => onShipChange(true)} style={{textTransform: 'none'}} active={value} variant="outlined" sm={sm}>Giao hàng</ColorButton>
                <ColorButton disabled={loading} onClick={() => onShipChange(false)} style={{textTransform: 'none'}} active={!value} variant="outlined" sm={sm}>Đến lấy</ColorButton>
            </ButtonGroup>
        </Wrap>
    )
}

const Wrap = styled('div')({
    maxWidth: 356,
    margin: 'auto',
    '@media (max-width: 599px)': {
        maxWidth: 'unset'
      }
})

const ColorButton = styled(Button)<ButtonProps & {active?: boolean, sm?: boolean}>(({theme, active, sm}) => ({
    width: '50%',
    minWidth: '80px !important',
    height: sm ? '32px' : '48px',
    fontSize: sm ? '12px !important' : '16px',
    border: '1px solid #F05123 !important',
    background: active ? '#F05123 !important' : 'white',
    color: active ? 'white !important' : '#F05123 !important',
    padding: sm ? '6px !important' : 'unset'
}));
export default SwitchButton