import React, { useEffect, useRef, useState } from 'react'
import HomeHeader from 'src/components/HomeHeader'
import OrderDetail from 'src/components/OrderDetail'
import { useParams } from 'react-router'
const OrderDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <>
      <HomeHeader showTop={false} showMenu={false} hideBorder={true} />
      <OrderDetail id={id} />
    </>
  )
}

export default OrderDetailPage
