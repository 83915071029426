import React, { useState } from 'react'
import PlaceImg from 'src/assets/images/dishplaceholder.svg'
interface props {
  src: string
  style?: React.CSSProperties
  onClick?: () => void
}
const Img: React.FC<props> = ({ src, style, onClick }) => {
  const [show, setShow] = useState<boolean>(false)
  return (
    <>
      {!show && (
        <img
          style={style}
          src={PlaceImg}
        />
      )}
      <img
        style={{ ...style, display: show ? 'block' : 'none' }}
        onLoad={() => {
          setShow(true)
        }}
        onClick = {() => {
          if (onClick) onClick();
        }}
        src={src}
      />
    </>
  )
}

export default Img
