import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions/transition'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />
})

interface LeftSideScreenInterface {
  open: boolean
  onClose?: () => void
  width?: number
  dialogClassName?: string
  defaultClose?: boolean
}

const LeftSideScreen: React.FC<LeftSideScreenInterface> = ({
  children,
  open,
  onClose,
  width,
  dialogClassName,
  defaultClose
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
    return () => setOpenDialog(false)
  }, [open])

  const handleClose = () => {
    if (onClose) {
      setOpenDialog(false)
      onClose()
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          width: width,
          height: '100vh',
          maxHeight: '100vh',
          maxWidth: '100%',
          position: 'absolute',
          background: '#fafafa',
          top: 0,
          left: 0,
          margin: 0
        },
        className: `scrolling ${dialogClassName}`
      }}
    >
      {children}
    </Dialog>
  )
}
export default LeftSideScreen
