import React, { useEffect } from 'react'
import styled from 'styled-components'
import InforImg from 'src/assets/icons/infor.svg'

interface InputProps {
  title: string
  detail: string
  showIcon: boolean
}

const Notice: React.FC<{data: InputProps}> = ({data}) => {
  return (
    <Wrap>
      <Content>
        <Title>{data.title}</Title>
        <Detail>{data.detail}</Detail>
      </Content>
      {data.showIcon && <Icon />}
    </Wrap>
  )
}
const Wrap = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #feff58;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
const Content = styled.div``
const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`
const Detail = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
const Icon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${InforImg}');
  flex-shrink: 0;
`
export default Notice
