import HomeHeader from 'src/components/HomeHeader'
import React, { useState, useEffect, useMemo } from 'react'
import { styled } from '@mui/system'
import LeftImg from 'src/assets/icons/nav-left.svg'
import { CircularProgress, Container, Typography, Button, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Notice from 'src/components/Notice'
import CartGroup from './Cart'
import useScroll from 'src/utils/hooks/useScroll'
import SwitchButton from 'src/components/Button/SwitchButton'
import PointImg from 'src/assets/icons/black-point.svg'
import InputBase from '@mui/material/InputBase'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import { formatCurrency } from 'src/utils/myUtils'
import CodeInput from 'src/components/CodeInput'
import ButtonGroup from 'src/components/Button/ButtonGroup'
import TextField from '@mui/material/TextField'
import AddressModal from 'src/pages/Location/AddressModal'
import { useCart } from 'src/components/Cart/CartContext'
import { CartItemInterface } from 'src/models/Cart'
import { AddressPos } from 'src/models/AddressPos'
import { NO_DELIVERY_ADDRESS, payMethods } from 'src/utils/constants'
import { useBrand } from '../provider'
import { grey, red, green } from 'src/utils/color'
import DeliveryTime from './deliveryTime'
import Popup from 'src/components/Modal/Popup'

interface InObject {
  value: string
  clicked: boolean
}

const phoneReg = /^0\d{9,11}/

const Checkout: React.FC = () => {
  const { cart, uuid, setCode, onChangeNeedShip, valid, createOrder, cancelCoupon, setAddress, prepareOrder, loading, data, currentStore } = useCart()
  const history = useHistory()
  const { pre, brand } = useBrand()
  const [showAddress, setShowAddress] = useState(false)
  const { scrollPosition } = useScroll()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [payMethod, setPayMethod] = useState<string>('cash')
  const [addressError, setAddressError] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [numOutlet, setNumOutlet] = useState<number>(0)
  const [intendTime, setIntendTime] = useState<string>('')
  const [submitCode, setSubmitCode] = useState(false)
  const [confirmLastAddress, setConfirmLastAddress] = useState<boolean>(false)
  const [openConfirmLastAddress, setOpenConfirmLastAddress] = useState<boolean>(false)

  const [user, setUser] = useState<{
    name: InObject
    phone: InObject
    email: InObject
  }>({
    name: {
      value: data.customer?.name ?? '',
      clicked: false
    },
    email: {
      value: data.customer?.email ?? '',
      clicked: false
    },
    phone: {
      value: data.customer?.phone ?? '',
      clicked: false
    }
  })

  useEffect(() => {
    if (!brand?._id) return
    (async () => {
      await prepareOrder();
    })()
    payMethods.forEach((m) => {
      if (m.id === payMethod) 
        m.selected = true; 
      else m.selected = false
    })
  }, [data])

  useEffect( () => {
    setLoading(loading);
  }, [loading])

  const handleChange = (e: any, key: 'name' | 'phone' | 'email') => {
    const d = {
      value: key === 'phone' ? e.target.value.trim() : e.target.value,
      clicked: true
    }
    setUser({ ...user, [key]: { ...d } })
  }

  const handleClick = (e: any, key: 'name' | 'phone' | 'email') => {
    const d = {
      value: user[key].value,
      clicked: true
    }
    setUser({ ...user, [key]: { ...d } })
  }

  useEffect(() => {
    const numOutlet = cart.orders?.filter(
      (outlet) => outlet.selected || outlet.items.filter((item) => item.selected).length
    )?.length
    setNumOutlet(numOutlet)
  }, [cart])

  const getNotice = () => {
    return {
      title: '📦️ Lưu ý tách đơn',
      detail: `Đặt hết các món trong giỏ hàng, bạn sẽ cần nhận ${numOutlet} đơn giao hàng khác nhau.`,
      showIcon: false
    }
  }

  const handleCheckCoupon = async () => {
    if (!cart.coupon) {
      return
    }
    setLoading(true)
    setSubmitCode(true)
    if (cart?.coupon?.length){
      await prepareOrder()
    }
  }

  
  const handleOrder = async () => {
    setLoading(true)
    try {
      await createOrder(
        {
          name: user.name.value.trim(),
          phone: user.phone.value.trim(),
          email: user.email.value.trim(),
          uuid: uuid
        },
        payMethod,
        intendTime,
        {
          type: currentStore?.type === 'h' ? 'hubId' : 'storeId',
          id: currentStore?._id || '',
        },
        cart.coupon,
      )

      if (cart.address?.latitude && cart.address?.longitude) {
        setAddress({
          placeId: cart.address?.placeId,
          text: cart.address?.text,
          pos: {
            lat: cart.address!.latitude!,
            lng: cart.address!.longitude!,
          },
          isLast: true,
        })
      }

      history.push(`${pre}/dat-hang-thanh-cong?method=${cart.needShip ? 'ship' : 'tostore'}`)
    } catch (e: any) {
      console.log(e)
      alert(e.response.data.error.message)
    }
    setLoading(false)
  }

  const checkOut = async () => {
    let error = false

    if (user.name.value.trim() === '') {
      user.name.clicked = true
      error = true
    }
    if (user.phone.value === '' || !phoneReg.test(user.phone.value)) {
      user.phone.clicked = true
      error = true
    }

    if (error === true) {
      setUser({ ...user, name: { ...user.name }, phone: { ...user.phone } })
    }

    if (cart.needShip && !cart.address) {
      setAddressError(true)
      error = true
    }

    setHasError(error)

    if (error) return

    if (cart.needShip && cart.address?.isLast && !confirmLastAddress) {
      setOpenConfirmLastAddress(true)
      return
    }

    handleOrder()
  }

  const appliedCoupon: any = useMemo(() => {
    return cart.orders.reduce((applied, order) => {
      return order.coupon_applied || applied
    }, false)
  }, [cart.orders])

  const isNoItems = !cart.orders.reduce((noItems, { items }) => {
    return noItems || !!items.length
  }, false)

  return (
    <>
      <HomeHeader showTop={false} showMenu={false} hideBorder={true} />
      {valid() ? (
        <>
          <NavBar
            style={{
              // background: scrollPosition > 1 ? 'white' : 'unset',
              // borderBottom: scrollPosition > 1 ? `1px solid ${grey[300]}` : 'unset',
            }}
          >
            <ContainerStyled>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Wrap>
                    <BackIcon onClick={history.goBack}  />
                    <Title>Xác nhận đơn hàng</Title>
                    <div className='f-1'></div>
                  </Wrap>
                </Grid>
              </Grid>
            </ContainerStyled>
          </NavBar>
          <ContainerStyled style={{ marginBottom: 20 }}>
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <Panel>
                  <PanelWrap>
                    <SwitchButton
                      sm={false}
                      value={cart.needShip}
                      loading={loading}
                      onShipChange={(value: boolean) => onChangeNeedShip(value)}
                    />
                    {cart.needShip ? (
                      <div>
                        <ShipLabel>Giao hàng tới</ShipLabel>
                        <WrapInput style={{ borderColor: addressError ? red[700] : grey[300] }}>
                          <InputBase
                            required
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Nhập vị trí của bạn *"
                            inputProps={{ 'aria-label': 'Nhập vị trí của bạn *' }}
                            value={cart.address?.text}
                            onClick={() => setShowAddress(true)}
                            startAdornment={<PointIcon />}
                          />
                        </WrapInput>
                        {addressError ? (
                          <div
                            style={{
                              fontSize: 12,
                              textAlign: 'left',
                              marginTop: 3,
                              color: red[700],
                              marginLeft: 14
                            }}
                          >
                            Bạn cần nhập địa chỉ
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <>
                        <Typography fontWeight="bold" mt={2}>
                          Đơn hàng đến lấy
                        </Typography>
                        <Typography>
                          Vui lòng tới (các) cửa hàng tại địa chỉ trong chi tiết đơn hàng dưới đây
                          để nhận đơn
                        </Typography>
                      </>
                    )}
                  </PanelWrap>
                  <Label>Thời gian {cart.needShip ? 'giao hàng' : 'đến lấy'}</Label>

                  <DeliveryTime hasShip={cart.needShip} changeDate={(time) => setIntendTime(time)}/>

                  <Label>Thông tin liên hệ</Label>
                  <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={6}>
                      <TextFieldStyled
                        required
                        label="Họ tên"
                        variant="outlined"
                        value={user.name.value}
                        onChange={(event: any) => handleChange(event, 'name')}
                        onBlur={(event: any) => handleClick(event, 'name')}
                        error={user.name.value.trim() === '' && user.name.clicked}
                        helperText={
                          user.name.value.trim() === '' && user.name.clicked
                            ? 'Bạn cần nhập tên'
                            : ' '
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldStyled
                        required
                        label="Số điện thoại"
                        variant="outlined"
                        value={user.phone.value}
                        onChange={(event: any) => handleChange(event, 'phone')}
                        onBlur={(event: any) => handleClick(event, 'phone')}
                        error={(user.phone.value.trim() === '' || !phoneReg.test(user.phone.value.trim())) && user.phone.clicked}
                        helperText={user.phone.clicked
                          && (user.phone.value.trim() === ''
                            ? 'Bạn cần nhập số điện thoại'
                            : !phoneReg.test(user.phone.value.trim())
                            ? 'Số điện thoại chưa chính xác'
                            : '')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldStyled
                        label="Email"
                        variant="outlined"
                        value={user.email.value}
                        onChange={(event: any) => handleChange(event, 'email')}
                      />
                    </Grid>
                  </Grid>
                </Panel>
                {/* TODO: update */}
                {!loading && <>
                  <OrderTitle>Chi tiết đơn hàng</OrderTitle>
                  {isNoItems && <Box>Các món bạn chọn có thể đã hết hạn bán. Vui lòng chọn lại món khác</Box>}
                  {cart.orders.map((item, index) => (
                    <CartWrap key={index}>
                      <CartGroup
                        key={index}
                        index={index}
                        data={item}
                        cart={cart}
                        openAddress={() => setShowAddress(true)}
                      />
                    </CartWrap>
                  ))}
                </>}
                {/* {!loading && <>
                  <OrderTitle>Chi tiết đơn hàng</OrderTitle>
                  {cart.orders.map((item, index) => 
                    <CartWrap key={index}>
                      <CartGroup
                        key={index}
                        index={index}
                        canEdit={false}
                        data={item}
                        openAddress={() => setShowAddress(true)}
                      />
                    </CartWrap>
                  )}
                </>} */}
              </Grid>
              <Grid item md={4} xs={12}>
                <Pay>
                  <div style={{ padding: '16px 12px' }}>
                    {!cart.address?.text && cart.needShip && (
                      <Notice data={{ ...NO_DELIVERY_ADDRESS, showIcon: false }} />
                    )}

                    {numOutlet > 1 && <Notice data={getNotice()} />}

                    <div style={{ marginBottom: 20 }}>
                      <Label>Phương thức thanh toán</Label>
                      <ButtonGroup
                        payMethods={payMethods}
                        select={(value: string) => setPayMethod(value)}
                      />
                    </div>

                    <div style={{ marginBottom: 20 }}>
                      <Label>Mã giảm giá</Label>
                      <CodeInput
                        loading={isLoading}
                        value={cart.coupon}
                        onSubmit={() => handleCheckCoupon()}
                        onCancel={() => {
                          setSubmitCode(false)
                          cancelCoupon()
                        }}
                        onChange={(value: string) => {
                          setCode(value.toUpperCase())
                          setSubmitCode(false)
                        }}
                      />
                      {(!isLoading && submitCode && cart.coupon && !appliedCoupon)
                        && <CodeError>Mã giảm giá không hợp lệ hoặc đã hết hạn sử dụng</CodeError>}
                    </div>
                    <Line style={{ borderTop: `1px solid ${grey[300]}` }}>
                      <LineText>Tổng tạm tính</LineText>
                      <Price>{formatCurrency(cart.totalItemPrice)}</Price>
                    </Line>
                    {cart.needShip && cart.address && (
                      <Line>
                        <LineText>Tổng phí giao hàng tạm tính</LineText>
                        <Price>{formatCurrency(cart.totalShippingFeeDisplay)}</Price>
                      </Line>
                    )}
                    {cart.totalItemPromotionPrice > 0 && <Line>
                      <LineText>Giảm giá</LineText>
                      <Price>-{formatCurrency(cart.totalItemPromotionPrice)}</Price>
                    </Line>}
                  
                    <Line>
                      <LineText>Tổng thanh toán</LineText>
                      <Price size={24}>
                        {formatCurrency(!cart.needShip && cart.address
                          ? cart.totalPrice
                          : cart.totalPrice + cart.totalShippingFeeDisplay > 0
                            ? cart.totalPrice + cart.totalShippingFeeDisplay
                            : 0
                        )}
                      </Price>
                    </Line>
                  </div>
                  <div style={{ padding: '12px', borderTop: `1px solid ${grey[300]}` }}>
                    {hasError && (
                      <div style={{ fontSize: 12, marginBottom: 3, color: red[700] }}>
                        Bạn chưa điền đầy đủ thông tin
                      </div>
                    )}
                    <HightOutlinedButton
                      disabled={isLoading}
                      onClick={() => checkOut()}
                      style={{ height: 56, width: '100%' }}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                      ) : (
                        'Đặt hàng'
                      )}
                    </HightOutlinedButton>
                  </div>
                </Pay>
              </Grid>
            </Grid>
          </ContainerStyled>

          <AddressModal
            open={showAddress}
            onClose={() => {
              setShowAddress(false)
            }}
            onOk={(pos: AddressPos) => {
              setAddress({
                placeId: pos.placeId,
                text: pos.text,
                pos: {
                  lat: pos.pos.lat,
                  lng: pos.pos.lng
                }
              })
              setShowAddress(false)
            }}
          />
        </>
      ) : (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          Bạn chưa có món ăn nào trong giỏ hàng!
        </div>
      )}
      <Popup title="Xác nhận địa chỉ giao hàng" open={openConfirmLastAddress}>
        <div>Xác nhận địa chỉ giao hàng của bạn là: {cart.address?.text}</div>
        <div style={{display: 'flex', justifyContent: 'center', margin: '16px 0'}}>
          <Button
            style={{
              textTransform: "none",
              borderRadius: 8,
              color: red[500],
              width: 126,
              marginRight: 10,
            }}
            onClick={() => {
              setOpenConfirmLastAddress(false)
            }}
          >
            Quay lại
          </Button>
          <HightOutlinedButton
            onClick={() => {
              setOpenConfirmLastAddress(false)
              setConfirmLastAddress(true)
              handleOrder()
            }}
            style={{
              height: 40,
              width: 126,
              cursor: 'pointer',
            }}
          >
            Xác nhận
          </HightOutlinedButton>
        </div>
      </Popup>
    </>
  )
}
const NavBar = styled('div')({
  top: 72,
  zIndex: 100,
  '@media (max-width: 799px)': {
    background: 'white',
    borderBottom: `1px solid ${grey[300]}`,
    marginBottom: 16,
    position: 'sticky',
  }
})

const Wrap = styled('div')({
  display: 'flex',
  height: 64,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 12px',
})

const Title = styled('div')(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'center',
  fontSize: 24,
  fontWeight: 700,
  [theme.breakpoints.only('xs')]: {
    fontSize: 16
  }
}))

const BackIcon = styled('div')({
  flex: 1,
  width: 48,
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left',
  backgroundImage: `url("${LeftImg}")`,
  flexShrink: 0,
  cursor: 'pointer'
})

const ShipLabel = styled('div')({
  fontSize: 14,
  fontWeight: 700,
  margin: '16px 0px'
})

const Panel = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 16,
  padding: '16px 12px',
  [theme.breakpoints.only('xs')]: {
    borderRadius: 0
  }
}))

const Pay = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 16,
  position: 'sticky',
  top: 140,
  [theme.breakpoints.only('xs')]: {
    position: 'unset',
    borderRadius: 0
  }
}))

const CartWrap = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 16,
  marginBottom: 20,
  [theme.breakpoints.only('xs')]: {
    borderRadius: 0
  },
  '&:last-child': {
    marginBottom: 0
  },
  '@media (max-width: 500px)': {
    padding: '0 12px'
  }
}))

const PanelWrap = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  marginTop: 20
})

const Label = styled('div')({
  fontWeight: 700,
  margin: '20px 0px'
})

const OrderTitle = styled(Label)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    padding: '0px 16px'
  }
}))

const ContainerStyled = styled(Container)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    padding: 0,
    borderRadius: 0
  }
}))

const WrapInput = styled('div')({
  border: `1px solid ${grey[300]}`,
  borderRadius: '8px',
  height: '48',
  display: 'flex',
  flexGrow: 1,
  margin: 'auto',
  maxWidth: 356,
  '@media (max-width: 599px)': {
    maxWidth: 'unset'
  }
})

const PointIcon = styled('div')({
  width: 30,
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `url("${PointImg}")`
})

const Line = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0px'
})

const LineText = styled('div')({
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

const Price = styled('div')<{ size?: number }>(({ theme, size = 16 }) => ({
  fontSize: size + 'px',
  fontWeight: 700,
  '@media (max-width: 599px)': {
    fontSize: size === 16 ? 14 : 16 ,
  }
}))

const CodeError = styled('div')({
  fontSize: 12,
  color: red[500],
  marginTop: 5
})

const TextFieldStyled = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '14px 14px',
    fontSize: 14
  },
  '& fieldset': {
    top: 0
  },
  '& label.Mui-focused': {
    color: grey[300]
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `1px solid ${grey[300]}`
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: grey[300]
    },
    '&.Mui-focused fieldset': {
      borderColor: grey[300]
    }
  }
})

export default Checkout
