import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import {Point, Title, Wrap} from './components';
import { User } from "src/models/User";
interface props{
  data: User;
}
const UserInfo: React.FC<props> = ({data}) => {
  return (
    <Wrap>
      <Title>Thông tin người nhận</Title>
      <div>{data.name} <span style={{fontSize: 18, margin: '0px 8px', fontWeight: 700}}>·</span> {data.phone}</div>
      {data.address && <Address>
        <Point></Point>
        {data.address}
      </Address>}
    </Wrap>
  );
};

const Address = styled('div')`
  display: flex;
  margin-top: 8px;
`


export default UserInfo;
