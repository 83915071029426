import React, { useMemo } from 'react'
import { styled } from '@mui/system'
import { HightOutlinedButton, OutlinedButton } from 'src/components/Button/OutlinedButton'
import { grey } from 'src/utils/color'
import Coupon from 'src/pages/Checkout/Coupon'
import {useCart} from 'src/components/Cart/CartContext'

interface props {
  value: string
  loading: boolean
  onChange: (e: any) => void
  onSubmit: () => void
  onCancel: () => void
}
const CodeInput: React.FC<props> = ({ value, loading, onChange, onSubmit, onCancel }) => {
  const { cart } = useCart()
  const appliedCoupon: any = useMemo(() => {
    return cart.orders.reduce((applied, order) => {
      return order.coupon_applied || applied
    }, false)
  }, [cart.orders])

  return (
    <Wrap>
      <Label>Mã giảm giá</Label>
      {appliedCoupon
        ? (<>
          <Coupon code={cart.coupon}></Coupon>
          <OutlinedButton
            onClick={() => onCancel()}
            disabled={loading}
            style={{
              height: 28,
              flexShrink: 0,
              fontSize: 12,
              alignItems: 'center',
              justifyContent: 'center',
              width: 105
            }}
          >
            Bỏ áp dụng
          </OutlinedButton>
        </>)
        : (<>
          <input value={value} onChange={(e) => onChange(e.target.value)}></input>
          <HightOutlinedButton
            disabled={loading}
            onClick={() => onSubmit()}
            style={{
              height: 28,
              flexShrink: 0,
              fontSize: 12,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Áp dụng
          </HightOutlinedButton>
        </>)
      }
    </Wrap>
  )
}

const Wrap = styled('div')({
  border: `1px solid ${grey[300]}`,
  borderRadius: 8,
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 9,
  '& > input': {
    width: '100%',
    flexGrow: 1,
    border: 'none',
    marginRight: 10,
    textTransform: 'uppercase',
    '&:focus-visible': {
      outline: 'none'
    }
  }
})

const Label = styled('div')({
  fontSize: 12,
  color: grey[300],
  position: 'absolute',
  top: -10,
  left: 10,
  background: 'white',
  padding: '0px 5px'
})
export default CodeInput
