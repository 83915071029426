import Container from '@mui/material/Container'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoImg } from 'src/assets/images/logo.svg'
import { ReactComponent as MenuIcon } from 'src/assets/images/menu.svg'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { ReactComponent as CartIcon } from 'src/assets/images/cart.svg'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { subBrand } from 'src/models/Brand'
import { useCart } from 'src/components/Cart/CartContext'
import { useBrand } from 'src/pages/provider'
import { IconButton } from '@mui/material'
import HamburgerMenu from './hamburgerMenu'
import PopupUser from './userPopup'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddressModal from 'src/pages/Location/AddressModal'
import { AddressPos } from "src/models/AddressPos";
import StoreChecker from 'src/components/Cart/StoreChecker'

const Wrap = styled('div')<{ hideBorder?: boolean }>(({hideBorder}) => ({
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  background: 'white',
  position: 'sticky',
  zIndex: 1000,
  '@media (max-width: 799px)': {
    boxShadow: hideBorder ? 'unset' : '0px 1px 0px rgba(0, 0, 0, 0.1)',
  }
}))
const HightLight = styled.div`
  background: ${(props) => props.theme.mainColor};
  color: white;
  font-size: 14px;
  padding: 7px;
  text-align: center;
`
const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  > div > img {
    @media (max-width: 599px) {
      width: 135px;
    }
  }
`
const WrapHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`
const WrapIcon = styled.div`
  display: flex;
  justify-content: space-between;
`

// const Icon = styled.div`
//   width: 48px;
//   height: 48px;
//   background-repeat: no-repeat;
//   background-position: center;
//   cursor: pointer;
//   border-radius: 8px;
//   &:hover {
//     background-color: #feede9;
//   }
// `
const Menu = styled.ul`
  padding-inline-start: 0px;
  font-size: 14px;
  padding: 0px 0px 8px;
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
`

export const Item = styled.li`
  list-style: none;
  display: inline;
  margin: 0px 15px;
  > a {
    text-decoration: none;
    color: black;
    padding: 5px 0px;
    display: inline-block;
    &:after {
      content: '';
      display: block;
      margin: auto;
      height: 2px;
      width: 0;
      transition: all 0.3s;
      margin-top: 5px;
    }
    &:hover::after {
      width: 100%;
      background: black;
    }
  }
`
const CartWrap = styled.div`
  position: relative;
`

const Number = styled.div`
  background-color: ${(props) => props.theme.mainColor};
  position: absolute;
  font-size: 12px;
  color: white;
  border-radius: 12px;
  font-weight: 700;
  right: -5px;
  top: -8px;
  padding: 3px 7px;
  text-align: center;
`
const Address = styled.div`
  flex-grow: 1;
  font-size: 14px;
  padding-bottom: 5px;
`
const PickLabel = styled.span`
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const hidenAddressModalPage = [
  '/thanh-toan',
  '/dat-hang-thanh-cong',
  '/don-hang',
]

const HomeHeader: React.FC<{ showMenu?: boolean; showTop?: boolean; subBrand?: subBrand[], hideBorder?: boolean }> = ({
  showMenu,
  showTop,
  subBrand,
  hideBorder,
}) => {
  const history = useHistory()

  const { cart, totalItem, toggleCart, setAddress, data } = useCart()
  const [open, setOpen] = useState<boolean>(false)
  const { brand, pre } = useBrand()
  const [showAddress, setShowAddress] = useState<boolean>(false)
  const [selectBrand, setSelectBrand] = useState<subBrand | undefined>()
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)

  const location = useLocation();

  const windowSize = useWindowSize()

  const handleClose = (value: string) => {
    setShowAddress(false);
  };

  const handleOk = (pos: AddressPos) => {
    setAddress({
      placeId: pos.placeId,
      text: pos.text,
      pos: {
        lat: pos.pos.lat,
        lng: pos.pos.lng
      }
    })
    setShowAddress(false);
  };

  const genText = (text: string) => {
    if (text.length >= 49) return `${text.slice(0, 48)}...`;
    return text;
  }

  const clickSubBrand = (e: any, brand: subBrand, index: number) => {
    setSelectBrand(brand)
    setOpenStoreCheck(true)
    e.preventDefault()
  }

  return (
    <>
      {/* {(showTop && windowSize && windowSize.width && windowSize.width < 599) && <HightLight>
            Chuỗi nhà hàng số đầu tiên tại Việt Nam phục vụ món giao đi tiêu chuẩn cao - Chỉ nhận đặt
            món từ 10:00 Sáng - 08:00 Tối
          </HightLight>} */}
      <Wrap hideBorder={hideBorder} style={{
        top: (showTop && windowSize && windowSize.width && windowSize?.width < 599) ? '-1px' : '0px',
        position: location.pathname.includes('/thuc-don') ? 'static' : 'sticky',
      }}>
        {(showTop && windowSize && windowSize.width && windowSize?.width >= 599) &&
          <HightLight>
            Chuỗi nhà hàng số đầu tiên tại Việt Nam phục vụ món giao đi tiêu chuẩn cao - Chỉ nhận đặt
            món từ 10:00 Sáng - 08:00 Tối
          </HightLight>
        }
        <Container>
          <MainHeader>
            <WrapHeaderLeft>
              <WrapIcon>
                <IconButton sx={{ borderRadius: 2, paddingLeft: 0 }} onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
                {/* <IconButton>
                  <SearchIcon />
                </IconButton> */}
              </WrapIcon>
              <Link style={{flexShrink: 0, marginLeft: windowSize.isMobile ? 0 : 25, marginRight:  windowSize.isMobile ? 0 : 60}} to={`${pre}`}>
                {brand?.logo ? (
                  <img
                    height={windowSize.isMobile ? 28 : 40}
                    src={brand?.logo}
                    alt={brand?.name}
                  />
                ) : (
                  <LogoImg height={windowSize.isMobile ? 28 : 40} title={brand?.name} />
                )}
              </Link>
            </WrapHeaderLeft>
            {!windowSize.isMobile && <Address>
              <div style={{maxWidth: 500, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <span style={{marginRight: 10, flexShrink: 0}}>Giao tới/Đến lấy từ</span>
                <PickLabel onClick={() => setShowAddress(true)}>
                  <span>{cart.address?.text ? genText(cart.address?.text) : 'Nhập địa chỉ của bạn'}</span>
                  <KeyboardArrowDownIcon/>
                </PickLabel>
              </div>
            </Address>}
            <WrapIcon>
              {/* <PopupUser /> */}
              <IconButton
                id="cart-icon"
                sx={{ borderRadius: 2, fontSize: 0 }}
                onClick={() => toggleCart(true)}
              >
                <CartWrap>
                  <CartIcon />
                  {totalItem > 0 ? <Number>{totalItem}</Number> : ''}
                </CartWrap>
              </IconButton>
            </WrapIcon>
          </MainHeader>
          {windowSize.isMobile && !hidenAddressModalPage.some((path) => location.pathname.includes(path))
            && <Address>
              <div style={{maxWidth: 500}}>
                <span style={{marginRight: 10}}>Giao tới/Đến lấy từ</span>
                <PickLabel onClick={() => setShowAddress(true)}>
                  <span>{cart.address?.text ? genText(cart.address?.text) : 'Nhập địa chỉ của bạn'}</span>
                  <KeyboardArrowDownIcon/>
                </PickLabel>
              </div>
            </Address>
          }
          {showMenu && (
            <Menu>
              <Item style={{marginLeft: 0}}>
                <Link className="active" to={`${pre}`}>
                  Trang chủ
                </Link>
              </Item>
              <Item>
                <Link to={`${pre}/ban-do`}>Tất cả điểm bán</Link>
              </Item>
              {!!brand?.subBrands &&
                brand?.subBrands.map((brand: subBrand, index: number) => (
                  <Item key={index}>
                    <Link to={`${pre}/thuc-don?b=` + brand.slug} onClick={(e) => {
                      clickSubBrand(e, brand, index)
                    }}>
                      {brand.name}
                    </Link>
                  </Item>
                ))}
            </Menu>
          )}
        </Container>

        <HamburgerMenu open={open} setOpen={setOpen} />
        <AddressModal
            open={showAddress}
            onClose={handleClose}
            onOk={handleOk}
          />
      </Wrap>
      {selectBrand && <StoreChecker
        open={openStoreCheck}
        store={{
          _id: selectBrand._id || '',
          name: selectBrand.name,
          slug: selectBrand.slug || '',
          url: `${pre}/thuc-don?b=${selectBrand.slug}`
        }}
        onOk={() => {
          history.push(`${pre}/thuc-don?b=${selectBrand.slug}`)
        }}
        onClose={() => {
          setOpenStoreCheck(false)
        }}
      />}
    </>
   
  )
}
export default HomeHeader
