import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import LeftImg from 'src/assets/icons/white-nav-left.svg'
import OpenLinkImg from 'src/assets/icons/open-link.svg'
import Infor from 'src/assets/icons/infor.svg'
import InforWhite from 'src/assets/icons/infor-white.svg'
import { useHistory } from 'react-router-dom'
import { HubPageInterface } from 'src/models/HubPage'
import InforDetail from './InforDetail'
import PickStoreModal from './PickStoreModal'
import ModalShare from './ModalShare'
import Popup from 'src/components/Modal/Popup'
import PointImg from 'src/assets/icons/point.svg'
import ClockImg from 'src/assets/icons/clock.svg'
interface Props {
  data: HubPageInterface
}

const InforCover: React.FC<Props> = ({ data }) => {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const openPickStore = () => {
    setOpen(true)
  }

  const handleShowShare = () => {
    setShow(true)
  }

  const handleCloseShare = () => {
    setShow(false)
  }

  return (
    <Wrap>
      <Cover
        style={{
          backgroundImage: `url(${data.cover ?? require('../../assets/images/cover.svg').default})`
        }}
      >
        <TopBg />
        <BotBg />
        <Control>
          {history.action !== 'POP' ? <BackIcon onClick={history.goBack} /> : <div></div>}
          <WrapIcon>
            <MobileDetailBrand onClick={() => {setShowInfo(true)}} />
            <OpenLink onClick={handleShowShare} />
          </WrapIcon>
          <Popup
            children={
              <ModalShare
                type={data.type}
                handleClose={handleCloseShare}
                name={data.name}
                slug={data.slug}
              />
            }
            title="Chia sẻ Đặt món"
            open={show}
            onClose={handleCloseShare}
            defaultClose
          />
        </Control>
        <Control style={{top: 'auto', bottom: '-34px', zIndex: 10, justifyContent: 'end'}}>
          {/* {data.type !== 'brand' && <MobileDetail onClick={() => {setShowInfo(true)}} />} */}
        </Control>
      </Cover>

      <HubWrap>
        <HubDetail>
          <Avatar>
            <img src={data.logo} alt={data.name} />
          </Avatar>
          {data.type === 'hub' && (
            <InforDetail
              name={data.name}
              address={data.address}
              open={data.open}
              todayTime={data.todayTime}
              openPickStore={() => openPickStore()}
            />
          )}
          {data.type === 'brand' && data.activeStore && (
            <InforDetail
              brand={data.name}
              name={data.activeStore.name}
              address={data.activeStore.address}
              open={data.activeStore.open}
              todayTime={data.activeStore.todayTime}
              openPickStore={() => openPickStore()}
            />
          )}
        </HubDetail>
      </HubWrap>
      {data.type === 'brand' && data.stores && (
        <PickStoreModal
          onClose={() => setOpen(false)}
          open={open}
          stores={data.stores}
          name={data.name}
          logo={data.logo}
        />
      )}
      <Popup
        children={
          <>
            <div><b>
              {data.name} {data.activeStore && `- ${data.activeStore.name}`}
            </b></div>
            <DetailItem>
              <DetailItemIconLocation />
              <span>{data.address || data.activeStore?.address}</span>
            </DetailItem>
            <DetailItem>
              <DetailItemIconTime />
              <span>
                Giờ phục vụ: {data.todayTime?.times.map((time, index) => (
                  `${time[0]} - ${time[1]}${
                    data.todayTime && index < data.todayTime?.times.length - 1
                      ? ', '
                      : ''
                  }`
                ))}
              </span>
              
            </DetailItem>
          </>
        }
        title="Thông tin điểm bán"
        open={showInfo}
        onClose={() => {setShowInfo(false)}}
        defaultClose
      />
    </Wrap>
  )
}
const Wrap = styled.div`
  margin-top: 16px;
  @media (max-width: 799px) {
    margin-top: 0;
  }
`
const Cover = styled.div`
  position: relative;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  // overflow: hidden;
  background-position: center;
  background-color: white;
  @media (max-width: 799px) {
    border-radius: 0;
  }
  @media (max-width: 599px) {
    height: 160px;
  }
`
const HubWrap = styled.div`
  position: relative;
  margin-bottom: 24px;
  // @media (max-width: 899px) {
  //   margin-bottom: 250px;
  // }
  // @media (max-width: 599px) {
  //   margin-bottom: 220px;
  // }
`
const Control = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 15px;
`
const WrapIcon = styled.div`
  display: flex;
`
const BackIcon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${LeftImg}');
  flex-shrink: 0;
  cursor: pointer;
`
const OpenLink = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${OpenLinkImg}');
  flex-shrink: 0;
  cursor: pointer;
`
const MobileDetail = styled.div`
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${Infor}');
  background-color: #FFF;
  flex-shrink: 0;
  cursor: pointer;

  @media (max-width: 899px) {
    display: block;
  }
`
const MobileDetailBrand = styled.div`
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${InforWhite}');
  background-size: 40px;
  flex-shrink: 0;
  cursor: pointer;

  @media (max-width: 899px) {
    display: block;
  }
`
const HubDetail = styled.div`
  margin-top: -83px;
  display: flex;
  padding: 0px 20px;
  // position: absolute;
  top: -83px;
  width: 100%;

  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 599px) {
    margin-top: -40px;
    top: -40px;
  }
`

const Avatar = styled.div`
  flex-shrink: 0;
  margin-right: 20px;

  > img {
    width: 160px;
    height: 160px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: white;
    object-fit: contain;
  }

  @media (max-width: 899px) {
    margin-right: 0px;
  }
  @media (max-width: 599px) {
    margin-right: 0px;
    margin-bottom: 8px;

    > img {
      width: 80px;
      height: 80px;
    }
  }
`
// const Content = styled.div`
//     flex-grow: 1;
// `

// const Brand = styled.div`
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 32px;
//     height: 32px;
//     color: white;
//     @media (max-width: 899px){
//         font-size: 14px;
//         line-height: 20px;
//         color: black;
//     }
// `

// const H1 = styled.div`
//     font-weight: 700;
//     font-size: 36px;
//     line-height: 48px;
//     color: white;
//     @media (max-width: 899px){
//         font-size: 24px;
//         line-height: 32px;
//         color: black;
//     }
// `

// const DetailItem = styled.div`
//     display: flex;
//     margin-top: 15px;
//     @media (max-width: 599px){
//         font-size: 14px;
//         line-height: 20px;
//     }
// `

// const AddressIcon = styled.div`
//     width: 22px;
//     height: 22px;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-image: url('${PointImg}');
//     margin-right: 10px;
//     flex-shrink: 0;
// `

// const ClockIcon = styled.div`
//     width: 22px;
//     height: 22px;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-image: url('${ClockImg}');
//     margin-right: 10px;
//     flex-shrink: 0;
// `

const Bg = styled.div`
  position: absolute;
  height: 72px;
  width: 100%;
`
const TopBg = styled(Bg)`
  top: 0;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 16px 16px 0 0;
  @media (max-width: 799px) {
    border-radius: 0;
  }
`

const BotBg = styled(Bg)`
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0 0 16px 16px;
  @media (max-width: 799px) {
    border-radius: 0;
  }
`
const DetailItem = styled.div`
  display: flex;
  @media (max-width: 599px){
      font-size: 14px;
      line-height: 20px;
  }
`
const DetailItemIconLocation = styled.div`width: 22px;
  display: inline-block;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${PointImg}');
  margin-right: 10px;
  flex-shrink: 0;
`
const DetailItemIconTime = styled.div`width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ClockImg}');
  margin-right: 10px;
  flex-shrink: 0;
`
export default InforCover
