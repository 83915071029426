import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions/transition'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
const Close = styled.div`
  position: absolute;
  z-index: 9999;
  top: 16px;
  left: 16px;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    color: black;
  }
`
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

interface SideScreenInterface {
  children: JSX.Element | JSX.Element[]
  open: boolean
  onClose?: () => void
  width?: number
  dialogClassName?: string
  defaultClose?: boolean
}

const SideScreen: React.FC<SideScreenInterface> = ({
  children,
  open,
  onClose,
  width = 546,
  dialogClassName,
  defaultClose
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
    return () => setOpenDialog(false)
  }, [open])

  const handleClose = () => {
    if (onClose) {
      setOpenDialog(false)
      onClose()
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          width: width,
          height: '100vh',
          maxHeight: '100vh',
          maxWidth: '100%',
          position: 'absolute',
          background: '#fafafa',
          top: 0,
          right: 0,
          margin: 0
        },
        className: `scrolling ${dialogClassName}`
      }}
    >
      {defaultClose && (
        <Close onClick={onClose}>
          <CloseIcon />
        </Close>
      )}
      {children}
    </Dialog>
  )
}
export default SideScreen
