import {styled} from '@mui/system'
import {ORDER_STATE, ORDER_STATUS} from 'src/utils/constants'
import {Stack, Typography} from '@mui/material'
import {cancelOrder, green, red} from '../../utils/color'
import {parseISO, format} from "date-fns"

interface props {
  orderState: string
  delivery: string
  intendTime: string
}

const OrderStatusBar: React.FC<props> = ({orderState, delivery, intendTime}) => {
  let color: string = red[500]
  let detail: JSX.Element = <><span>Đơn hàng đã được ghi nhận,&nbsp;</span><span>đang chờ xử lý</span></>
  let state: string = ORDER_STATUS.find((el) => el.id === orderState)?.name || ORDER_STATUS[0].name

  switch (orderState) {
    case ORDER_STATE.PENDING:
      detail = delivery === 'ship'
        ? <><span>Đơn hàng đã được xác nhận,&nbsp;</span><span>đang chờ giao</span></>
        : <><span>Đơn hàng đã được xác nhận,&nbsp;</span><span>đang chờ khách đến lấy</span></>
      break
    case ORDER_STATE.DELIVERY:
      color = green[500]
      state = ORDER_STATUS[2].name
      detail = delivery === 'ship'
        ? <>Đơn hàng đang được vận chuyển tới khách hàng</>
        : <>Đơn hàng đã được giao cho khách hàng</>
      break
    case ORDER_STATE.CANCELED:
      color = cancelOrder[500]
      state = ORDER_STATUS[4].name
      detail = <>Đơn hàng đã được hủy bởi khách hàng</>
      break
    case ORDER_STATE.DONE:
      color = green[500]
      state = ORDER_STATUS[3].name
      detail = delivery === 'ship'
        ? <>Đơn hàng đã được shipper giao cho khách hàng</>
        : <>Đơn hàng đã được giao cho khách hàng</>
      break
    default:
      break
  }

  const renderTimer = () => {
    return intendTime
      ? format(parseISO(intendTime), 'HH:mm dd/MM/yyyy')
      : delivery === 'ship'
        ? 'Giao ngay'
        : 'Đến ngay'
  }

  return (
    <Wrap color={color}>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        spacing={1}
        textAlign="center"
        justifyContent={{sm: 'space-between'}}
        mb={{xs: 0, sm: 1}}
      >
        <Typography fontWeight={700} fontSize={{xs: 14, sm: 16}}>
          {delivery === 'ship' ? 'Giao hàng' : 'Đến lấy'}
          <span style={{fontSize: 18, margin: '0px 15px'}}>·</span>
          {state}
        </Typography>

        <Typography fontSize={{xs: 14, sm: 16}} style={{marginTop: 0}}>
          Giờ hẹn:<strong>{' ' + renderTimer()}</strong>
        </Typography>
      </Stack>
      <Text textAlign={{xs: 'center', sm: 'start'}}>
        {detail}
      </Text>
    </Wrap>
  )
}

const Wrap = styled('div')((props) => ({
  padding: '16px',
  background: props.color,
  '& p': {
    color: 'white'
  }
}))

const Text = styled(Typography)({
  display: 'block',
  width: '100%',
  '>span': {
    display: 'inline-block',
  },
  '@media (max-width: 599px)': {
    fontSize: 14,
  }
})

export default OrderStatusBar
