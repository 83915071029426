import { useState, createContext, useContext, useEffect } from 'react'
import { AddressPos } from 'src/models/AddressPos'
import { Cart, CartItemInterface, StoreOrder } from 'src/models/Cart'
import { ItemOption } from 'src/models/ItemOption'
import { MenuItem } from 'src/models/MenuItem'
import { OptionChoice } from 'src/models/OptionChoice'
import { User } from 'src/models/User'
import { _createOrder, _prepareOrder, checkItem } from 'src/utils/api'
import { MENUITEM_STATUS } from 'src/utils/constants'
import {useBrand} from "../../pages/provider";

interface CartContextType {
  cart: Cart
  openCart: boolean
  couponValid: boolean
  discount: number
  uuid: string
  data: Data
  dataTotal: number
  totalItem: number
  loading: boolean
  currentStore: StoreOrder | undefined
  viewingStore: StoreOrder | undefined
  checkStore: {open: boolean, cb: Function}
  addToCart: (menuItem: MenuItem, store?: StoreOrder, cb?: (added: boolean) => void) => void
  setNote: (index: number, text: string) => void
  setCode: (code: string) => void
  remove: (index: number) => void
  checkBoxItem: (index: number, value: boolean) => void
  checkBoxAllItems: (value: boolean) => void
  toggleCart: (value: boolean) => void
  setAddress: (pos: AddressPos) => void
  updateMenuItem: (index: number, item: MenuItem) => void
  onChangeNeedShip: (value: boolean) => void
  createOrder: (u: User, payMethod: string, intendTime: string, storeId: {type: 'hubId' | 'storeId', id: string}, coupon?: string) => void
  resetCart: () => void
  valid: () => boolean
  prepareOrder: () => void
  cancelCoupon: () => void
  setCurrentStore: (store: StoreOrder | undefined) => void
  setViewingStore: (store: StoreOrder | undefined) => void
  setCheckStore: ({open, cb}: {open: boolean, cb: Function}) => void
}

interface Props {
  children: JSX.Element | JSX.Element[]
}

interface Data {
  items: MenuItem[]
  customer?: {
    name: string
    email?: string
    uuid: string
    phone: string
  }
  address?: {
    placeId?: string
    text: string
    latitude: number
    longitude: number
    isLast: boolean
  }
  needShip?: boolean
}

const CartContext = createContext<CartContextType>(null!)

const useCart = () => useContext(CartContext)

const emptyCart = {
  orders: [],
  totalItem: 0,
  finalTotal: 0,
  totalPrice: 0,
  totalShippingFee: 0,
  totalShippingFeeDisplay: 0,
  tempTotalPrice: 0,
  tempTotalShippingFee: 0,
  totalItemPrice: 0,
  needShip: true,
  coupon: '',
  discount: 0,
  paymentMethod: 'cash',
  orderTotalDirectPromotionValue: 0,
  totalItemPromotionPrice: 0,
}

const emptyData = {
  items: [],
  needShip: true,
}

const CartProvider: React.FC<Props> = ({ children }) => {
  const [openCart, setOpenCart] = useState(false)
  const [uuid, setUuid] = useState<string>('')
  const [cart, setCart] = useState<Cart>(emptyCart)
  const [couponValid, setCouponValid] = useState<boolean>(false)
  const [discount, setDiscount] = useState<number>(0)
  const [dataTotal, setDataTotal] = useState<number>(0)
  const [data, setData] = useState<Data>(emptyData)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalItem, setTotalItem] = useState<number>(0)
  const brandProvider = useBrand()
  const [currentStore, setCurrentStoreData] = useState<StoreOrder | undefined>()
  const [viewingStore, setViewingStore] = useState<StoreOrder | undefined>()
  const [checkStore, setCheckStore] = useState<{open: boolean, cb: Function}>({open: false, cb: () => {}})

  useEffect(() => {
    console.log('-->>> cart init')
    if (!brandProvider.brand?._id) return
    const init = async () => {
      const currentStore = getCurrentStore()
      const data = initData()
      setData(data)
      setCurrentStore(currentStore)
      if (data.address && data.address.latitude) {
        setCart({
          ...cart,
          address: data.address,
        })
      }
    }

    init()
    const id = initUuid()
    setUuid(id)
  }, [brandProvider.brand?._id])

  useEffect(() => {
    if (!brandProvider.brand?._id) return
    console.log('-->>> re cal data & save data', data)
    calData()
    saveData()
  }, [data])

  const findItem = (menuItem: MenuItem, items: MenuItem[]) => {
    return items.find((item: MenuItem) => {
      if (!item.options || item.options.length === 0)
        return menuItem._id.toString() === item._id.toString()
      if (item.options.length > 0 && menuItem._id.toString() === item._id.toString()) {
        for (let i = 0; i < item.options.length; i++) {
          for (let j = 0; j < item.options[i].choices.length; j++) {
            if (item.options[i].choices[j].quantity !== menuItem.options[i].choices[j].quantity)
              return false
          }
        }
        return menuItem.userNote === item.userNote
      }
      return false
    })
  }

  const addToCart = async (menuItem: MenuItem, store?: StoreOrder, cb?: (added: boolean) => void) => {
    const needCheckStore = store || viewingStore

    const handle = () => {
      setCurrentStore(store || needCheckStore)

      const item = findItem(menuItem, data.items)
      if (item) {
        item.quantity += menuItem.quantity
      } else {
        data.items.push(menuItem)
      }
      setData({
        ...data
      })

      cb && cb(true)
    }

    if (data.items.length
        && needCheckStore
        && currentStore
        && needCheckStore._id !== currentStore?._id
    ) {
      setCheckStore({open: true, cb: () => {
        handle()
      }})
      return
    }

    handle()
  }

  const prepareOrder = async () => {
    if (!data.items) {
      setCart({ ...emptyCart, address: cart.address })
      return
    }

    try {
      setLoading(true)
      let notes: string[] = []
      cart.orders.forEach((item: CartItemInterface) => {
        notes.push(item.note)
      })
      const res = await _prepareOrder({
        [currentStore!.type === 'h' ? 'hubId' : 'storeId' ]: currentStore?._id,
        ...data,
        items: data.items.filter((item: MenuItem) => item.selected),
        coupon: cart.coupon,
        website: brandProvider.brand?._id,
        notes
      })
      setCart({
        ...res.data,
        address: data.address
      })
    } catch (e: any) {
      console.log(e.response.data.error)
    }
    setLoading(false)
  }

  const setNote = (index: number, text: string) => {
    cart.orders[index].note = text
    setCart({ ...cart })
  }

  const setCode = (code: string) => {
    setCart({
      ...cart,
      coupon: code,
    })

  }

  const cancelCoupon = async () => {
    cart.coupon = ''
    await prepareOrder()
  }

  const valid = () => {
    const item = data.items.find((item: MenuItem) => item.selected)
    return item !== undefined
  }

  const calData = () => {
    let total = 0
    let totalItem = 0
    console.log('-> calData', data.items)
    data.items.forEach((item: MenuItem) => {
      totalItem += item.quantity
      
      let optionPrice = 0
      if (item.options && item.options.length > 0) {
        item.options.forEach((option: ItemOption) => {
          option.choices.forEach((choice: OptionChoice) => {
            if (choice.quantity && choice.quantity > 0)
              optionPrice += choice.price * choice.quantity
          })
        })
      }
      item.totalPerItem = item.price + optionPrice
      if (!item.selected) return
      total += item.totalPerItem * item.quantity
    })
    console.log('-> calData--2', total, totalItem, data.items)
    setDataTotal(total)
    setTotalItem(totalItem)
  }

  const saveData = () => {
    localStorage.setItem('data', JSON.stringify(data))
  }

  const remove = async (index: number) => {
    data.items.splice(index, 1)
    setData({ ...data })
  }

  const createOrder = async (
    u: User,
    payMethod: string,
    intendTime: string,
    { type, id: storeId }: { type: 'hubId' | 'storeId', id: string },
    coupon?: string,
  ) => {
    const d: any = {...data}
    d.customer = u
    d.paymentMethod = payMethod
    d.intendTime = intendTime
    d.notes = []
    d.coupon = coupon;
    d.website = brandProvider.brand?._id;
    d.items = data.items.filter((item: MenuItem) => item.selected)
    d[type] = storeId
    cart.orders.forEach((order) => {
      d.notes.push(order.note)
    })

    setData({
      ...data,
      customer: u
    })

    setCart({
      ...cart,
      coupon: '',
      coupon_applied: false,
      couponData: undefined,
    })
    await _createOrder(d)
  }

  const checkBoxItem = async (index: number, value: boolean) => {
    data.items[index].selected = value
    setData({ ...data })
  }

  const checkBoxAllItems = async (value: boolean) => {
    data.items.forEach((item) => {
      item.selected = value
    })
    setData({ ...data })
  }

  const updateMenuItem = (index: number, menuIem: MenuItem) => {
    data.items[index] = menuIem
    setData({ ...data })
  }

  const setAddress = async (pos: AddressPos) => {
    if (pos.pos.lat && pos.pos.lng && pos.text) {
      cart.address = {
        placeId: pos.placeId,
        text: pos.text,
        latitude: pos.pos.lat,
        longitude: pos.pos.lng,
        isLast: pos.isLast || false,
      }
      data.address = cart.address
      setData({ ...data })
      await prepareOrder()
    }
  }

  const toggleCart = (value: boolean) => {
    if (value) {
      checkItems();
    }
    setOpenCart(value)
  }

  const checkItems = async() => {
    try{
      setLoading(true)
      const result = await checkItem({ids: data.items.map((item) => item._id)})
      const itemMap: any = {}
      result.data.data.forEach((item: any) => itemMap[item.id] = item.status)
      data.items = data.items.reduce((arr: MenuItem[], item: MenuItem) => {
        item.status = itemMap[item._id]
        if (item.status !== MENUITEM_STATUS.ACTIVE) {
          item.selected = false;
        } else {
          arr.push(item)
        }
        return arr
      }, []);

      setData({...data})

      if (!data.items.length) return

      await prepareOrder()
    } catch (e: any){
      console.log(e.response.data.error)
    }
    setLoading(false)
  }

  const onChangeNeedShip = async (value: boolean) => {
    if (data.needShip !== value) {
      data.needShip = value
      setData({ ...data })
      await prepareOrder()
    }
  }

  const resetCart = () => {
    // data.items = data.items.filter((meuItem: MenuItem) => !meuItem.selected)
    data.items = []
    setCart({ ...emptyCart, address: cart.address })
    setData({ ...data, customer: data.customer })
    calData()
  }

  const setCurrentStore = (store: StoreOrder | undefined) => {
    setCurrentStoreData(store)
    localStorage.setItem('currentStore', JSON.stringify(store))
  }

  const initData = () => {
    try {
      const rawData = localStorage.getItem('data')
      if (rawData) {
        const data = JSON.parse(rawData)
        return data
      } else {
        return emptyData
      }
    } catch {
      return emptyData
    }
  }

  const getCurrentStore = () => {
    try {
      const rawData = localStorage.getItem('currentStore')
      return rawData ? JSON.parse(rawData) : undefined
    } catch {
      return undefined
    }
  }

  const value = {
    cart,
    openCart,
    addToCart,
    setNote,
    setCode,
    remove,
    checkBoxItem,
    checkBoxAllItems,
    toggleCart,
    setAddress,
    updateMenuItem,
    onChangeNeedShip,
    createOrder,
    resetCart,
    valid,
    prepareOrder,
    cancelCoupon,
    setCurrentStore,
    setViewingStore,
    setCheckStore,
    loading,
    dataTotal,
    totalItem,
    couponValid,
    discount,
    uuid,
    data,
    currentStore,
    viewingStore,
    checkStore,
  }
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

const initUuid = () => {
  const uuid = localStorage.getItem('uuid')
  if (uuid) {
    return uuid
  } else {
    const id = new Date().getTime().toString()
    localStorage.setItem('uuid', id)
    return id
  }
}
export default CartProvider
export { useCart }
