
import React, { useEffect } from 'react'
import { Button } from '@mui/material'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import Popup from 'src/components/Modal/Popup'
import { red } from 'src/utils/color'
import { useCart } from './CartContext'

interface Props {
  open: boolean
  onOk: () => void
  onClose: () => void
  store: { _id: string, slug: string, name: string, url: string }
}

const StoreChecker: React.FC<Props>  = ({ open, onOk, store, onClose }) => {
  const { data, resetCart, currentStore } = useCart()

  const openPopup = !!(open && currentStore?._id !== store._id && data.items.length)

  useEffect(() => {
    if (open && !openPopup) {
      onClose()
      onOk()
    }
  })

  return <Popup title="Thay đổi lựa chọn cửa hàng?" open={openPopup} onClose={onClose}>
    <div>
      Bạn đang đặt món dở tại cửa hàng {currentStore?.name} - Bạn có chắc chắn muốn đặt món lại tại cửa hàng {store.name}?
    </div>
    <div style={{display: 'flex', justifyContent: 'center', margin: '16px 0'}}>
      <Button
        style={{
          textTransform: "none",
          borderRadius: 8,
          color: red[500],
          width: 126,
          marginRight: 10,
        }}
        onClick={() => {
          onClose()
        }}
      >
        Quay lại
      </Button>
      <HightOutlinedButton
        onClick={() => {
          onClose()
          resetCart()
          onOk()
        }}
        style={{
          height: 40,
          width: 126,
          cursor: 'pointer',
        }}
      >
        Xác nhận
      </HightOutlinedButton>
    </div>
  </Popup>
}

export default StoreChecker
