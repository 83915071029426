import React from 'react'
import styled from 'styled-components'
import ProductItem from 'src/components/ProductItem'
import { MenuItemCategory } from 'src/models/MenuItemCategory'
import { MenuItem } from 'src/models/MenuItem'
import { BrandInterface } from 'src/models/Brand'
interface Props {
  data: MenuItemCategory | null
  brand?: BrandInterface
  brandName?: string
  select: (item: MenuItem) => void
}
const GroupProduct: React.FC<Props> = ({ data, brand, brandName, select }) => {
  if (!data) return <></>
  return (
    <div id={`${brand?._id || ''}-${data._id}`} style={{ marginBottom: '10px' }}>
      <H3>{data.name}</H3>
      {data.menuitems.map((item: MenuItem, index: number) => (
        <ProductItem
          select={(item: MenuItem) => select(item)}
          key={index}
          data={item}
          brand={brandName ? brandName : brand?.name}
        />
      ))}
    </div>
  )
}
const H3 = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media (max-width: 899px) {
    margin: 10px 10px 0px 10px;
  };
  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export default GroupProduct
