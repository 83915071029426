import React, { useEffect, useMemo, useState } from 'react'
import { useBrand } from '../provider'
import Location from 'src/pages/Location'
import Home from 'src/pages/Home'
import Wrapper from 'src/pages/Wrapper'
import NotFound from '../NotFound'
const HomeWrapper: React.FC = () => {
  const { isSub, loading, brandSlug } = useBrand()


  if (isSub) {
    if (window.location.pathname === '/') {
      return <>{!loading && <Home/>}</>
    }
    if (window.location.pathname === '/ban-do') {
      return <>{!loading && <Location/>}</>
    }
    if (window.location.pathname === '/thuc-don') {
      return <>{!loading && <Wrapper/>}</>
    }
  }
  return <>
    {(!loading && brandSlug) && <Home/>}
    {(!loading && !brandSlug) && <NotFound/>}
  </>
}
export default HomeWrapper
