import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import LeftImg from 'src/assets/icons/nav-left.svg'
import OpenLinkImg from 'src/assets/icons/open-link-2.svg'
import { Container } from '@mui/material'
import { HubPageInterface } from 'src/models/HubPage'
import ArrowDownImgBlack from 'src/assets/icons/arrow-down-black.svg'
import { useHistory } from 'react-router-dom'
import PickStoreModal from './PickStoreModal'
import Popup from 'src/components/Modal/Popup'
import ModalShare from './ModalShare'
import { useCart } from 'src/components/Cart/CartContext'
import { ReactComponent as CartIcon } from 'src/assets/images/cart.svg'
interface Props {
  data: HubPageInterface
}
const NavBar: React.FC<Props> = ({ data }) => {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const { toggleCart, totalItem } = useCart()

  const openPickStore = () => {
    setOpen(true)
  }

  const handleShowShare = () => {
    setShow(true)
  }

  const handleCloseShare = () => {
    setShow(false)
  }
  return (
    <Bg>
      <Container>
        <Wrap>
          {history.action !== 'POP' && <BackIcon onClick={history.goBack}></BackIcon>}
          <Title>
            <img src={data.logo} alt={data.name} />
            {data.activeStore ? (
              <WrapName>
                <Brand>{data.name}</Brand>
                <Store onClick={() => {
                      openPickStore()
                    }}>
                  {data.activeStore.name}{' '}
                  <ArrowDown></ArrowDown>
                </Store>
              </WrapName>
            ) : (
              <div>{data.name}</div>
            )}
          </Title>
          <CartWrap onClick={() => toggleCart(true)}>
            <CartIcon />
            {totalItem > 0 ? <Number>{totalItem}</Number> : ''}
          </CartWrap>
          {/* <OpenLink onClick={handleShowShare} /> */}
          <Popup
            children={
              <ModalShare
                type={data.type}
                handleClose={handleCloseShare}
                name={data.name}
                slug={data.slug}
              />
            }
            title="Chia sẻ Đặt món"
            open={show}
            onClose={handleCloseShare}
            defaultClose
          />
        </Wrap>
      </Container>
      {data.type === 'brand' && data.stores && (
        <PickStoreModal
          onClose={() => setOpen(false)}
          open={open}
          stores={data.stores}
          name={data.name}
          logo={data.logo}
        />
      )}
    </Bg>
  )
}
const Bg = styled.div`
  background: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 10px;
  @media (max-width: 899px) {
    margin-bottom: 0px;
  }
`
const Wrap = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 899px) {
    height: 56px;
  }
`
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
  > img {
    margin-right: 12px;
    width: 48px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    object-fit: contain;
    @media (max-width: 899px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 899px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const BackIcon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${LeftImg}');
  flex-shrink: 0;
  cursor: pointer;
`
const CartWrap = styled.div`
  position: relative;
  cursor: pointer;
`
const Number = styled.div`
  background-color: ${(props) => props.theme.mainColor};
  position: absolute;
  font-size: 12px;
  color: white;
  border-radius: 12px;
  font-weight: 700;
  right: -5px;
  top: -8px;
  padding: 3px 7px;
  text-align: center;
`
const OpenLink = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${OpenLinkImg}');
  flex-shrink: 0;
  cursor: pointer;
`

const WrapName = styled.div`
  display: flex;
  flex-direction: column;
`
const Brand = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`
const Store = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
`
const ArrowDown = styled.div`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ArrowDownImgBlack}');
  cursor: pointer;
  margin-left: 10px;
`
export default NavBar
