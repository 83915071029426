import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_URL
const VIET_QR_URL = process.env.REACT_APP_VIET_QR_API_URL
export const _get = async (url: string) => {
  const response = await axios.get(BASE_URL + url)
  return response.data
}
export const _post = async (url: string, body?: any) => {
  const response = await axios.post(BASE_URL + url, body)
  return response
}

export const _put = async (url: string, body?: any) => {
  const response = await axios.put(BASE_URL + url, body)
  return response
}

export const _postBankInfo = async (url: string, body?: any) => {
  const response = await axios.post(VIET_QR_URL + url, body)
  return response
}
