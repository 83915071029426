import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { StoreInterface } from "src/models/Store";
interface Props {
  store: StoreInterface
  logo: string
  select: Function
}
const Store: React.FC<Props> = ({ store, logo,select }) => {

  return (
    <Wrap onClick={() => select(store)}>
      <img src={logo}/>
      <Content>
        <Name>{store.name}</Name>
        <Address>{store.address}</Address>
        <Status status={store.open}>{store.open ? 'Đang bán' : 'Đang đóng cửa'}</Status>
      </Content>
    </Wrap>
  );
};
const Wrap = styled('div')({
  display: 'flex',
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '16px 12px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F0FFF3',
  },
  '> img': {
    width: 114,
    height: 114,
    objectFit: 'contain',
    borderRadius: 16,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    marginRight: 10,
    flexShrink: 0,
    '@media (max-width: 599px)': {
      width: 80,
      height: 80,
    }
  }
})
const Content = styled('div')({

})
const Name = styled('div')({
  fontWeight: 700,
  fontSize: 24,
  textTransform: 'capitalize',
  '@media (max-width: 599px)': {
    fontSize: 16,
  }
})

const Address = styled('div')({
  fontSize: 14,
})

const Status = styled('div')<{status: boolean | undefined}>(({ theme, status }) => ({
  border: `1px solid ${status ? '#39B54A' : 'red'}`,
  fontSize: 12,
  color: status ? '#39B54A' : 'red',
  borderRadius: 16,
  padding: 5,
  marginTop: 10,
  width: 'fit-content'
}));
export default Store;
