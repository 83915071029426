import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import HomeHeader from 'src/components/HomeHeader'
import MotoImg from 'src/assets/images/moto.svg'
import ToStoreImg from 'src/assets/images/toStore.svg'
import { HightOutlinedButton, OutlinedButton } from 'src/components/Button/OutlinedButton'
import { useHistory } from 'react-router-dom'
import { useCart } from 'src/components/Cart/CartContext'
import { Box, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useBrand } from '../provider'
import SideScreen from '../../components/Modal/SideScreen'
import MyOrder from 'src/components/MyOrder'
import { useModalState } from 'src/utils/hooks/useModalState'
import useWindowSize from 'src/utils/hooks/useWindowSize'

const OrderSuccess: React.FC = () => {
  const history = useHistory()
  const { resetCart } = useCart()
  const { pre } = useBrand()

  const { isOpen, onOpen, onClose } = useModalState()

  const windowSize = useWindowSize()
  const [claimMethod, setClaimMethod] = useState<'ship' | 'tostore'>('ship')

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const cMethod = params.get('method');

    if (cMethod === 'ship' || cMethod === 'tostore') setClaimMethod(cMethod)
    else setClaimMethod('ship')
    resetCart()
  }, [])

  return (
    <>
      <HomeHeader showTop={false} showMenu={false} />
      <NavTitle>
        <IconButton sx={{ m: 1 }} onClick={() => history.push(`${pre}`)}>
          <CloseIcon sx={{ color: 'black' }} />
        </IconButton>
        <Box width="100%" pr={7}>
          Đặt món thành công
        </Box>
      </NavTitle>
      <Wrap>
        <Title>Đặt món thành công</Title>
        <img src={claimMethod === 'ship' ? MotoImg : ToStoreImg} style={{width: '255px'}} alt="order-success"/>
        <div>
          <p>{claimMethod === 'ship'
            ? 'Đơn hàng của bạn đã được đặt thành công & sẽ được giao ngay đâyyy...'
            : 'Đơn hàng của bạn đã được đặt thành công & vui lòng ghé cửa hàng để nhận món nhé!'}</p>
        </div>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3 }} mt={2}>
          <OutlinedButton
            onClick={onOpen}
            style={{ height: 56 }}
            sx={{
              maxWidth: {
                md: 356,
                sm: '100%'
              }
            }}
            fullWidth
          >
            Đơn hàng của bạn
          </OutlinedButton>

          <HightOutlinedButton
            onClick={() => history.push(`${pre}`)}
            style={{ height: 56 }}
            sx={{
              maxWidth: {
                md: 356,
                sm: '100%'
              }
            }}
            fullWidth
          >
            Tiếp tục đặt món
          </HightOutlinedButton>
        </Stack>

        <SideScreen open={isOpen} onClose={onClose} defaultClose={true}>
          <MyOrder />
        </SideScreen>
      </Wrap>
    </>
  )
}

const Wrap = styled('div')`
  max-width: 760px;
  margin: auto;
  text-align: center;
  padding: 20px;

  > img {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 600px) {
    padding: 12px;
  }
`

const Title = styled('div')`
  font-weight: 700;
  font-size: 24px;
  margin-top: 40px;

  @media (max-width: 600px) {
    display: none;
  }
`

const NavTitle = styled('div')`
  background: white;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-weight: 700;
  position: sticky;
  top: 72px;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
`

export default OrderSuccess
