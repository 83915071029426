import { StoreInterface } from './Store'

export interface BrandInterface {
  tintColor?: string
  cover: string
  intro?: string
  logo: string
  name: string
  domain?: string
  slug: string
  _id?: string
  id?: string
  stores?: StoreInterface[]
  subBrands: subBrand[]
  homeConfig: HomeConfig[]
}

export function formatBrand(data: any): BrandInterface {
  let brand = {
    tintColor: data.color,
    cover: data.cover,
    intro: data.intro,
    logo: data.logo,
    id: data._id,
    _id: data._id,
    domain: data.domain,
    name: data.name,
    slug: data.slug,
    subBrands: data.subBrands,
    homeConfig: data.homeConfig
  }
  return brand
}

export interface HomeConfig {
  ui: string
  pickType: string
  banners: any[]
  menuItems: []
  pos: number
  title: string
  link: string
  btnLabel: string
  btnLink: string
  status: string
}

export interface subBrand {
  cover: string
  logo: string
  name: string
  slug?: string
  _id?: string
  stores?: []
}
