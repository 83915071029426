import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { formatBrand } from 'src/models/Brand'
import { getBrandInfo } from '../brand/thunks'

interface BrandInterface {
    tintColor: string
}
const initialState: BrandInterface = {
    tintColor: '#6B0772',
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBrandInfo.fulfilled, (state, { payload }) => {
            state.tintColor = formatBrand(payload.data.data).tintColor || '#6B0772'
        })
    },
})
export default configSlice.reducer
