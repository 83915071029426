import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Button from 'src/components/Button'
import { useAppSelector } from 'src/store/hooks'
import styled from 'styled-components'

const Wrap = styled.div`
    background: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
    > div.text {
        margin: 0px 16px 32px 16px;
        text-align: center;
    }
`
const CheckedIcon = styled.div`
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3.5px solid #7db021;
    > svg {
        color: #7db021;
        width: 48px;
        height: 48px;
    }
`
interface SuccessOrderInterface {
    onClose: () => void
}
const SuccessOrder: React.FC<SuccessOrderInterface> = (props) => {
    const storeData = useAppSelector(state => state.store)
    return (
        <Wrap>
            <CloseRoundedIcon
                onClick={props.onClose}
                style={{ fontSize: 24, color: '#475f7b', position: 'absolute', top: 20, left: 12 }}
            />
            <CheckedIcon>
                <CheckRoundedIcon />
            </CheckedIcon>
            <p className='heading-3' style={{ color: '#7db021', margin: '16px 0px 48px' }}>
                Đặt hàng thành công
            </p>
            <div className='text'>
                <p>Đơn hàng bạn đặt đã được ghi nhận!</p>
                <p>
                    {storeData.selectedStore.address} sẽ xử lý & liên hệ với bạn trong vòng <b>5 phút</b>!
                </p>
            </div>
            <div className='text'>
                <p>
                    Sau 5 phút, nếu không nhận được cuộc gọi, vui lòng liên hệ hotline <b>{storeData.selectedStore.phone}</b> để được hỗ
                    trợ
                </p>
            </div>
            <Button onClick={()=> window.location.href = `tel:${storeData.selectedStore.phone}`}>
                <p>Gọi Hotline</p>
            </Button>
        </Wrap>
    )
}
export default SuccessOrder
