import 'flatpickr/dist/themes/material_green.css'
import styled from 'styled-components'
import Flatpickr from 'react-flatpickr'
import { useEffect } from 'react'

interface PropsInterface {
    label?: string
    date: string | number | Date
    endIcon?: any
    onChange?: (date: any) => void
}
const Wrap = styled.div`
    padding: 6px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    display: flex;
    width: 100%;
    > input {
        width: 100%;
        border: none;
        font-family: inherit;
        font-size: 14px;
        outline: unset;
    }
`
export default function TimePicker(props: PropsInterface) {
    useEffect(() => {
        const date = new Date()
        props.onChange && props.onChange(date.toISOString())
    }, [])
    const changeHandler = (value: Date[]) => {
        props.onChange && props.onChange(value[0].toISOString())
    }
    return (
        <>
            <p>{props.label}</p>
            <Wrap>
                <Flatpickr
                    options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        disableMobile: true,
                        minDate: 'today',
                    }}
                    value={props.date}
                    onChange={changeHandler}
                />
                {props.endIcon}
            </Wrap>
        </>
    )
}
