import React, { useRef, useState, forwardRef } from 'react'
import { styled } from '@mui/system'
import ItemPicker from '../ItemPicker'
import RadioList from '../RadioList'
import PlusItem from './PlusItem'
import { formatCurrency } from 'src/utils/myUtils'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { MenuItem } from 'src/models/MenuItem'
import { ItemOption } from 'src/models/ItemOption'
import LeftImg from 'src/assets/icons/nav-left.svg'
import { useItemDetail } from 'src/components/ItemDetail/ItemDetailContext'
import { useCart } from '../Cart/CartContext'
import { OptionChoice } from 'src/models/OptionChoice'
import Img from 'src/components/Img'
import flyToCart from 'src/utils/flyToCart/flyToCart'
import { StoreOrder } from 'src/models/Cart'
interface Props {
  onClose?: () => void
  updateItem?: (item: MenuItem) => void
  configIndex?: number
  handleSelect?: (cb: Function) => void | null
}

const RadioListRef = forwardRef<any, { data: ItemOption, index: number, clicked: boolean }>((props, ref) => {
  return <RadioList {...props} innerRef={ref} />
})

const PlusItemRef = forwardRef<any, { data: ItemOption, optionIndex: number, alert: boolean }>((props, ref) => {
  return <PlusItem {...props} innerRef={ref} />
})

const ItemDetail: React.FC<Props> = ({ onClose, updateItem, configIndex, handleSelect}) => {
  const { data, total, isCreate, setNote, onDecrease, onIncrease } = useItemDetail()
  const { addToCart } = useCart()
  const [clicked, setClicked] = useState<boolean>(false)
  const [invalidOptionIndexs, setInvalidOptionIndexs] = useState<number[]>([])
  const optRefs = useRef<any[]>([])
  const contentRef = useRef<any>()

  const scroll = (DOM?: HTMLElement) => {
    if (!DOM || !contentRef.current) return
    contentRef.current.scrollTop = DOM.offsetTop - 64
  }

  const invalid = () => {
    let invalids: number[] = []
    for (let i = 0; i < data.options.length; i++) {
      const option = data.options[i]

      const totalSelected = option.choices.reduce((total, currChoice) => {
        return total + (currChoice.quantity || 0)
      }, 0)

      if (option.maxCount === 1 && option.minCount === 1) {
        const choice = option.choices.find((item: OptionChoice) => item.quantity)
        if (!choice) invalids.push(i)
      } else if (option.maxCount && totalSelected > option.maxCount) {
        invalids.push(i)
      } else if (option.minCount && totalSelected < option.minCount) {
        invalids.push(i)
      }
    }

    setInvalidOptionIndexs(invalids)
    return invalids.length ? invalids : null
  }

  return (
    <Wrap>
      <Head>
        {!isCreate && (
          <BackIcon
            onClick={() => {
              if (onClose) onClose()
            }}
          />
        )}
      </Head>
      <Content ref={contentRef}>
        <Name>{data.name}</Name>
        <Unit>{data.unit}</Unit>
        <Des>{data.description}</Des>
        <WrapPrice>
          <div>
            {/* <OldPrice>{formatCurrency(120000)}</OldPrice> */}
            <Price>{formatCurrency(data.price)}</Price>
          </div>
          <ItemPicker
            value={data.quantity}
            onDecrease={() => onDecrease()}
            onIncrease={() => onIncrease()}
          />
        </WrapPrice>
        <WrapImage>
          <Img
            style={{
              borderRadius: 16,
              width: '100%',
              objectFit: 'cover'
            }}
            src={data.image}
          />
        </WrapImage>
        {data.options.map((item: ItemOption, index: number) =>
          item.maxCount === 1 && item.minCount === 1 ? (
            <RadioListRef key={index} data={item} index={index} clicked={clicked} ref={(el) => {optRefs.current[index] = el}} />
            // <RadioList key={index} data={item} index={index} clicked={clicked} ref={(el) => optRefs.current[index] = el} />
          ) : (
            <PlusItemRef
              key={index}
              data={item}
              optionIndex={index}
              alert={clicked && invalidOptionIndexs.includes(index)}
              ref={(el) => {optRefs.current[index] = el}}
            />
            // <PlusItem
            //   key={index}
            //   data={item}
            //   optionIndex={index}
            //   alert={clicked && invalidOptionIndexs.includes(index)}
            //   ref={(el) => optRefs.current[index] = el}
            // />
          )
        )}
        {/* <PlusItem/> */}
        <Note>Yêu cầu đặc biệt</Note>
        <TextareaAutosizeStyled
          aria-label="note"
          placeholder="Ghi chú"
          minRows={2}
          value={data.userNote}
          onChange={(e) => setNote(e.target.value)}
        />
      </Content>
      <ButtonPanel>
        <HightOutlinedButton
          onClick={() => {
            const invalidOption = invalid()
            if (invalidOption) {
              scroll(optRefs.current[invalidOption[0]])
              setClicked(true)
              return
            }

            const updateSelect = (store?: StoreOrder) => {
              if (isCreate) {
                addToCart({...data, selected: true}, store, (_added) => {
                  flyToCart((typeof configIndex !== 'undefined' ? `${configIndex}-` : '') + data._id)
                })
              } else if (updateItem) updateItem(data)
              if (onClose) onClose()
            }

            if (handleSelect) {
              handleSelect((store?: StoreOrder) => {
                updateSelect(store)
              })
              return
            }

            updateSelect()
          }}
          disabled={!data.quantity}
          style={{ height: 56, width: '100%' }}
        >
          {isCreate ? 'Thêm vào giỏ' : 'Cập nhật món'} • {formatCurrency(total)}
        </HightOutlinedButton>
      </ButtonPanel>
    </Wrap>
  )
}

const Wrap = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const Content = styled('div')({
  padding: 12,
  height: 'calc(var(--vh, 1vh) * 100 - 141px)',
  overflowY: 'scroll',
  scrollBehavior: 'smooth'
})

const Head = styled('div')({
  height: 64,
  flexShrink: 0,
  display: 'flex',
  borderBottom: '1px solid #eeeeee',
  alignItems: 'center'
})

const Name = styled('div')({
  fontSize: 24,
  fontWeight: 700
})

const Unit = styled('div')({
  fontSize: 14,
  color: '#100B00',
  opacity: 0.5,
  marginTop: 8
})

const Des = styled('div')({
  fontSize: 16,
  marginTop: 8,
  WebkitLineClamp: 2,
  maxHeight: 40,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const WrapPrice = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 16
})

const OldPrice = styled('div')({
  fontSize: 16,
  color: '#100B00',
  opacity: 0.5
})

const Price = styled('div')({
  fontSize: 24
})

const WrapImage = styled('div')({
  height: 237,
  display: 'flex',
  justifyContent: 'center',
  marginTop: 16
})

const ButtonPanel = styled('div')({
  background: 'white',
  borderTop: '1px solid #eeeeee',
  flexShrink: 0,
  padding: 10
})

const Note = styled('div')({
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 10,
  marginTop: 24
})

const TextareaAutosizeStyled = styled(TextareaAutosize)({
  width: '-webkit-fill-available',
  border: '1px solid #eee',
  padding: 5,
  resize: 'vertical',
  '&:focus-visible': {
    outline: 'none'
  },
  borderRadius: 8
})

const BackIcon = styled('div')({
  width: 48,
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `url("${LeftImg}")`,
  flexShrink: 0,
  cursor: 'pointer'
})

export default ItemDetail
