import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions/transition'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import styled from 'styled-components'
const Close = styled.div`
    position: absolute;
    z-index: 9999;
    top: 16px;
    left: 16px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
        color: #fff;
    }
`
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

interface HalfScreenInterface {
    children: JSX.Element | JSX.Element[]
    open: boolean
    onClose?: () => void
    height?: number
    dialogClassName?: string
    defaultClose?: boolean
}

const HalfScreen: React.FC<HalfScreenInterface> = ({ children, open, onClose, height = 80, dialogClassName, defaultClose }) => {
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        setOpenDialog(open)
        return () => setOpenDialog(false)
    }, [open])

    const handleClose = () => {
        if (onClose) {
            setOpenDialog(false)
            onClose()
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    margin: 0,
                    borderRadius: 0,
                    marginTop: `auto`,
                    width: '100%',
                    height: height + '%',
                    maxWidth: '100%',
                    position: 'relative',
                    background: '#fafafa',
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                },
                className: `scrolling ${dialogClassName}`,
            }}>
            {defaultClose && <Close onClick={onClose}>
                <CloseRoundedIcon />
            </Close>}
            {children}
        </Dialog>
    )
}
export default HalfScreen
