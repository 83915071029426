import React, { useState } from 'react'
import styled from '@emotion/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { green } from 'src/utils/color'
import Popup from 'src/components/Modal/Popup'
import { OutlinedButton } from 'src/components/Button/OutlinedButton'
import { grey, red } from 'src/utils/color'
import { Button } from '@mui/material'
import { useCart } from 'src/components/Cart/CartContext'
import { formatCurrency } from 'src/utils/myUtils'
import {format} from 'date-fns'
import {useModalState} from 'src/utils/hooks/useModalState'
interface CouponType {
  code: string
  disable?: boolean
}

const Coupon: React.FC<CouponType> = ({ code, disable }) => {
  const {isOpen, onOpen, onClose} = useModalState(false)
  const { cart, cancelCoupon } = useCart()

  return (
    <>
      <Code style={{height: disable ? 32 : 28}} onClick={() => { if (!disable) onOpen() }}>
        <Text>{code}</Text>
        <InfoOutlinedIcon />
      </Code>
      <Popup
        children={
          <>
            {cart?.couponData && (
              <>
                <WrapCode>
                  <div style={{ marginBottom: 10 }}>Mã khuyến mãi</div>
                  <Coupon code={cart.coupon} disable={true} />
                </WrapCode>
                <div>
                  <Title>Thời gian áp dụng</Title>
                  <div style={{marginTop: 8}}>
                    {format(new Date(cart?.couponData.startTime), 'HH:mm dd/MM/yyyy')} đến {format(new Date(cart?.couponData.endTime), 'HH:mm dd/MM/yyyy')}
                  </div>
                </div>
                <div>
                  <Title>Điều kiện áp dụng</Title>
                  <ul style={{marginTop: 5, marginBottom: 0}}>
                    <li>Đơn hàng tối thiểu {formatCurrency(cart?.couponData.minPrice)}</li>
                    <li>
                      Áp dụng tại:
                      <ul>
                        {cart.couponData.stores?.map((item) => <li>{item.brand?.name} - {item.name}</li>)}
                        {cart.couponData.hubs?.map((item) => <li>{item.name}</li>)}
                      </ul>
                    </li>
                    <li>Số lượng có hạn</li>
                  </ul>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CancelButton style={{ marginRight: 10 }} onClick={() => onClose()}>
                    Quay lại
                  </CancelButton>
                  <OkButton onClick={() => {
                    cancelCoupon()
                    onClose()
                  }}>Bỏ áp dụng</OkButton>
                </div>
              </>
            )}
          </>
        }
        open={isOpen}
        title={'Chi tiết khuyến mãi'}
        onClose={() => onClose()}
        defaultClose={true}
        height={'unset'}
      />
    </>
  )
}
const Text = styled('div')`
  margin-right: 8px;
`
const Code = styled('div')`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${green[500]};
  background: rgba(57, 181, 74, 0.2);
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  & svg {
    font-size: 16px;
  }
`
const OkButton = styled(OutlinedButton)({
  height: 40,
  width: 126,
  cursor: 'pointer'
})

const CancelButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 8,
  color: red[500],
  width: 126
})

const WrapCode = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled('div')`
  font-weight: bold;
`
export default Coupon
