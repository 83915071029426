import styled from 'styled-components'
import Cover from 'src/assets/images/cover.png'
import { useState } from 'react'
import { useScrollPosition } from 'src/utils/hooks/useScrollPosition'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { useHistory } from 'react-router'
const Wrap = styled.div`
    position: fixed;
    z-index: 999;
    background: #fff;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    padding: 8px 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    > div > svg {
        opacity: 0.5;
    }
`
const Brand = styled.div`
    flex: 1;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.5;
        color: #000
    }
`
const BrandHeader = () => {
    const history = useHistory()
    const backHandler = () => {
        history.goBack()
    }

    return (
        <Wrap className='limit-width'>
            <div onClick={backHandler}>
                <ArrowBackRoundedIcon />
            </div>
            <Brand className='bot'>
                <p className='caption'>Xác nhận</p>
                <p className='heading-3'>Thông tin đặt hàng</p>
            </Brand>
        </Wrap>
    )
}
export default BrandHeader
