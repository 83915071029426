import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import { Wrap, Line } from "./components";
import { formatDate } from "src/utils/myUtils";

const Code: React.FC<{data: any}> = ({data}) => {
  return (
    <Wrap>
      <Line style={{fontWeight: 700, color: 'black'}}> 
        Mã đơn hàng
        <div>
          {data.code}
        </div>
      </Line>
      <Line>
        <div>Thời gian đặt hàng</div>
        <div>{formatDate(data.created)}</div>
      </Line>
      {data.acceptTime && <Line>
        <div>Thời gian thanh toán</div>
        <div>{formatDate(data.acceptTime)}</div>
      </Line>}
      {data.startDeliveryTime && <Line>
        <div>Thời gian giao hàng cho vận chuyển</div>
        <div>{formatDate(data.startDeliveryTime)}</div>
      </Line>}
      {data.deliveredTime && <Line>
        <div>Thời gian hoàn thành</div>
        <div>{formatDate(data.deliveredTime)}</div>
      </Line>
      }
    </Wrap>
  );
};
export default Code;
