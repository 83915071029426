import styled from 'styled-components'
import Cover from 'src/assets/images/cover.png'
import React, { useEffect, useState } from 'react'
import { useScrollPosition } from 'src/utils/hooks/useScrollPosition'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { useAppSelector } from 'src/store/hooks'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Popup from 'src/components/Modal/Popup'
import { calculateDistance } from 'src/utils/myUtils'
import { _getSchedule } from 'src/utils/api'
import { formatSchedule, Schedule } from 'src/models/Schedule'
import Map from 'src/components/Map'
import { WEEKDAY } from 'src/utils/constants'
import { useHistory } from 'react-router'
const Container = styled.div`
    position: fixed;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    display: flex;
    padding: 0px 10% 0px;
    width: 100%;
    > div {
        padding-top: 126px;
    }
    > .w-100 {
        width: 100%;
        background: #fff;
    }
    > .w-50 {
        width: 48%;
    }
`
const Wrap = styled.div<{ height: number; image?: string }>`
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 999;
    width: 100%;
    height: ${(props) => props.height + 'px'};
    background-size: cover;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    overflow: hidden;
    .cover-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    > div.top {
        > div {
            position: absolute;
            top: 16px;
            left: 16px;
            > svg {
                border-radius: 50%;
                padding: 2px;
                background: rgba(
                    ${(props) => (props.height > 130 ? 0 : 255 * (1 - (props.height - 87) / 74))},
                    ${(props) => (props.height > 130 ? 0 : 255 * (1 - (props.height - 87) / 74))},
                    ${(props) => (props.height > 130 ? 0 : 255 * (1 - (props.height - 87) / 74))},
                    ${(props) => (props.height > 130 ? 1 : 1 - 1 * (1 - (props.height - 87) / 74))}
                );
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    > div {
        max-height: 87px;
    }
    > div.bot {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
        padding-left: ${(props) => (props.height > 130 ? 16 : 16 + 32 * (1 - (props.height - 87) / 74) + 'px')};
    }
    p {
        color: #fff;
    }
    > .info {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
            color: #fff;
            font-size: 20px;
        }
    }
`
const Brand = styled.div`
    flex: 1;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const ModalWrapper = styled.div`
    margin-top: -16px;
    > .map {
        height: 207px;
    }
    > div:not(.map) {
        padding: 16px;
    }
    .schedule {
        display: flex;
        > .label {
            width: 80px;
            > p {
                line-height: 24px;
            }
        }
        .time {
            background: rgba(107, 7, 114, 0.2);
            border: 1px solid #6b0772;
            border-radius: 8px;
            padding: 2px 4px;
            margin: 0px 16px 16px 0px;
        }
        .no-time {
            border: 1px solid #6b0772;
            border-radius: 8px;
            padding: 2px 4px;
            margin: 0px 16px 16px 0px;
        }
    }
`
const Header: React.FC<{ open?: boolean; setOpen?: (open: boolean) => void }> = ({ open, setOpen }) => {
    const history = useHistory()
    const [height, setHeight] = useState<number>(258)
    const [thisOpen, setThisOpen] = useState<boolean>(false)
    useScrollPosition(
        ({ prevPos, currPos }) => {
            let y = Math.floor(Math.abs(currPos.y))
            if (y <= 171) {
                setHeight(258 - y)
            } else {
                setHeight(87)
            }
        },
        [height]
    )

    const backHandler = () => {
        if (window.location.hostname != process.env.REACT_APP_PLSORDER_ORIGIN_HOSTNAME && !window.location.pathname.includes('order-information')) {
            history.push('/')
            return
        }
        history.goBack()
    }

    const brandData = useAppSelector((state) => state.brand)
    const storeData = useAppSelector((state) => state.store)
    const orderData = useAppSelector((state) => state.order)
    const [scheduleData, setScheduleData] = useState<Schedule | null>(null)
    const [dis, setDis] = useState<string>('')
    const calculateDis = (pos: any) => {
        const myLocation = { lat: pos.coords.latitude, lng: pos.coords.longitude }
        calculateDistance(myLocation, { lat: storeData.selectedStore?.lat, lng: storeData.selectedStore?.lng }, (d) =>
            setDis(d)
        )
    }
    const getSchedule = async () => {
        const s = await _getSchedule(storeData.selectedStore?.id || '')
        setScheduleData(formatSchedule(s.data.data))
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(calculateDis)
        getSchedule()
    }, [])

    return (
        <Container className='container'>
            <div className='w-100'>
                <Wrap height={height}>
                    <div className='top'>
                        <div onClick={backHandler}>
                            <ArrowBackRoundedIcon />
                        </div>
                    </div>
                    <img className='cover-image' src={brandData?.cover || Cover} />
                    <Brand className='bot'>
                        <p>Order from</p>
                        <p className='heading-2'>{brandData?.name}</p>
                    </Brand>
                    <div
                        className='info'
                        onClick={() => {
                            typeof setOpen == 'undefined' ? setThisOpen(true) : setOpen(true)
                        }}>
                        <InfoOutlinedIcon />
                    </div>
                </Wrap>
            </div>

            <div style={{ minWidth: 30 }} />
            <div className='w-50'></div>
            <Popup
                open={typeof open == 'undefined' ? thisOpen : open}
                onClose={() => {
                    typeof setOpen == 'undefined' ? setThisOpen(false) : setOpen(false)
                }}
                defaultClose>
                <ModalWrapper>
                    <div className='map'>
                        <Map
                            origin={{
                                lat: storeData.selectedStore?.lat || 0,
                                lng: storeData.selectedStore?.lng || 0,
                            }}
                        />
                    </div>
                    <div>
                        <p className='heading-3'>`${brandData?.name} - {storeData.selectedStore.name}`</p>
                        <p style={{ color: '#999' }}>{storeData.selectedStore?.address}</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {storeData.selectedStore?.isOpen ? (
                                <p className='open' style={{ fontWeight: 'bold', color: '#3DBD00' }}>
                                    Đang mở cửa
                                </p>
                            ) : (
                                <p className='close' style={{ fontWeight: 'bold', color: '#E02020' }}>
                                    Tạm nghỉ
                                </p>
                            )}
                            <p>{dis}</p>
                        </div>
                        <p className='heading-3' style={{ margin: '16px 0px 8px' }}>
                            Giới thiệu
                        </p>
                        <p>{brandData?.intro}</p>
                        <p className='heading-3' style={{ margin: '16px 0px 8px' }}>
                            Giờ hoạt động
                        </p>
                        {scheduleData
                            ? [...Array(7).keys()].map((i) => {
                                  return (
                                      <div className='schedule' key={i}>
                                          <div className='label'>
                                              <p>{WEEKDAY[i]}</p>
                                          </div>
                                          <div style={{ display: 'flex' }}>
                                              {scheduleData[i].length > 0 ? (
                                                  scheduleData[i].map((time: any, index: number) => (
                                                      <p key={index} className='time'>
                                                          {time}
                                                      </p>
                                                  ))
                                              ) : (
                                                  <p className='no-time'>Nghỉ bán</p>
                                              )}
                                          </div>
                                      </div>
                                  )
                              })
                            : null}
                    </div>
                </ModalWrapper>
            </Popup>
        </Container>
    )
}
export default Header
