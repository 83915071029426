import { createGlobalStyle } from 'styled-components'
const GlobalStyles = createGlobalStyle`
@media screen and (min-width: 800px) {
    .limit-width {
        max-width: 370px !important;
    }
    .text-h0 {
        font-size: 36px;
        line-height: 48px;
    }
    .text-h1 {
        font-size: 32px;
        line-height: 40px;
    }
    .text-h2 {
        font-size: 24px;
        line-height: 32px;
    }
    .text-h3 {
        font-size: 16px;
        line-height: 24px;
    }
    .text-h4 {
        font-size: 14px;
        line-height: 20px;
    }
    .text-body {
        font-size: 14px;
        line-height: 20px;
    }
}
*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
html {
    scroll-behavior: smooth;
}
body {
    margin: 0 auto;
    font-family: 'Lexend';
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto !important;
}
.container {
    max-width: 1440px;
    margin: auto;
}
.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}
.f-3 {
    flex: 3;
}
.f-4 {
    flex: 4;
}
.w-30 {
    width: 30px;
}
#root {
    box-shadow: 0px 0px 20px 0px #cbcbcb;
    background: #fff;
}
.MuiInput-root:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.MuiInput-root:after {
    border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
}

.MuiDialogContent-root {
    padding: 0px!important;
}
.MuiDialogContent-root>div {
    border-top: 16px solid #fff;
}

.MuiInput-root *{
    font-family: 'Lexend'!important;
}
fieldset {
    border-color: #dadada;
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-day.selected {
    background: ${(props) => props.theme.tintColor};
    color: #fff;
    border-color: ${(props) => props.theme.tintColor};
}
.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before,.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
    display: none;
}
.flatpickr-calendar.open {
    right: unset!important;
    margin-left: 16px;
}
p {
    margin: 0;
    color: #000;
    font-size: 14px;
    line-height: 20px;
}
p.hero-heading {
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
}
p.heading-1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}
p.heading-2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}
p.heading-3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}
p.heading-4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}
p.body-text {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}
p.body-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}
p.caption {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* slideTransition.scss */

.pageSlider-exit > .page {
    z-index: 10;
}

.pageSlider-enter > .page {
    z-index: 100;
}

.pageSlider-enter.left > .page {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right > .page {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left > .page {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.right > .page {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    transition: all 250ms ease-in-out;
}

.swiper-pagination-bullet{
    background: white;
    opacity: 1;
}
.swiper-pagination-bullet-active{
    background: #39B54A;
}
.active:after{
    width: 100% !important;
    background: #39B54A;
}
.active:hover:after{
    background: #39B54A !important;
}

.scroll-menu-active:after{
    content: "";
    display: block;
    margin: auto;
    height: 4px;
    width: 100%;
    background: #39B54A;
    color: #39B54A;
    border-radius: 2px 2px 0px 0px;
}
.pac-container{
    z-index: 10000
}
.text-h0 {
    font-size: 48px;
    line-height: 64px
}
.text-h1 {
    font-size: 36px;
    line-height: 48px
}
.text-h2 {
    font-size: 32px;
    line-height: 40px
}
.text-h3 {
    font-size: 24px;
    line-height: 32px
}
.text-h4 {
    font-size: 16px;
    line-height: 24px
}
.text-h5 {
    font-size: 14px;
    line-height: 20px
}
.text-body {
    font-size: 16px;
    line-height: 24px
}
.text-sub {
    font-size: 14px;
    line-height: 20px
}
.text-caption {
    font-size: 12px;
    line-height: 16px
}

`

export default GlobalStyles
