export interface Schedule {
    [key: number]: Array<string>
    0: Array<string>
    1: Array<string>
    2: Array<string>
    3: Array<string>
    4: Array<string>
    5: Array<string>
    6: Array<string>
}
export function formatSchedule(data: any) {
    let res: Schedule = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    }
    data.forEach((d: any) =>
        d.dows?.forEach((e: number) => {
            res[e].push(d.start.string + ' - ' + d.end.string)
        })
    )
    return res
}
