import { styled } from '@mui/system'
import PointImg from 'src/assets/icons/black-point.svg'
import { grey, red } from 'src/utils/color'

export const Title = styled('div')`
  font-weight: 700;
  margin-bottom: 8px;
`
export const Wrap = styled('div')`
  padding: 16px 12px;
  background: white;
  margin-top: 16px;
  @media (max-width: 599px) {
    font-size: 14px;
  }
`
export const Point = styled('div')`
  background-image: url('${PointImg}');
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
`

export const Line = styled('div')`
  color: ${grey[300]};
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`

export const Price = styled('div')`
  font-weight: 700;
`

export const TextBtn = styled('span')`
  color: ${red[500]};
  cursor: pointer;
  text-decoration: underline;
`
