import React from 'react'
import styled from 'styled-components'
import MinusIcon from 'src/assets/icons/minus-circle.svg'
import PlusIcon from 'src/assets/icons/add-circle.svg'
const Wrap = styled.div`
    display: flex;
    align-items: center;
    > p {
        margin: 0px 8px
    }
`
const Icon = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 48px;
    height: 48px;
    @media (max-width: 799px) {
        width: 40px;
        height: 40px;
    }
    > img {
        width: 100%;
    }
`
interface Props {
    max?: number
    value?: number
    onIncrease?: () => void
    onDecrease?: () => void
    buttonSize?: number
    textSize?: number
}

const ItemPicker: React.FC<Props> = ({
    max = 999,
    value,
    onIncrease,
    onDecrease,
    buttonSize,
    textSize = 24
}) => {
    const onMinusIconClick = () => {
        if (onDecrease) {
            if (typeof value != 'undefined' && value > 0) {
                onDecrease()
                return
            }
        }
    }
    const onPlusIconClick = () => {
        if (onIncrease) {
            if (typeof value === 'undefined' || value === 0) {
                onIncrease()
                return
            }
            if (typeof max === 'undefined' || value < max) {
                onIncrease()
                return
            }
        }
    }

    return value ? (
        <Wrap>
            <Icon
                style={buttonSize ? {
                    width: buttonSize + 'px',
                    height: buttonSize + 'px',
                } : {}}
                onClick={onMinusIconClick}>
                <img src={MinusIcon} alt='minus-icon' />
            </Icon>
            <p className='heading-4' style={{fontSize: textSize+'px', width: 60, textAlign: 'center'}}>{value}</p>
            <Icon
                style={buttonSize ? {
                    width: buttonSize + 'px',
                    height: buttonSize + 'px',
                } : {}}
                onClick={onPlusIconClick}>
                <img src={PlusIcon} alt='plus-icon' />
            </Icon>
        </Wrap>
    ) : (
        <Wrap>
            <Icon
                style={buttonSize ? {
                    width: buttonSize + 'px',
                    height: buttonSize + 'px',
                } : {}}
                onClick={onPlusIconClick}>
                <img src={PlusIcon} alt='plus-icon' />
            </Icon>
        </Wrap>
    )
}
export default ItemPicker
