import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import React, { RefObject, useRef, useState } from 'react'
import styled from 'styled-components'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
interface InputProps {
    required?: boolean
    placeholder?: string
    value: string
    onChange?: (value: string) => void
    onBlur?: () => void
    onFocus?: () => void
    name?: string
    className?: string
    disabled?: boolean
    expand?: boolean
}
const Wrap = styled.div`
    margin: 16px 0px;
    width: 32px;
    transition: all 0.25s;
    &.expanded {
        width: 100%;
        min-width: 100%;
    }
    .search-icon {
        display: flex;
        justify-content: center;
    }
    &:not(.expanded) {
        div,
        div:after,
        div:before {
            border-bottom: none !important;
        }
    }
`
const SearchInput: React.FC<InputProps> = (props) => {
    const [isExpand, setIsExpand] = useState<boolean>(false)
    const ref = useRef<HTMLInputElement>(null)
    const toggleHandler = () => {
        if (!props.expand) {
            if (ref.current != null && !isExpand) {
                ref.current.focus()
            }
            setIsExpand(!isExpand)
        }
    }
    return (
        <Wrap className={isExpand || props.expand ? 'expanded' : ''}>
            <TextField
                inputRef={ref}
                onBlur={() => {
                    setIsExpand(false)
                    props.onBlur && props.onBlur()
                }}
                onFocus={props.onFocus}
                className={props.className}
                disabled={props.disabled}
                name='dish-search'
                placeholder={props.placeholder}
                fullWidth={true}
                value={props.value}
                onChange={(e) =>
                    props.onChange ? (e.target.value != ' ' ? props.onChange(e.target.value) : props.onChange('')) : null
                }
                variant='standard'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <div className='search-icon' onClick={toggleHandler}>
                                <SearchRoundedIcon />
                            </div>
                        </InputAdornment>
                    ),
                }}
            />
        </Wrap>
    )
}
export default SearchInput
