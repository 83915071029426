import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'
import { Pagination, Autoplay } from 'swiper'
import { Banner } from 'src/models/Banner'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { Box } from '@mui/system'
import { useHistory } from 'react-router-dom'
import { isInternal } from 'src/utils/myUtils'
import { useBrand } from '../provider'

interface SwiperProps {
  banners: Banner[]
  index: number
}
const SwiperStyled: React.FC<SwiperProps> = ({ banners, index }) => {
  const windowSize = useWindowSize()
  const history = useHistory()
  const { pre } = useBrand()
  const handleBannerClick = (link: string) => {
    isInternal(link) ? history.push(pre + link) : window.open(link)
  }
  return (
    <Box sx={{ mt: { md: index === 0 ? 0 : 8, xs: index === 0 ? 0 : 5 } }}>
      <Swiper
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
      >
        {!!banners.length &&
          banners.map((banner: Banner, index: number) => (
            <SwiperSlide key={index} onClick={() => handleBannerClick(banner.link)}>
              <img
                style={{ width: '100%', height: 'auto' }}
                height={
                  windowSize.width && windowSize.width >= 420
                    ? `${window.innerWidth / 2.499}px`
                    : `${window.innerWidth}px`
                }
                src={
                  windowSize.width && windowSize.width < 420
                    ? banner?.mobile_image_url
                    : banner?.desktop_image_url
                }
                alt={banner.name}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  )
}

export default SwiperStyled
