import { styled } from '@mui/system'
import React, { useState, useEffect, ForwardedRef } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { formatCurrency } from 'src/utils/myUtils'
import { ItemOption } from 'src/models/ItemOption'
import { OptionChoice } from 'src/models/OptionChoice'
import { useItemDetail } from 'src/components/ItemDetail/ItemDetailContext'
import Alert from '@mui/material/Alert'
const RadioList: React.FC<{ data: ItemOption; index: number; clicked: boolean; innerRef: ForwardedRef<any> }> = ({
  data,
  index,
  clicked,
  innerRef,
}) => {
  const [value, setValue] = useState<string>()
  const { onSelectOption } = useItemDetail()
  useEffect(() => {
    const item = data.choices.find((item) => item.quantity)
    setValue(item?._id)
  }, [data])
  return (
    <>
      <FormControl ref={innerRef} style={{ width: '100%', marginTop: 24 }}>
        <FormLabel style={{ fontSize: 14, color: 'black', fontWeight: 700 }}>
          Chọn {data.name} (bắt buộc chọn 1)
        </FormLabel>
        {clicked && !value && (
          <Alert style={{ marginTop: 5 }} severity="error">
            Bạn cần chọn ít nhất một món
          </Alert>
        )}
        <RadioGroup value={value} name="radio-buttons-group">
          {data.choices.map((item: OptionChoice, choiceIndex: number) => (
            <FormControlLabel
              key={choiceIndex}
              value={item._id}
              onChange={() => {
                setValue(item._id)
                onSelectOption(index, choiceIndex)
              }}
              control={
                <Radio
                  checked={value === item._id}
                  sx={{
                    color: '#F05123',
                    '&.Mui-checked': {
                      color: '#F05123'
                    }
                  }}
                />
              }
              label={
                <Line>
                  <Price>{item.name}</Price>
                  <Price>+{formatCurrency(item.price)}</Price>
                </Line>
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  width: '100%'
                },
                pr: 1.25
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  )
}

const Line = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #eeeeee'
})

const Price = styled('div')({
  fontSize: 14
})

export default RadioList
