import React from 'react'
import { OrderItem } from 'src/models/OrderItem'
import { removeItem } from 'src/store/features/order/slice'
import { useAppDispatch } from 'src/store/hooks'
import { formatCurrency } from 'src/utils/myUtils'
import styled from 'styled-components'

const ItemOrder: React.FC<{ item: OrderItem; isLast: boolean }> = (props) => {
    const ItemWrap = styled.div`
        display: flex;
        margin: 16px 0px;
        > div {
            padding-bottom: 9px;
        }
        p.delete {
            color: #e73948;
            cursor: pointer;
        }
        .item-name {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 70%;
        }
        .caption {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 4px;
        }
    `
    let choiceOrder = ''
    if (props.item.options && props.item.options.length > 0) {
        props.item.options.forEach((op, oi) =>
            op.choices.forEach((c, ci) => {
                if (!(oi === 0 && ci === 0)) {
                    choiceOrder += ' + '
                }
                if (op.maxCount === 1) {
                    choiceOrder += c.name
                } else {
                    choiceOrder += c.quantity + 'x' + c.name
                }
            })
        )
    }
    const dispatch = useAppDispatch()
    return (
        <>
            <ItemWrap>
                <div
                    style={{ paddingRight: 16, borderBottom: `${!props.isLast ? '1px solid rgb(89 89 89 / 20%)' : ''}` }}
                    onClick={() => dispatch(removeItem({ itemId: props.item.itemId }))}>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>{props.item.quantity}</span>{' '}
                        <span style={{ opacity: 0.5 }}>x</span>
                    </p>
                </div>
                <div
                    style={{
                        flex: 1,
                        borderBottom: `${!props.isLast ? '1px solid rgb(89 89 89 / 20%)' : ''}`,
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className='item-name'>{props.item.name}</p>
                        <p>{formatCurrency(props.item.price * props.item.quantity || 0)}</p>
                    </div>
                    <p className='caption' style={{ opacity: 0.5 }}>
                        {choiceOrder}
                    </p>
                </div>
            </ItemWrap>
        </>
    )
}

export default ItemOrder
