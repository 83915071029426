import React, { useCallback, useEffect, useRef, useState } from 'react'
import Input from 'src/components/Input'
import styled from 'styled-components'
import { _getLocationDetailFromId, _getLocationFromAddress } from 'src/utils/api'
import { GoogleMapPlace, GoogleMapPlaceDetail } from 'src/models/GoogleMapPlace'
import _ from 'lodash'
import useClickOutSide from 'src/utils/hooks/useClickOutSide'
import CircularProgress from '@mui/material/CircularProgress'
interface PropsInterface {
    label?: string
    placeholder?: string
    value?: string
    onChange: (data: GoogleMapPlaceDetail) => void
    errorMsg?: string
}

const Wrap = styled.div`
    position: relative;
    flex-grow: 1;
    > p.errMsg {
        margin-top: 4px;
        color: #ef5467;
    }
`
const Suggestion = styled.div`
    position: fixed;
    background: #fff;
    z-index: 99;
    padding: 9px;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    max-width: 480px;
`
const Place = styled.div`
    padding-bottom: 8px;
    margin-top: 8px;
    cursor: pointer;
    &:not(.last) {
        border-bottom: 1px solid #cdcdcd;
    }
`

export default function LocationInput(props: PropsInterface) {
    const [keyword, setKeyword] = useState<string>(props.value || '')
    const [session, setSession] = useState<string>(Date.now().toString())
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(false)
    const [list, setList] = useState<Array<GoogleMapPlace>>([])
    const wrapperRef = useRef(null)
    const [loading, setLoading] = useState<boolean>(false);
    const getPlaceDebounce = async () => {
        if (keyword != '') {
            setLoading(true);
            const response = await _getLocationFromAddress(keyword, session)
            setLoading(false);
            setList(response.data)
        }
    }

    const debouncedChangeHandler = useCallback(_.debounce(getPlaceDebounce, 500), [keyword])

    const changeHandler = async (value: string) => {
        setKeyword(value)
        debouncedChangeHandler()
    }

    const onSelect = async (id: string) => {
        const response = await _getLocationDetailFromId(id)

        // todo: use full geo detail data
        const place = list.find((p) => p.place_id === id)
        if (response.data.formatted_address !== place?.description) {
            response.data.formatted_address = place?.description || response.data.formatted_address
        }

        props.onChange(response.data)
        setShouldDisplay(false)
    }

    useClickOutSide(wrapperRef, () => {
        setShouldDisplay(false)
    })

    useEffect(() => {
        debouncedChangeHandler()
        return debouncedChangeHandler.cancel
    }, [keyword, debouncedChangeHandler])

    useEffect(() => {
        setKeyword(props.value || '')
    }, [props.value])
    return (
        <Wrap ref={wrapperRef}>
            <Input
                value={keyword}
                onChange={(value) => changeHandler(value)}
                onFocus={() => setShouldDisplay(true)}
                label={props.label}
                placeholder={props.placeholder}
                name='address'
            />
            {loading && <div style={{position: 'absolute', right: '3px', top: 22}}><CircularProgress size={15} color="inherit"/></div>}
            {typeof props.errorMsg != 'undefined' ? <p className='errMsg caption'>{props.errorMsg}</p> : null}
            {list && list.length > 0 && shouldDisplay ? (
                <div style={{position: 'absolute', top: 55}}>
                    <Suggestion>
                        
                        {list.map((place, index) => (
                            <Place
                                key={index}
                                className={index == list.length - 1 ? 'last' : ''}
                                onClick={() => onSelect(place.place_id)}>
                                {place.description}
                            </Place>
                        ))}
                        
                    </Suggestion>
                </div>
            ) : null}
        </Wrap>
    )
}
