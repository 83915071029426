import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import Outlet from './Outlet'
import { LocationInterface } from "src/models/Location";
import { HightOutlinedButton } from "src/components/Button/OutlinedButton";
import { red } from "src/utils/color";
import { useCart } from 'src/components/Cart/CartContext';
import { _getDistance } from 'src/utils/api'

interface Props {
  onClose: Function
  onSelect: Function
  open: boolean
  hubs: LocationInterface[]
  name: string
}

const OutletModal: React.FC<Props> = ({ onClose, onSelect, open, hubs, name }) => {
  const { cart } = useCart()
  const [selectedHub, setSelectedHub] = useState<LocationInterface | undefined>()
  const [disabledOK, setDisabledOK] = useState<boolean>(true)
  const [distances, setDistances] = useState<string[]>([])

  const handleClose = () => {
    setSelectedHub(undefined)
    setDisabledOK(true)
    onClose && onClose(false)
  }

  const select = (hub: LocationInterface) => {
    // setDisabledOK(false)
    // setSelectedHub(hub)
    onSelect && onSelect(hub)
    onClose && onClose(false)
  }

  const handleOk = () => {
    setSelectedHub(undefined)
    setDisabledOK(true)
    onSelect && onSelect(selectedHub)
    onClose && onClose(false)
  }

  useEffect(() => {
    if (!cart.address || !open || distances.length) return

    const des = hubs.map(({latitude, longitude}) => `${latitude},${longitude}`).join('|')

    _getDistance(
      // cart.address!.placeId ? `place_id:${cart.address.placeId}` : `${cart.address!.latitude},${cart.address!.longitude}`,
      `${cart.address!.latitude},${cart.address!.longitude}`,
      des,
    ).then((res) => {
      setDistances(res.data.rows[0]?.elements.map((e: any) => e.distance.text))
    })
  }, [cart.address?.latitude, cart.address?.longitude, open])

  return (
    <Dialog onClose={handleClose} open={open}>
      <TitleWrap>
        <IconButtonPos onClick={handleClose}>
          <CloseIcon />
        </IconButtonPos>
        <Title>
          Chọn điểm bán đặt món
        </Title>
      </TitleWrap>
      <DialogContent sx={{ width: { xs: 'unset', md: 564 } }}>
        <ContentWrap style={{ borderTop: 'none' }}>
          <div>
            <b>{name}</b> hiện có tại {hubs.length} điểm bán, vui lòng chọn điểm bán bạn muốn đặt món:
          </div>
          {hubs.map((item: LocationInterface, index: number) => (
            <Outlet
              key={index}
              distance={distances[index]}
              selected={item === selectedHub}
              select={(hub: LocationInterface) => select(hub)}
              store={item}
            />
          ))}
        </ContentWrap>
      </DialogContent>
      {/* <div style={{display: 'flex', justifyContent: 'center', margin: '16px 12px'}}>
        <CancelButton style={{marginRight: 10}} onClick={handleClose}>Để sau</CancelButton>
        <OkButton onClick={handleOk} disabled={disabledOK}>Đặt món</OkButton>
      </div> */}
    </Dialog>
  )
}

const ContentWrap = styled('div')({
  margin: '12px 12px 16px 12px',
  borderTop: 'none',
})

const TitleWrap = styled(DialogTitle)({
  position: 'relative',
  padding: '16px 12px',
})

const Title = styled('div')({
  fontWeight: 700,
  fontSize: 16,
  textAlign: 'center',
  marginLeft: 40
})

const IconButtonPos = styled(IconButton)({
  position: 'absolute',
  left: 20,
  top: 10
})


const OkButton = styled(HightOutlinedButton)({
  height: 40,
  width: 126,
  cursor: 'pointer',
  ":disabled": {
    opacity: 0.26,
  }
});

const CancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: 8,
  color: red[500],
  width: 126
});

export default OutletModal
