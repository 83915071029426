import React, { useEffect, useMemo, useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { List } from '@mui/material'
import { CateItem } from 'src/models/CateItem'
import styled from 'styled-components'

const MenuItem: React.FC<{
  data: CateItem
  selectItem: Function
  index: number
  setActiveId: Function
  activeId: string
}> = ({ data, selectItem, index, setActiveId, activeId }) => {
  const [open, setOpen] = useState<boolean>(false)

  const isActive = useMemo(() => {
    const cateIds = data?.child?.map((item: CateItem) => item._id)
    return cateIds?.includes(activeId)
  }, [activeId, data?.child])

  const handleToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    // if (isActive) {
    //   setOpen(true)
    // }
    setOpen(!!isActive)
  }, [isActive])

  return (
    <>
      <ListItemButton
        sx={{ borderTop: '1px solid #e8e7e6', padding: '7px' }}
        onClick={handleToggle}
      >
        <ListItemText primary={`${data.name}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListStyled disablePadding>
          {data.child &&
            data.child.map((it, j) => (
              <ListItemButton
                key={j}
                selected={activeId === it._id}
                onClick={() => {
                  setActiveId(it._id)
                  selectItem(index, j)
                }}
              >
                <Item>{it.name}</Item>
              </ListItemButton>
            ))}
        </ListStyled>
      </Collapse>
    </>
  )
}
const Item = styled.div`
  font-size: 14px;
  padding: 7px;
`

const ListStyled = styled(List)`
  & > .Mui-selected {
    background-color: #eefff4 !important;
  }
`
export default MenuItem
