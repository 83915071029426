import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
import React, { useState } from 'react'
import Background from 'src/components/Background'
import { CircularProgress } from '@mui/material'
const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        color: ${(props) => props.theme.mainColor}!important;
    }
`
const Loading: React.FC<{height?: number}> = ({height = 100}) => {
    return (
        <Wrap style={{height}}>
            <CircularProgress />
        </Wrap>
    )
}
export default Loading
