import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import LocationItem from "./LocationItem";
import GMap from "src/components/GMap";
import NavLeftImg from "../../assets/icons/nav-left.svg";
import PointImg from "src/assets/icons/point.svg";
import GPSImg from "src/assets/icons/gps.svg";
import MapImg from "../../assets/icons/map.svg";
import ListImg from "../../assets/icons/list.svg";
import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import HomeHeader from "src/components/HomeHeader";
import useWindowSize from "src/utils/hooks/useWindowSize";
import { Hub } from "src/models/Hub";
import { _getDistance, _getHubs } from "src/utils/api";
import { Link } from "react-router-dom";
import { LocationInterface } from "src/models/Location";
import AddressModal from "./AddressModal";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { AddressPos } from "src/models/AddressPos";
import { useHistory } from "react-router-dom";
import Loading from 'src/components/Loading';
import { useBrand } from "../provider";
import { grey } from "src/utils/color";
import { useCart } from "src/components/Cart/CartContext";
import { distance as latLngDistance } from 'src/utils/myUtils'

const Location: React.FC = () => {
  const { cart, setAddress } = useCart()
  const { isSub, brandSlug } = useBrand()
  const history = useHistory();
  const [swiper, setSwiper] = useState<any>(null);
  const [showList, setShowList] = useState(true);
  const windowSize = useWindowSize();
  const [selectedHub, setSelectedHub] = useState<LocationInterface>();
  const [hubData, setHubData] = useState<LocationInterface[]>([]);
  const [distances, setDistances] = useState<number[]>([]);
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [pos, setPos] = useState<AddressPos>({
  //   text: "",
  //   pos: {
  //     lng: 0,
  //     lat: 0,
  //   },
  // });

  useEffect(() => {
    const getHubs = async () => {
      setLoading(true);
      const data: { data: LocationInterface[] } = await _getHubs(brandSlug);
      // navigator.geolocation.getCurrentPosition((pos) => {
      data.data.map((item: LocationInterface) => {
        // calculateDistance(
        //     { lat: pos.coords.latitude, lng: pos.coords.longitude },
        //     { lat: item.latitude, lng: item.longitude },
        //     (dis) => {
        //         console.log('dis', dis);
        //         item.distance = dis
        //     }
        // )
        if (item.stores) {
          item.numBrand = item.stores.length;
        } else {
          item.numBrand = 1;
        }

        item.dis = 0;
      });
      // })
      setHubData(data.data);
      setLoading(false);
    };
    getHubs();
  }, []);

  const select = (selected: LocationInterface, index: number) => {
    if (!hubData) return;
    hubData.forEach((hub: LocationInterface, i: number) => {
      if (hub._id === selected._id) {
        hub.selected = true;
        setSelectedHub(hub);
      } else hub.selected = false;
    });
    // setHubData([...hubData]);
    if (windowSize.width && windowSize.width < 900) {
      setShowList(false);
      if (swiper) swiper?.slideTo(index, 200);
    }
  };
  const scrollToLocation = (index: number) => {
    document
      .getElementById("list")
      ?.children[index].scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = (value: string) => {
    setShowAddress(false);
  };

  const handleOk = (data: AddressPos) => {
    setShowAddress(false);
    setAddress(data)
  };

  const genText = (text: string) => {
    if (text.length >= 49) return `${text.slice(0, 48)}...`;
    return text;
  }

  useEffect(() => {
    calculateDistance();
  }, [cart.address, hubData]);

  const calculateDistance = () => {
    if (!cart.address || !hubData.length) return

    const distList: number[] = []
    hubData.forEach((item: LocationInterface) => {
      const dx = item.latitude - cart.address!.latitude;
      const dy = item.longitude - cart.address!.longitude;
      const dis = Math.sqrt(dx * dx + dy * dy);
      const distance = latLngDistance(cart.address!.latitude, cart.address!.longitude, item.latitude, item.longitude)
      // if (dis) item.dis = dis;
      distList.push(distance)
    });
    setDistances(distList)

    // const locationBatchs: any[][] = hubData.reduce((array: string[][], item) => {
    //   let locationList = array[array.length - 1]

    //   if (locationList!.length >= 20) {
    //     locationList = []
    //     array.push(locationList)
    //   }

    //   locationList!.push(`${item.latitude},${item.longitude}`)
    //   return array
    // }, [[]])

    // Promise.all(locationBatchs.map((locationList) => {
    //   return _getDistance(
    //     `${cart.address!.latitude},${cart.address!.longitude}`,
    //     locationList.join('|'),
    //   ).then((res) => {
    //     let dis = res.data.rows[0]?.elements.map((el: any) => el.distance.value)
    //     if (!dis) {
    //       dis = []
    //       for (let i = 0; i < locationList.length; i++) {
    //         dis.push(0)
    //       }
    //     }
    //     return dis
    //   })
    // })).then((resultList) => {
    //   setDistances(resultList.flat())
    // }).catch((e) => {
    //   console.log(e)
    //   setDistances([])
    // })

    // hubData.sort(
    //   (itemA: LocationInterface, itemB: LocationInterface) =>
    //     itemA.dis - itemB.dis
    // );
    // setHubData([...hubData]);
  };

  const sortHub: LocationInterface[] = useMemo(() => {
    const data: LocationInterface[] = hubData.map((item, index) => {
      return {
        ...item,
        dis: distances[index],
        distance: distances[index] ? `${(distances[index] / 1000).toFixed(2)} km` : '',
        selected: item === selectedHub,
      }
    })

    data.sort((iA, iB) => (iA.dis - iB.dis))
    return data
  }, [distances, hubData, selectedHub])

  const reverseHub: LocationInterface[] = useMemo(() => {
    const data: LocationInterface[] = []
    for (let i = 0; i < sortHub.length; i++) {
      data.push(sortHub[sortHub.length - i - 1])
    }
    return data
  }, [sortHub])
  

  return (
    <>
      <HomeHeader />
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Grid style={{ background: "white" }} container>
          <Grid xs={12} md={3}>
            <Nav>
              {history.action !== "POP" ? (
                <Link to={`/${brandSlug}`} className="f-1">
                  <IconButtonCustom>
                    <NavLeftIcon></NavLeftIcon>
                  </IconButtonCustom>
                </Link>
              ) : (
                <div className="f-1"></div>
              )}

              <Title>Chọn điểm bán</Title>
              <div className="f-1">
                <Box display={{ xs: "flex", md: "none" }} justifyContent="end">
                  {!showList && (
                    <ListIcon onClick={() => setShowList(true)}></ListIcon>
                  )}
                  {showList && (
                    <MapIcon onClick={() => setShowList(false)}></MapIcon>
                  )}
                </Box>
              </div>
            </Nav>
            {/* {windowSize.isMobile && <div style={{ padding: 12 }}>
              <Label>Từ vị trí của bạn</Label>
              <Search>
                <WrapInput onClick={() => setShowAddress(true)}>
                  <PointIcon></PointIcon>
                  <InputBase
                    value={cart.address?.text || ''}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Nhập vị trí của bạn"
                    inputProps={{ "aria-label": "Nhập vị trí của bạn" }}
                  />
                  <IconButton><GPSIcon/></IconButton>
                </WrapInput>
                <Box display={{ xs: "block", md: "none" }}>
                  {!showList && (
                    <ListIcon onClick={() => setShowList(true)}></ListIcon>
                  )}
                  {showList && (
                    <MapIcon onClick={() => setShowList(false)}></MapIcon>
                  )}
                </Box>
              </Search>
            </div>} */}
            
            {(showList || (windowSize.width && windowSize.width >= 900)) && (
              <LocationList id="list">
                {sortHub.map((item: LocationInterface, index: number) => (
                  <LocationItem
                    key={index}
                    data={item}
                    select={(data: LocationInterface) => select(data, index)}
                  ></LocationItem>
                ))}
                {loading && <Loading height={400}></Loading>}
              </LocationList>
            )}
            
          </Grid>
            <Grid xs={12} md={9} sx={{display: (windowSize.width &&
              ((windowSize.width < 900 && !showList) ||
                windowSize.width >= 900)) ? 'block' : 'none'}}>
              <WrapMap>
                <GMap
                  data={reverseHub}
                  select={(data: LocationInterface, index: number) => {
                    select(data, sortHub.length - index - 1);
                    scrollToLocation(sortHub.length - index - 1);
                  }}
                  hub={selectedHub}
                  myLocation={{
                    lat: cart.address?.latitude || 0,
                    lng: cart.address?.longitude || 0,
                  }}
                ></GMap>
               
                  <Place sx={{display: (windowSize.width && windowSize.width < 900 && !showList) ? 'block' : 'none'}}>
                    <Swiper
                      onSwiper={(w: any) => setSwiper(w)}
                      slidesPerView={1}
                      spaceBetween={16}
                      onSlideChange={({ activeIndex }) => {
                        select(sortHub[activeIndex], activeIndex);
                      }}
                    >
                      {sortHub.map(
                        (item: LocationInterface, index: number) => (
                          <SwiperSlide key={index}>
                            <LocationItem
                              round={true}
                              key={index}
                              data={item}
                              select={(data: LocationInterface) => select(data, index)}
                            ></LocationItem>
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </Place>
              </WrapMap>
            </Grid>
        </Grid>
        <AddressModal
          open={showAddress}
          onClose={handleClose}
          onOk={handleOk}
        />
      </Container>
    </>
  );
};

const WrapMap = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 81px);
  position: relative;
  @media (max-width: 899px) {
    height: calc(var(--vh, 1vh) * 100 - 175px);
  }
`;
const Place = styled(Box)`
  position: fixed;
  bottom: 0px;
  padding: 10px 10px 20px 10px;
  width: 100%;
`;
const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  height: 56px;
`;
const NavLeftIcon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${NavLeftImg}");
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  flex-grow: 1;
  text-align: center;
  @media (max-width: 1050px) {
    font-size: 18px;
  }
  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const IconButtonCustom = styled(Button)`
  width: 48px;
  height: 48px;
  min-width: 48px !important;
  &:hover {
    background-color: ${grey[100]} !important;
  }
  &:active {
    background-color: ${grey[100]} !important;
  }
  > .MuiTouchRipple-root span {
    background-color: ${grey[100]};
  }
`;

const PointIcon = styled.div`
  width: 30px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${PointImg}");
`;

const GPSIcon = styled("div")({
  width: 24,
  height: 24,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url('${GPSImg}')`,
});

const Search = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const WrapInput = styled.div`
  border: 1px solid ${grey[300]};
  border-radius: 8px;
  height: 48px;
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
`;

const LocationList = styled(Box)`
  height: calc(var(--vh, 1vh) * 100 - 136px);
  overflow-y: scroll;
  @media (max-width: 899px) {
    height: calc(var(--vh, 1vh) * 100 - 175px);
  }
`;

const ListIcon = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${ListImg}");
`;
const MapIcon = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${MapImg}");
`;
export default Location;
