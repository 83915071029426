import React, { ForwardedRef } from 'react';
import {styled} from '@mui/system';
import Item from './Item';
import {useItemDetail} from 'src/components/ItemDetail/ItemDetailContext'
import {ItemOption} from 'src/models/ItemOption';
import Alert from '@mui/material/Alert'

const PlusItem: React.FC<{ data: ItemOption, optionIndex: number, alert: boolean, innerRef: ForwardedRef<any> }> = ({
  data,
  optionIndex,
  alert = false,
  innerRef,
}) => {
  const {onResetItem, onIncreaseItem} = useItemDetail();

  let selectInstruction = ''
  // if (data.minCount && data.maxCount) {
  //   if (data.minCount === data.maxCount) selectInstruction = `${data.maxCount}`
  //   else selectInstruction = `tối thiểu ${data.minCount} tối đa ${data.maxCount}`
  // } else if (data.minCount) {
  //   selectInstruction = `tối thiểu ${data.minCount}`
  // } else if (data.maxCount) {
  //   selectInstruction = `tối đa ${data.maxCount}`
  // }

  if (data.minCount) {
    selectInstruction = `tối thiểu ${data.minCount}`
  }

  return (
    <Wrap ref={innerRef}>
      <Title>
        {data.name} {selectInstruction && `(chọn ${selectInstruction})`}
      </Title>
      {alert && (
        <Alert style={{ marginTop: 5 }} severity="error">
          Bạn cần chọn {selectInstruction}
        </Alert>
      )}
      {data.choices &&
        data.choices.map((item, index) =>
          <Item
            key={index}
            onClear={() => onResetItem(optionIndex, index)}
            onIncrease={() => onIncreaseItem(optionIndex, index)}
            data={item}/>
        )}
    </Wrap>
  )
}
const Wrap = styled('div')({
  position: 'relative',
  marginTop: 24
})

const Title = styled('div')({
  fontSize: 14,
  fontWeight: 700
})
export default PlusItem;