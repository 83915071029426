import React, { useState } from 'react'
import styled from 'styled-components'
import ArrowRightImg from 'src/assets/images/arrowRight.svg'
import GroupProduct from '../GroupProduct'
import { StoreInterface } from 'src/models/Store'
import { MenuItemCategory } from 'src/models/MenuItemCategory'
import { Link, useHistory } from 'react-router-dom'
import { MenuItem } from 'src/models/MenuItem'
import StoreChecker from 'src/components/Cart/StoreChecker'

interface Props {
  data: StoreInterface | null
  select: (item: MenuItem) => void
  brandName?: string
}
const Brand: React.FC<Props> = ({ data, select, brandName }) => {
  const history = useHistory()
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)

  if (!data) return <></>
  return (
    <>
      {data?.categories && data?.categories.length > 0 && (
        <>
          {data?.brand?.name && (
            <Title id={data?.brand?._id}>
              <H2>{data?.brand?.name}</H2>
              <GoButton to={`thuc-don?b=${data?.brand?.slug}`} onClick={(e) => {
                e.preventDefault()
                setOpenStoreCheck(true)
              }}/>
            </Title>
          )}
          {data.categories?.map((cate: MenuItemCategory, index: number) => (
            <GroupProduct
              select={(item: MenuItem) => select(item)}
              key={index}
              data={cate}
              brand={data?.brand}
              brandName={brandName}
            />
          ))}
        </>
      )}
      {data?.brand && <StoreChecker
        open={openStoreCheck}
        store={{
          _id: data.brand._id || '',
          name: data.brand.name,
          slug: data.brand.slug,
          url: `thuc-don?b=${data.brand.slug}`,
        }}
        onOk={() => {
          history.push(`thuc-don?b=${data.brand!.slug}`)
        }}
        onClose={() => {
          setOpenStoreCheck(false)
        }}
      />}
    </>
  )
}
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const H2 = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0px;
  @media (max-width: 899px) {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 10px;
  }
`

const GoButton = styled(Link)`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ArrowRightImg}');
  cursor: pointer;
`
export default Brand
