import React from "react";
import { styled } from "@mui/system";
import { LocationInterface } from "src/models/Location";
import CheckIcon from '@mui/icons-material/Check';
import DefaultLogo from 'src/assets/images/hub-app-icon-mark.svg';
import MoreBrandLogo from 'src/assets/images/more-brands.svg';

interface Props {
  store: LocationInterface
  distance: string
  selected: boolean
  select: Function
}

const Outlet: React.FC<Props> = ({ store, distance, selected, select }) => {
  let logo = <img src={DefaultLogo} alt={store.name}/>
  let brandName = ''

  if (store.brand) {
    logo = <img src={store.brand.logo || DefaultLogo} alt={store.name}/>
    brandName = `${store.brand.name} - `
  } else if (store.stores && store.stores.length) {
    const logos: string[] = store.stores.reduce((logoArray: string[], store) => {
      if (store.brand?.logo) logoArray.push(store.brand.logo)
      return logoArray
    }, [])

    if (logos.length === 1) {
      logo = <img
        src={logos[0]}
        alt={store.name}
      />
    }

    if (logos.length === 2) {
      logo = <ImgBrand style={{
        backgroundImage: `url(${logos[0]}), url(${logos[1]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '28px 28px',
        backgroundPosition: 'center left, center right',
      }}></ImgBrand>
    }

    if (logos.length === 3) {
      logo = <ImgBrand style={{
        backgroundImage: `url(${logos[0]}), url(${logos[1]}), url(${logos[2]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '28px 28px',
        backgroundPosition: 'top left, top right, bottom center',
      }}></ImgBrand>
    }

    if (logos.length > 3) {
      logo = <ImgBrand style={{
        backgroundImage: `url(${logos[0]}), url(${logos[1]}), url(${logos[2]}), url(${MoreBrandLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '28px',
        backgroundPosition: 'top left, top right, bottom left, bottom right',
      }}></ImgBrand>
    }
  }

  const storeCount = store.brand ? '' : `${store.stores?.length} thương hiệu`
  let desc = store.address

  if (distance && storeCount) {
    desc = `${distance} • ${storeCount}`
  } else if (distance) {
    desc = distance
  } else if (storeCount) {
    desc = storeCount
  }

  return (
    <Wrap onClick={() => select(store)} selected={selected}>
      {logo}
      <Content>
        <Name>{brandName}{store.name}</Name>
        <Status>
          {desc}
        </Status>
      </Content>
      {selected && <Mark><CheckIcon /></Mark>}
    </Wrap>
  );
};

const Wrap = styled('div')<{selected: boolean | undefined}>(({ theme, selected }) => ({
  position: 'relative',
  marginTop: '8px',
  display: 'flex',
  width: '100%',
  border: `1px solid ${selected ? '#F05123' : 'rgba(0, 0, 0, 0)'}`,
  borderRadius: '8px',
  background: '#F2F4F4',
  padding: '16px 12px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, .1)',
  },
  '> img': {
    width: 56,
    height: 56,
    objectFit: 'contain',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    marginRight: 12,
    flexShrink: 0,
    '@media (max-width: 599px)': {
      width: 56,
      height: 56,
    }
  }
}))

const Content = styled('div')({

})

const Name = styled('div')({
  fontWeight: 700,
  fontSize: 14,
})

const Status = styled('div')({
  fontSize: 12,
});

const Mark = styled('div')({
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 32px 32px 0',
  borderColor: 'transparent #f04f23 transparent transparent',
  position: 'absolute',
  right: -1,
  top: -1,
  borderTopRightRadius: 8,
  '& > svg': {
    color: 'white',
    width: 12,
    height: 12,
    marginLeft: 18,
    marginTop: 4,
  }
})

const ImgBrand = styled('div')({
  backgroundColor: '#fff',
  width: 56,
  height: 56,
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.2)',
  marginRight: 12,
})

export default Outlet;
