import { useState, createContext, useContext, useEffect } from "react";
import { ItemOption } from "src/models/ItemOption";
import { MenuItem } from "src/models/MenuItem";
import { OptionChoice } from "src/models/OptionChoice";

interface ItemDetailContextType {
  data: MenuItem;
  total: number;
  isCreate: boolean;
  onDecrease: () => void;
  onIncrease: () => void;
  onResetItem: (optionIndex: number, index: number) => void;
  onIncreaseItem: (optionIndex: number, index: number) => void;
  onSelectOption: (optionIndex: number, choiceIndex: number) => void;
  setNote: (userNote: string) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  isCreate?: boolean
  item: MenuItem
}

const ItemDetailContext = createContext<ItemDetailContextType>(null!);

const useItemDetail = () => useContext(ItemDetailContext);

const ItemDetailProvider: React.FC<Props> = ({ children, item, isCreate = true }) => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<MenuItem>(JSON.parse(JSON.stringify(item)));

  const onDecrease = () => setData({ ...data, quantity: data.quantity ? data.quantity - 1 : 0});
  const onIncrease = () => setData({ ...data, quantity: data.quantity ? data.quantity + 1 : 1});

  const onResetItem = (optionIndex: number, index: number) => {
    const options = data.options;
    options[optionIndex].choices[index].quantity = 0;
    setData({ ...data, ...options});
  };

  const onIncreaseItem = (optionIndex: number, choiceIndex: number) => {
    const options = data.options;
    const option = options[optionIndex]
    const choice = option.choices[choiceIndex]

    const totalSelected = option.choices.reduce((total, currChoice) => {
      return total + (currChoice.quantity || 0)
    }, 0)

    if (totalSelected >= option.maxCount) {
      return
    }

    //@ts-ignore
    if (!choice.quantity) options[optionIndex].choices[choiceIndex].quantity = 0;
    else if (choice.maxCount && choice.quantity >= choice.maxCount ) return

    //@ts-ignore
    options[optionIndex].choices[choiceIndex].quantity += 1;
    setData({ ...data, ...options});
  };

  const onSelectOption = (optionIndex: number, choiceIndex: number) => {
    const options = data.options;
    options[optionIndex].choices.forEach((item) => {
      item.quantity = 0;
    });
    options[optionIndex].choices[choiceIndex].quantity = 1;
    setData({ ...data, ...options });
  };

  const setNote = (userNote: string) => {
    setData({...data, userNote})
  }
  
  useEffect(() => {
    if (!data.quantity || data.quantity === 0) {
      setTotal(0);
      return;
    }
    data.total = data.price * data.quantity;
    data.totalPerItem = data.price;
    let plusTotal = 0;

    data.options.forEach((item: ItemOption) => {
      if (!item.choices) return;
      item.choices.forEach((it: OptionChoice) => {
        if (it.quantity && it.quantity > 0) plusTotal += it.quantity * it.price;
      })
    });
    data.totalPerItem += plusTotal;
    data.total += plusTotal * data.quantity;

    setTotal(data.total);
  }, [data])


  const value = {
    data,
    total,
    isCreate,
    onDecrease,
    onIncrease,
    onResetItem,
    onIncreaseItem,
    onSelectOption,
    setNote
  };
  return (
    <ItemDetailContext.Provider value={value}>
      {children}
    </ItemDetailContext.Provider>
  );
};

export default ItemDetailProvider;
export { useItemDetail };
