import styled from '@emotion/styled'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  RadioProps,
  Select
} from '@mui/material'
import {useState, useEffect} from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {ReactComponent as TickCircle} from 'src/assets/icons/tick-circle.svg'
import {ReactComponent as CircleRadio} from 'src/assets/icons/circle-radio.svg'
import {format, addMinutes, getMinutes} from 'date-fns'

interface DeliveryTimePropType {
  hasShip: boolean
  changeDate: (time: string) => void
}

const DeliveryTime: React.FC<DeliveryTimePropType> = ({hasShip, changeDate}) => {
  const [value, setValue] = useState<string>('now')
  const [date, setDate] = useState<string>('today')

  const times: string[] = []

  let now = new Date()
  now = addMinutes(now, 60 + Math.ceil(getMinutes(now) / 10) * 10 - getMinutes(now))
  for (let i = 0; i < 64; i++) {
    times.push(format(addMinutes(now, i * 10), 'HH:mm'))
  }

  const [time, setTime] = useState<string>(times[0])


  useEffect(() => {
    let d;
    if (date === 'today') d = format((new Date()), 'MM-dd-yyyy')
    if (date === 'tomorrow') {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      d = format(tomorrow, 'MM-dd-yyyy')
    }
    changeDate(`${d} ${time}`)

  }, [date, time])
  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <RadioGroup row value={value} onChange={(e) => setValue(e.target.value)}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  value="now"
                  control={<StyledRadio />}
                  label={(hasShip ? 'Giao' : 'Đến') + ' ngay'}
                />
              </Grid>
              {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: { lg: 'end' } }}>
                <FormControlLabel
                  value="timer"
                  sx={{ mr: 0 }}
                  control={<StyledRadio />}
                  label={'Hẹn giờ ' + (hasShip ? 'giao' : 'đến')}
                />
              </Grid> */}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>

      {value === 'timer' && (
        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="space-between" gap={3}>
            <FormControl fullWidth>
              <SelectStyled onChange={(e) => { setDate((e.target.value as any)) }} defaultValue={date} IconComponent={KeyboardArrowDownIcon}>
                <MenuItem value="today">Hôm nay</MenuItem>
                <MenuItem value="tomorrow">Ngày mai</MenuItem>
              </SelectStyled>
            </FormControl>

            <FormControl fullWidth>
              <SelectStyled onChange={(e: any) => { setTime(e?.target?.value as string) }} defaultValue={time} IconComponent={KeyboardArrowDownIcon}>
                {!!times.length &&
                  times.map((time: string, index: number) => (
                    <MenuItem key={index} value={time}>
                      {time}
                    </MenuItem>
                  ))}
              </SelectStyled>
            </FormControl>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

const SelectStyled = styled(Select)({
  '&.MuiSelect-root .MuiSelect-select': {
    paddingBottom: '12.5px',
    paddingTop: '12.5px',
    borderRadius: 8
  }
})

function StyledRadio(props: RadioProps) {
  return <Radio checkedIcon={<TickCircle />} icon={<CircleRadio />} {...props} />
}

export default DeliveryTime
