import React from 'react'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { ThemeProvider as MThemeProvider } from '@mui/material'
import Cart from './components/Cart'
import GlobalStyles from './globalStyle'
import NotFound from './pages/NotFound'
import routes from './routes'
import { useAppSelector } from './store/hooks'
import useWindowSize from './utils/hooks/useWindowSize'
import BrandProvider from './pages/provider'
import mTheme from './muiStyled'
import CartProvider from "./components/Cart/CartContext";
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #F2F2F2;
  > .page {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const App: React.FC = () => {
  const tintColor = useAppSelector((state) => state.config.tintColor)
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })
  return (
    <ThemeProvider
      theme={{
        tintColor: tintColor,
        background: '#FFFFFF',
        textColor: '#475F7B',
        mainColor: '#39B54A'
      }}
    >
      <MThemeProvider theme={mTheme}>
        <GlobalStyles />
        <BrowserRouter>
          <BrandProvider>
            <CartProvider>
              <AppRouter />
            </CartProvider>
          </BrandProvider>
        </BrowserRouter>
      </MThemeProvider>
    </ThemeProvider>
  )
}
const AppRouter = withRouter(({ location }) => {
  const windowSize = useWindowSize()
  if (windowSize.isMobile != null) {
    return (
      <Wrap>
        <div>
          {/* <Map /> */}
          <Switch location={location}>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                render={(props) =>
                  !windowSize.isMobile && route.componentPC ? (
                    <route.componentPC {...props} />
                  ) : (
                    <route.component {...props} />
                  )
                }
              />
            ))}
            <Route>
              <NotFound />
            </Route>
          </Switch>
          <Cart />
        </div>
      </Wrap>
    )
  } else {
    return <div></div>
  }
})
export default App
