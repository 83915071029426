import { Button, Container, Stack } from '@mui/material'

import { _destroyOrder } from 'src/utils/api'
import { useState } from 'react'
import { useCart } from '../Cart/CartContext'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'src/utils/hooks/useWindowSize'

interface DestroyOrderProps {
  data: any
  onClose: (e?: any) => void
  onReload: (showSnackBar?: boolean) => void
}

export default function DestroyOrder(props: DestroyOrderProps) {
  const { data, onClose, onReload } = props
  const [loading, setLoading] = useState<boolean>(false)
  const { uuid } = useCart()
  const history = useHistory()
  const windowSize = useWindowSize()
  const handleDestroy = async () => {
    try {
      setLoading(true)
      await _destroyOrder(data._id, { uuid: uuid })
      setLoading(false)
    } catch (e) {
      onClose(e)
    }
    if (windowSize.isMobile) {
      onClose()
      history.goBack()
    } else {
      onClose()
    }
    onReload(true)
  }
  return (
    <Container>
      <Stack direction="row" justifyContent="center" spacing={2} my={2} alignItems="center">
        <Button variant="outlined" color="warning" onClick={onClose}>
          Hủy
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleDestroy}
          sx={{ px: 5, py: 1, borderRadius: 2, background: '#39B54A', fontWeight: 'bold' }}
        >
          Xác nhận
        </Button>
      </Stack>
    </Container>
  )
}
