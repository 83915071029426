import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import PointIcon from 'src/assets/icons/point.svg'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import { LocationInterface } from 'src/models/Location'
import { useHistory } from 'react-router-dom'
import Popup from 'src/components/Modal/Popup'
import BrandModal from './BrandModal'
import { useBrand } from '../provider'
import { grey } from 'src/utils/color'
import StoreChecker from 'src/components/Cart/StoreChecker'

export interface BrandItem {
  _id?: string
  name: string
  slug: string
  logo: string
}

export interface StoreItem {
  _id?: string
  name: string
  slug: string
  brand: BrandItem
}

const LocationItem: React.FC<{ data: LocationInterface; round?: boolean; select: Function }> = ({
  data,
  round,
  select,
}) => {
  const history = useHistory()
  const [show, setShow] = useState<boolean>(false)
  const [brands, setBrand] = useState<BrandItem[]>([])
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)
  const { pre } = useBrand()

  const handleShowBrand = (e: any) => {
    setShow(true)
  }

  const handleCloseBrand = () => {
    setShow(false)
  }

  const onClickMenu = () => {
    if (data.brand) {
      history.push(`${pre}/thuc-don?st=${data.slug}`)
    } else {
      history.push(`${pre}/thuc-don?h=${data.slug}`)
    }
  }

  useEffect(() => {
    if (data.brand) {
      setBrand([data.brand])
    } else {
      //@ts-ignore
      const temp = data.stores?.map((store: StoreItem) => store.brand)
      if (temp) {
        setBrand(temp)
      }
    }
  }, [data])
  return (
    <Wrap
      sx={{
        borderRadius: round ? '16px' : '0px',
        backgroundColor: data.selected ? grey[100] : 'white'
      }}
      onClick={(e) => {
        // select(data)
        // e.preventDefault()
      }}
    >
      <Name round={round} onClick={() => {select(data)}}>
        {data.brand ? `${data.brand.name} - ` : ''}
        {data.name}
      </Name>
      <Address round={round} onClick={() => {select(data)}}>
        <IconWrap>
          <IconPos></IconPos>
        </IconWrap>
        <AddressText round={round}>{data.address}</AddressText>
      </Address>
      <More>
        <Brand onClick={handleShowBrand}>{data.numBrand} thương hiệu</Brand>
        <Popup
          children={<BrandModal handleClose={handleCloseBrand} brands={brands} />}
          open={show}
          title={data.name + ' - ' + data.numBrand + ' thương hiệu'}
          onClose={handleCloseBrand}
          defaultClose
        />
        <Distance>{data.distance}</Distance>
      </More>
      <HightOutlinedButton
        onClick={() => {
          setOpenStoreCheck(true)
        }}
        style={{ width: '100%', fontSize: 16 }}
      >
        Đặt món ngay
      </HightOutlinedButton>
      <StoreChecker
        open={openStoreCheck}
        store={{
          _id: data._id,
          name: data.name,
          slug: data.slug,
          url: data.brand ? `${pre}/thuc-don?st=${data.slug}` : `${pre}/thuc-don?h=${data.slug}`,
        }}
        onOk={() => {
          onClickMenu()
        }}
        onClose={() => {
          setOpenStoreCheck(false)
        }}
      />
      <div style={{display: '-webkit-flex'}}></div>
    </Wrap>
  )
}

const Wrap = styled(Box)`
  padding: 16px 12px;
  background: white;
  border-bottom: 1px solid ${grey[200]};
  cursor: pointer;
`
const Name = styled('div')<{round?: boolean}>(({ round }) => ({
  'fontWeight': 700,
  'fontSize': '14px',
  'lineHeight': '20px',
  'marginBottom': '5px',
}))
const Address = styled('div')<{round?: boolean}>(({round}) => ({
  'fontWeight': 400,
  'fontSize': '14px',
  'lineHeight': '20px',
  'display': 'flex',
  'alignItems': 'center',
}))
const AddressText = styled.div<{round?: boolean}>`
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-right: 10px;
  flex-shrink: 0;
`
const IconPos = styled.div`
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${PointIcon}');
`
const Brand = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.mainColor};
  border: 1px solid ${(props) => props.theme.mainColor};
  border-radius: 8px;
  padding: 4px 10px;
`
const More = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
`
const Distance = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${grey[300]};
`
export default LocationItem
