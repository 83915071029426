import React, { useState } from 'react'
import { styled } from '@mui/system'
import CheckIcon from '@mui/icons-material/Check'

interface PayMethod {
  name: string
  selected: boolean
  id: string
}
interface props {
  payMethods: PayMethod[]
  select: (value: string) => void
}
const ButtonGroup: React.FC<props> = ({ payMethods, select }) => {
  const [data, setData] = useState<PayMethod[]>(payMethods)

  const handleClick = (index: number) => {
    const d = [...payMethods]
    d.forEach((item: PayMethod) => (item.selected = false))
    d[index].selected = true
    setData(d)
    select(d[index].id)
  }

  return (
    <Wrap>
      {data.map((item: PayMethod, index: number) => (
        <Btn
          key={index}
          onClick={() => handleClick(index)}
          style={{
            marginLeft: 6,
            boxShadow: item.selected ? '0px 0px 0px 1px #f04f23 inset' : 'unset'
          }}
        >
          {item.name}
          {item.selected && (
            <Mark>
              <CheckIcon />
            </Mark>
          )}
        </Btn>
      ))}
    </Wrap>
  )
}
const Wrap = styled('div')({
  display: 'flex'
})

const Mark = styled('div')({
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 32px 32px 0',
  borderColor: 'transparent #f04f23 transparent transparent',
  position: 'absolute',
  right: 0,
  top: 0,
  '& > svg': {
    color: 'white',
    width: 12,
    height: 12,
    marginLeft: 18,
    marginTop: 4
  }
})

const Btn = styled('div')({
  height: 48,
  background: '#FCFCFC',
  width: '50%',
  borderRadius: 8,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  position: 'relative',
  overflow: 'hidden',
  flexShrink: 1,
  textAlign: 'center'
})

export default ButtonGroup
