import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Notice from 'src/components/Notice'
import InforCover from './InforCover'
import Grid from '@mui/material/Grid'
import SideMenu from 'src/components/SideMenu'
import Box from '@mui/material/Box'
import ScrollMenu from 'src/components/ScrollMenu'
import Brand from 'src/components/Brand'
import NotFound from 'src/pages/NotFound'
import { StoreInterface } from 'src/models/Store'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import useScroll from 'src/utils/hooks/useScroll'
import NavBar from './NavBar'
import { HubPageInterface } from 'src/models/HubPage'
import { MenuItem } from 'src/models/MenuItem'
import SideScreen from 'src/components/Modal/SideScreen'
import ItemDetail from 'src/components/ItemDetail'
import ItemDetailProvider from 'src/components/ItemDetail/ItemDetailContext'
import { Group } from 'src/models/Group'
import { useCart } from 'src/components/Cart/CartContext'
import { NO_DELIVERY_ADDRESS } from 'src/utils/constants'
import flyToCart from 'src/utils/flyToCart/flyToCart'
import { Stack } from '@mui/material'
import OpenTimeCheck from 'src/components/OpenTimeCheck'

interface Props {
  data: HubPageInterface
}
const HubPage: React.FC<Props> = ({ data }) => {
  const { addToCart, cart } = useCart()
  const [numOutlet, setNumOutlet] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const windowSize = useWindowSize()
  const { scrollPosition } = useScroll()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null)
  const [openCheckOpenTime, setOpenCheckOpenTime] = useState<boolean>(true)

  const select = (item: MenuItem, store: StoreInterface | undefined) => {
    const it = { ...item, quantity: 1, selected: true }
    if (it.options && it.options.length > 0) {
      setSelectedMenuItem(it)
      setOpen(true)
    } else {
      addToCart(it, undefined, (_added) => {
        flyToCart(it._id)
      })
    }
  }

  useEffect(() => {
    const numOutlet = cart.orders?.filter((outlet) => outlet.selected || outlet.items.filter((item) => item.selected).length)?.length;
    setNumOutlet(numOutlet);
  }, [cart])

  const getNotice = () => {
    return {
      title: '📦️ Lưu ý tách đơn',
      detail: `Đặt hết các món trong giỏ hàng, bạn sẽ cần nhận ${numOutlet} đơn giao hàng khác nhau.`,
      showIcon: true
    }
  }
  
  if (error) {
    return <NotFound />
  }
  return (
    <>
      {scrollPosition >= 280 && data && <NavBar data={data} />}
      <Container disableGutters={!!windowSize.isMobile}>
        <InforCover data={data} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={3} display={{ xs: 'none', md: 'block' }}>
            {data && <SideMenu data={data} top={96} />}
          </Grid>
          <Grid item md={9} sx={{ width: { xs: '100%' } }}>
            {/* <Stack spacing={0.5} mb={2.5} mt={1.5}>
              {!cart.address?.text && <Notice data={NO_DELIVERY_ADDRESS} />}
              {numOutlet > 1 && <Notice data={getNotice()} />}
            </Stack> */}
            {windowSize.width && windowSize.width >= 900 && data && data.stores && (
              <Box display={{ xs: 'none', md: 'block' }}>
                {data.activeStore ? (
                  <Brand
                    select={(item: MenuItem) => select(item, data.activeStore)}
                    data={data.activeStore}
                    brandName={data.name}
                  />
                ) : (
                  data?.stores.map((item: StoreInterface, index: number) => (
                    <Brand
                      select={(menuItem: MenuItem) => select(menuItem, item)}
                      data={item}
                      key={index}
                    />
                  ))
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Box style={{ backgroundColor: 'white' }} display={{ xs: 'block', md: 'none' }}>
        {data && <ScrollMenu data={data} top={'55px'} />}
        {windowSize.width && windowSize.width < 900 && data && data.stores && (
          <Box display={{ xs: 'block', md: 'none' }}>
            {data.activeStore ? (
              <Brand
                select={(item: MenuItem) => select(item, data.activeStore)}
                data={data.activeStore}
                brandName={data.name}
              />
            ) : (
              data?.stores.map((item: StoreInterface, index: number) => (
                <Brand
                  select={(menuItem: MenuItem) => select(menuItem, item)}
                  data={item}
                  key={index}
                />
              ))
            )}
          </Box>
        )}
      </Box>
      <SideScreen open={open} onClose={() => setOpen(false)} defaultClose={true}>
        {selectedMenuItem ? (
          <ItemDetailProvider item={selectedMenuItem}>
            <ItemDetail
              onClose={() => {
                setOpen(false)
              }}
            />
          </ItemDetailProvider>
        ) : (
          <></>
        )}
      </SideScreen>
      <OpenTimeCheck
        open={openCheckOpenTime}
        onClose={() => {setOpenCheckOpenTime(false)}}
      />
    </>
  )
}

export default HubPage
