import { MenuItem } from 'src/models/MenuItem'
import { createSlice } from '@reduxjs/toolkit'
import { fetchMenuItems } from './thunks'
import { _format as formatItem } from 'src/models/MenuItem'
export interface MenuCategory {
    name: string
    id: string | null
    items: Array<MenuItem>
}

export interface MenuState {
    category: Array<MenuCategory>
}

const initialState: MenuState = {
    category: [],
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMenuItems.fulfilled, (state, { payload }) => {
            const items = payload.data.data
            let menu: Array<MenuCategory> = []
            menu.push({
                name: 'Chưa phân loại',
                id: null,
                items: [],
            })
            items.forEach((item: any) => {
                let formattedItem = formatItem(item)
                if (typeof item.categories != 'undefined' && item.categories.length > 0) {
                    item.categories.forEach((cat: any) => {
                        const index = menu.findIndex((c) => c.id === cat._id)
                        if (index > -1) {
                            menu[index].items.push(formattedItem)
                        } else {
                            menu.push({
                                name: cat.name,
                                id: cat._id,
                                items: [formattedItem],
                            })
                        }
                    })
                } else {
                    menu[0].items.push(formattedItem)
                }
            })
            if (menu[0].items.length === 0) {
                menu.shift()
            }
            state.category = menu
        })
    },
})

export default menuSlice.reducer
