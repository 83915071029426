import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CateItem } from 'src/models/CateItem'
import { HubPageInterface } from 'src/models/HubPage'
import { MenuItemCategory } from 'src/models/MenuItemCategory'
import { StoreInterface } from 'src/models/Store'
import useDebounce from 'src/utils/hooks/useDebonce'
import { useScrollspy } from 'src/utils/hooks/useScrollspy'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import styled from 'styled-components'
import ListImg from '../../assets/icons/black-list.svg'
interface Props {
  data: HubPageInterface
  top?: string
}

const ScrollMenuFn: React.FC<Props> = ({ data, top }) => {
  const [activeId, setActiveId] = useState<string>('')
  const windowSize = useWindowSize()

  const selectItem = (item: any, activeId: string) => {
    setActiveId(activeId)
    let id
    if (item.brand && item.brand._id) {
      id = item?.brand?._id
    } else {
      id = `${data.activeStore?.brand?._id || ''}-${item._id}`
    }
    var rect = document?.getElementById(id)?.getBoundingClientRect()
    if (rect) {
      let top = rect.top + window.scrollY - 110
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'auto'
      })
    }
  }

  let ids = []
  if (data.activeStore) {
    ids = (data.activeStore && data.activeStore.categories?.map((cate: CateItem) => `${data.activeStore?.brand?._id || ''}-${cate._id}`)) || []
  } else {
    ids =
      (data.stores &&
        data.stores
          .map((store: StoreInterface) => store.categories?.map((cate: CateItem) => `${store.brand?._id || ''}-${cate._id}`))
          .flat(2)) ||
      []
  }
  const scrollActiveId = useScrollspy(ids as string[], windowSize.height && windowSize.height / 2)

  const debouncedValue = useDebounce<string>(scrollActiveId, 10)
  useEffect(() => {
    if (scrollActiveId) {
      setActiveId(scrollActiveId)
    }
  }, [debouncedValue])
  return (
    <Wrap style={{ top }}>
      <ListIcon />
      <Menu>
        {!data.activeStore &&
          data.stores &&
          data.stores.map((item: StoreInterface, index: number) => (
            <Item
              onClick={() => selectItem(item, item?.brand?._id as string)}
              key={index}
              className={
                item?.categories?.map((cate) => `${item.brand?._id || ''}-${cate._id}`).includes(activeId) ||
                item?.brand?._id === activeId
                  ? 'scroll-menu-active'
                  : ''
              }
            >
              <Link to="#">{item.brand?.name}</Link>
            </Item>
          ))}

        {data.activeStore &&
          data.activeStore.categories &&
          data?.activeStore?.categories.map((item: MenuItemCategory, index: number) => (
            <Item
              onClick={() => selectItem(item, `${data.activeStore?.brand?._id || ''}-${item._id}`)}
              key={index}
              className={activeId === `${data.activeStore?.brand?._id || ''}-${item._id}` ? 'scroll-menu-active' : ''}
            >
              <Link to="#">{item.name}</Link>
            </Item>
          ))}
      </Menu>
    </Wrap>
  )
}
const Wrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e7e6;
  position: sticky;
  background: white;
  z-index: 1;
`
const Menu = styled.ul`
  padding-inline-start: 0px;
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  background: white;
`

const Item = styled.li`
  list-style: none;
  display: inline;
  margin: 0px 15px;
  > a {
    text-decoration: none;
    color: black;
    padding: 16px 0px;
    display: inline-block;
    cursor: pointer;
  }
`

const ListIcon = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ListImg}');
  flex-shrink: 0;
`

const ScrollMenu = memo(ScrollMenuFn)
export default ScrollMenu
