import React from 'react'
import styled from 'styled-components'
import { useCart } from 'src/components/Cart/CartContext'
const Wrap = styled.div`
  padding: 12px;
  background: white;
  border-top: 1px solid #e8e7e6;
  color: white;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
`
const Detail = styled.div`
  max-width: 546px;
  border-radius: 8px;
  padding: 6px 24px;
  background-color: ${(props) => props.theme.mainColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 56.6px;
`
const View = styled.div`
  font-size: 16px;
  font-weight: 700;
`
const Quantity = styled.div`
  font-size: 14px;
`
const CartBar: React.FC = () => {
  const { data, totalItem, toggleCart, dataTotal } = useCart()

  return (
    <>
      {data.items.length > 0 && (
        <Wrap onClick={() => toggleCart(true)}>
          <Detail>
            <div>
              <Quantity style={{fontSize: dataTotal > 0 ? '14px' : '16px', fontWeight: 'bold' }}>{totalItem} món</Quantity>

            </div>
            <View>Xem giỏ hàng</View>
          </Detail>
        </Wrap>
      )}
    </>
  )
}

export default CartBar
