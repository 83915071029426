import Container from '@mui/material/Container'
import React from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import LogoImg from 'src/assets/images/logo.svg'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FoodImg from 'src/assets/images/food.svg'
import GrabImg from 'src/assets/images/grab.svg'
import GojekImg from 'src/assets/images/gojek.svg'
import AirkitchenImg from 'src/assets/images/airkitchen.svg'
import BaeminImg from 'src/assets/images/baemin.svg'

const Wrap = styled.div`
    padding: 80px 0px;
    background: white;
`
const Intro = styled.div`
    font-size: 14px;
    margin-top: 16px;
    max-width: 356px;
`
const Social = styled.div`
    display: flex;
    margin-top: 16px;
`
const WrapIcon = styled.div`
    color: #9f9d99;
    margin-right: 24px;
`
const MenuTitle = styled.div`
    color: #9f9d99;
    border-bottom: 1px solid #e8e7e6;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0px;
`
const WrapPartner = styled.div`
    display: flex;
    margin-bottom: 50px;
    flex-wrap: wrap;
    > div{
        margin-right: 11px;
        margin-top: 10px;
    }
`

const Menu = styled.div`
    padding-inline-start: 0px;
    > li{
        list-style: none;
        font-size: 14px;
        padding: 10px 0px;
        > a{    
            display: block;
            text-decoration: none;
            color: black;
        }
    }
`

const PartnerItem: React.FC<{img: string, text: string}> = ({img, text}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={img} style={{marginRight: 7}}/>
            <div style={{fontSize: 14}}>{text}</div>
        </div>
    )
}

const Footer: React.FC = () => {
    return (
        <Wrap style={{marginTop: '40px'}}>
            <Container>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={6}>
                        <img src={LogoImg}/>
                        <Intro>Chuỗi nhà hàng số đầu tiên tại Việt Nam phục vụ món giao đi tiêu chuẩn cao</Intro>
                        <Social>
                            <WrapIcon><FacebookIcon></FacebookIcon></WrapIcon>
                            <WrapIcon><InstagramIcon/></WrapIcon>
                            <WrapIcon><YouTubeIcon/></WrapIcon>
                            <WrapIcon><LinkedInIcon/></WrapIcon>
                        </Social>
                    </Grid>
                    <Grid item md={6}>
                        <MenuTitle>Luôn sẵn sàng giao tận nơi</MenuTitle>
                        <WrapPartner>
                            <PartnerItem img={AirkitchenImg} text={'AirKitchen'}></PartnerItem>
                            <PartnerItem img={FoodImg} text={'ShopeeFood'}></PartnerItem>
                            <PartnerItem img={GrabImg} text={'GrabFood'}></PartnerItem>
                            <PartnerItem img={BaeminImg} text={'Baemin'}></PartnerItem>
                            <PartnerItem img={GojekImg} text={'Gojek'}></PartnerItem>
                        </WrapPartner>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6} md={4}>
                                <MenuTitle>Hợp tác</MenuTitle>
                                <Menu>
                                    <li>
                                        <a href="#">Mua nhượng quyền</a>
                                    </li>
                                    <li>
                                        <a href="#">Cấp nhượng quyền</a>
                                    </li>
                                </Menu>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <MenuTitle>Hỗ trợ</MenuTitle>
                                <Menu>
                                    <li>
                                        <a href="#">Chính sách</a>
                                    </li>
                                    <li>
                                        <a href="#">Điều kiện</a>
                                    </li>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Wrap>
    )
}
export default Footer
