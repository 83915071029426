
import React, { useEffect, useMemo } from 'react'
import { HightOutlinedButton } from 'src/components/Button/OutlinedButton'
import Popup from 'src/components/Modal/Popup'
import { BACKEND_WEEKDAY } from 'src/utils/constants'
import { useCart } from 'src/components/Cart/CartContext';
import { styled } from '@mui/system'

interface Props {
  open: boolean
  onClose: () => void
  onOK?: () => void 
}

const OpenTimeCheck: React.FC<Props>  = ({ open, onClose, onOK }) => {
  const { currentStore } = useCart()

  const [isValidOpenTime, dislayOpenTimes] = useMemo(() => {
    let isValidOpenTime = false
    const dislayOpenTimes: [string, string][] = []

    const now = new Date()
    const hour = now.getUTCHours() > 16 ? now.getUTCHours() - 17 : now.getUTCHours() + 7
    const minute = now.getUTCMinutes()
    const timePoint = hour * 60 + minute

    const todayOpenTime = currentStore && currentStore.openTimes && currentStore.openTimes.find((ot) => {
      return ot.day === BACKEND_WEEKDAY[now.getUTCDay()]
    })

    if (!todayOpenTime) {
      isValidOpenTime = true
    } else {
      for (let i = 0; i < todayOpenTime.times.length; i++) {
        const [startHour, startMinute] = todayOpenTime.times[i][0].split(':')
        const [endHour, endMinute] = todayOpenTime.times[i][1].split(':')
  
        const startTimePoint = parseInt(startHour) * 60 + parseInt(startMinute)
        const endTimePoint = parseInt(endHour) * 60 + parseInt(endMinute)
  
        if (timePoint < endTimePoint) {
          dislayOpenTimes.push(todayOpenTime.times[i])
        }
  
        if (startTimePoint <= timePoint && timePoint <= endTimePoint) {
          isValidOpenTime = true
        }
      }
    }

    return [isValidOpenTime, dislayOpenTimes]
  }, [open, (currentStore?._id || '_id')])

  const openPopup = !!(open && currentStore && currentStore.openTimes && !isValidOpenTime)

  useEffect(() => {
    if (open && !openPopup) {
      onClose()
      onOK && onOK()
    }
  })

  return <Popup title="Ngoài giờ phục vụ" open={openPopup} onClose={onClose}>
    <Content>
      Vui lòng trở lại đặt món vào {dislayOpenTimes.map(([start, end]) => `${start}-${end}`).join(', ')}
    </Content>
    <ButtonWrap>
      <HightOutlinedButton
        onClick={() => {
          onClose()
        }}
        style={{
          height: 40,
          width: 126,
          cursor: 'pointer',
        }}
      >
        OK
      </HightOutlinedButton>
    </ButtonWrap>
  </Popup>
}

const Content = styled('div')({
  textAlign: 'center',
})

const ButtonWrap = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '16px 0',
})

export default OpenTimeCheck
