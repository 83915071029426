import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Background from 'src/components/Background'
import Button from 'src/components/Button'
import NavHeader from 'src/components/Header/NavHeader'
import SearchInput from 'src/components/SearchInput'
import { fetchMenuItems } from 'src/store/features/menu/thunks'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { useScrollPosition } from 'src/utils/hooks/useScrollPosition'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { formatCurrency } from 'src/utils/myUtils'
import styled from 'styled-components'
import Header from './Header'
import MenuItem from './MenuItem'
import ItemOrder from './OrderItem'
import queryString from 'query-string'
import Stores from './Stores'
import { addItem } from 'src/store/features/order/slice'
import { OrderItem } from 'src/models/OrderItem'
import { useLocation } from 'react-router'
import { getBrandInfo } from 'src/store/features/brand/thunks'
import Popup from 'src/components/Modal/Popup'
import Detail from './MenuItem/Detail'
import { MenuItem as ItemInterface } from 'src/models/MenuItem'
const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    flex: 1;
    padding: 16px;
    padding-top: 310px;
    position: relative;
    z-index: 0;
`
const Container = styled.div`
    position: relative;
    padding: 0px 10%;
    display: flex;
    > .w-100 {
        width: 100%;
    }
    > .w-50 {
        width: 48%;
    }
`
const Notice = styled.div`
    margin-top: 16px;
    background: #fff5d8;
    padding: 8px;
    border-radius: 8px;
    max-height: 92px;
    > p.content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 51px;
        line-height: 17px;
        margin-top: 5px;
        font-size: 12px;
    }
`
const CategoryBar = styled.div`
    width: 33%;
    > * {
        margin: 18px 0px;
    }
    > div {
        position: sticky;
        top: 235px;
        > div {
            max-height: 60vh;
            overflow: scroll;
            margin-right: 16px;
            > div {
                margin: 18px 0px;
                > a {
                    text-decoration: none;
                }
            }
        }
    }
    div.cate .active > p {
        border-bottom: 3px solid ${(props) => props.theme.tintColor};
        width: fit-content;
    }
`

const MenuSection = styled.div`
    width: 100%;
    padding: 0px 16px;
    border-left: 1px solid #ebebeb;
    > .search-section {
        border-bottom: 1px solid #ebebeb;
        margin: -16px;
        padding: 16px 16px 0px;
        div,
        div:after,
        div:before {
            border-bottom: none !important;
        }
    }
`

const CateSection = styled.div`
    margin-top: -112px;
    padding-top: 112px;
`
const Main = styled.div`
    display: flex;
    border-top: 1px solid #ebebeb;
    background: #fff;
    margin-top: 16px;
`
const CategoryLabel = styled.p`
    margin-top: 16px;
`
const SideSection = styled.div`
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
    border-radius: 16px;
    margin-top: 24px;
    position: sticky;
    overflow: hidden;
    top: 126px;
    > .button {
        padding: 16px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    }
`
const ButtonStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    p,
    svg {
        color: #fff;
    }
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            margin-left: 4px;
        }
    }
`
const PopupHeader = styled.div`
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99;
    justify-content: space-between;
    margin: 0px 16px 16px;
    background: #fff;
`
const Menu: React.FC = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const windowSize = useWindowSize()
    const location = useLocation<string>()
    const query = queryString.parse(location.search)
    const [keyword, setKeyword] = useState<string>('')
    const params = useParams<Record<string, string | undefined>>()
    const [sectionActive, setSectionActive] = useState<number>(0)
    const [scrolling, setScrolling] = useState<boolean>(false)
    const [detailPopupOpen, setDetailPopupOpen] = useState<boolean>(false)
    const [sharedItemCate, setSharedItemCate] = useState<string>()
    const [sharedItem, setSharedItem] = useState<ItemInterface>()
    const [open, setOpen] = useState<boolean>(false)
    const menu = useAppSelector((state) => state.menu)
    const storeData = useAppSelector((state) => state.store)
    const orderItems = useAppSelector((state) => state.order.items)
    const majorBrand = useAppSelector((state) => state.majorBrand)
    const brandLogo = useAppSelector((state) => state.brand?.logo)
    let price = 0
    let countOrder = 0
    orderItems.forEach((item) => {
        countOrder += item.quantity
        if (item.price) {
            price += item.price * item.quantity
        }
    })
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (menu.category.length > 0) {
            menu.category.map((c, index) => {
                c.items.map((item, index) => {
                    if (query.item == item.id) {
                        if (orderItems.some((i) => i.itemId == query.item)) {
                            return
                        }
                        setSharedItem(item)
                        setSharedItemCate(c.id || '')
                        setDetailPopupOpen(true)
                    }
                })
            })
        }
    }, [menu])
    useEffect(() => {
        const slug = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        dispatch(getBrandInfo(`slug=${slug}`))
    }, [])
    
    useEffect(() => {
        if (storeData.selectedStore.slug) {
            dispatch(fetchMenuItems(`slug=${storeData.selectedStore.slug}`))
        }
    }, [storeData.selectedStore.slug])

    const searchHandler = (value: string) => {
        setKeyword(value)
    }
    const nextHandler = () => {
        if (orderItems.length > 0) {
            history.push(`${window.location.pathname}/order-information`)
        }
    }
    useScrollPosition(() => {
        menu.category.forEach((c, index) => {
            let elementY = document.getElementById(`menu_section_${index}`)?.getBoundingClientRect().top || 99999
            if (windowSize.height && elementY < windowSize.height / 2 && !scrolling) {
                setSectionActive(index)
            }
        })
    }, [sectionActive, windowSize.height])
    const onClickMenuTab = (index: number) => {
        setScrolling(true)
        setTimeout(() => setScrolling(false), 2000)
        let bodyY = document.body.getBoundingClientRect().top
        let elementY = document.getElementById(`menu_section_${index}`)?.getBoundingClientRect().top || 99999
        window.scrollTo(0, Math.abs(bodyY - elementY))
    }

    const onClickHome = () => {
        if (majorBrand.slug) {
            history.push(`/${params.brand}`)
        }
        if (majorBrand.domain) {
            history.push(`/`)
        }
    }

    return (
        <>
            <Popup open={detailPopupOpen} onClose={() => setDetailPopupOpen(false)}>
                <PopupHeader>
                    <p className='heading-3'>Thêm món</p>
                    <CloseRoundedIcon onClick={() => setDetailPopupOpen(false)} />
                </PopupHeader>
                <>
                    {sharedItem && sharedItemCate ? (
                        <Detail
                            item={sharedItem}
                            categoryId={sharedItemCate}
                            onClose={() => setDetailPopupOpen(false)}
                        />
                    ) : null}
                </>
            </Popup>
            <NavHeader logo={brandLogo || ''} onClickHome={onClickHome} onClickIntro={() => setOpen(true)} />
            <Container className='container'>
                <Background />
                <div className='w-100'>
                    <Header open={open} setOpen={setOpen} />
                    <Wrap>
                        <Stores />
                        {storeData.selectedStore.announce && (
                            <Notice>
                                <p className='heading-4'>Thông báo</p>
                                <p className='content'>{storeData.selectedStore.announce}</p>
                            </Notice>
                        )}
                        <Main>
                            <CategoryBar>
                                <div>
                                    <p className='heading-3'>Thực đơn</p>
                                    <div className='cate'>
                                        {menu.category.map((c, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        onClickMenuTab(index)
                                                        setSectionActive(index)
                                                    }}>
                                                    <a className={sectionActive == index ? 'active' : ''}>
                                                        <p>{c.name}</p>
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </CategoryBar>
                            <MenuSection>
                                <div className='search-section'>
                                    <SearchInput
                                        expand
                                        value={keyword}
                                        onChange={searchHandler}
                                        placeholder='Tìm món nhanh'
                                    />
                                </div>
                                {menu.category.map((c, index) => {
                                    return (
                                        <CateSection key={index} id={'menu_section_' + index}>
                                            {c.items.filter(
                                                (item) => item.name.toLocaleLowerCase().indexOf(keyword) > -1
                                            ).length > 0 && (
                                                <CategoryLabel className='heading-3'>{c.name}</CategoryLabel>
                                            )}
                                            {c.items
                                                .filter((item) => item.name.toLocaleLowerCase().indexOf(keyword) > -1)
                                                .map((item, index) => {
                                                    return <MenuItem key={index} item={item} categoryId={c.id} />
                                                })}
                                        </CateSection>
                                    )
                                })}
                            </MenuSection>
                        </Main>
                    </Wrap>
                </div>
                <div style={{ minWidth: 30 }} />
                <div className='w-50' style={{ zIndex: 9 }}>
                    <SideSection>
                        <div style={{ padding: '16px 16px 0px' }}>
                            <p className='heading-3' style={{ marginBottom: 16 }}>
                                Món chọn
                            </p>
                            <div style={{ maxHeight: '70vh', overflow: 'scroll' }}>
                                {orderItems.map((item, index) => (
                                    <ItemOrder key={index} item={item} isLast={index == orderItems.length - 1} />
                                ))}
                            </div>
                        </div>
                        <Button className='button' onClick={nextHandler}>
                            <ButtonStyle>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <p style={{ color: '#fff', marginBottom: 5, lineHeight: '17px' }}>
                                        {countOrder} món
                                    </p>
                                    <p className='heading-3'>{formatCurrency(price)}</p>
                                </div>
                                <div>
                                    <p className='heading-3' style={{ lineHeight: '18px' }}>
                                        XEM GIỎ HÀNG
                                    </p>
                                    <ArrowForwardRoundedIcon />
                                </div>
                            </ButtonStyle>
                        </Button>
                    </SideSection>
                </div>
            </Container>
        </>
    )
}
export default Menu
