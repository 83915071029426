import { getBrandInfo } from './../brand/thunks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BrandInterface } from 'src/models/Brand'
import { formatStore, StoreInterface } from 'src/models/Store'
import { fetchStoreInfo } from './thunks'

interface StoreSliceInterface {
    storeList: Array<StoreInterface>
    selectedStore: StoreInterface
}
const initialState: StoreSliceInterface = {
    storeList: [],
    selectedStore: {
        name: '',
        lat: 0,
        lng: 0,
        latitude: 0,
        longitude: 0,
        phone: '',
        slug: '',
        address: '',
        id: '',
        distance: '',
        isOpen: false,
        _id: ''
    },
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        changeStore: (state, action: PayloadAction<StoreInterface>) => {
            state.selectedStore = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBrandInfo.fulfilled, (state, { payload }) => {
            state.storeList = payload.data.data.stores.map((s:any) => formatStore(s))
            state.selectedStore = payload.data.data.stores.map((s:any) => formatStore(s))[0]
        })
        builder.addCase(fetchStoreInfo.fulfilled, (state, { payload }) => {
            const selectedStore = formatStore(payload.data.data)

            state.storeList = payload.data.data.otherStores.map((s:any) => formatStore(s)).push(selectedStore)
            state.selectedStore = selectedStore
        })
    },
})
export const { changeStore } = storeSlice.actions
export default storeSlice.reducer
