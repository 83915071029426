import styled from 'styled-components'
import UnlinkIcon from 'src/assets/icons/unlink.svg'
import React, { useEffect, useState } from 'react'
import Background from 'src/components/Background'
import { CircularProgress } from '@mui/material'
import Loading from 'src/components/Loading'
import { _getConfig } from 'src/utils/api'
import { useHistory, useParams } from 'react-router'
import HomeMenu from 'src/pages/HomeMenu'
import { useAppDispatch } from 'src/store/hooks'
import { setDomain, setSlug } from 'src/store/features/majorBrand/slice'

const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 16px;
    > div.content {
        width: 100%;
    }
`
const Cover = styled.div`
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 96px;
    > img {
        height: 208px;
        width: 100%;
        object-fit: cover;
    }
`
const Brand = styled.div`
    cursor: pointer;
    margin-right: 16px;
    z-index: 2;
    background: #ebebeb;
    border-radius: 16px;
    overflow: hidden;
    width: 115px;
    height: 115px;
    display: flex;
    > img {
        width: 100%;
        object-fit: contain;
    }
`
interface BrandSiteConfig {
    logo: string
    slug: string
}
const BrandSite: React.FC = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const hostname: any = window.location.hostname != 'localhost' ? window.location.hostname : process.env.REACT_APP_PLSORDER_ORIGIN_HOSTNAME
    const [loading, setLoading] = useState<boolean>(false)
    const [coverUrl, setCoverUrl] = useState<string>('')
    const [brands, setBrands] = useState<Array<BrandSiteConfig>>([])
    const params = useParams<Record<string, string | undefined>>()
    const init = async () => {
        setLoading(true)
        let res
        if (hostname == process.env.REACT_APP_PLSORDER_ORIGIN_HOSTNAME) {
            res = await _getConfig('slug=' + (params.majorBrand || 'a') + '&type=major')
            dispatch(setSlug(params.majorBrand || ''))
        } else {
            res = await _getConfig('domain=' + hostname)
            dispatch(setDomain(hostname))
        }
        if (res.data.data.landing?.home?.url) {
            window.location.href = res.data.data.landing?.home?.url
            return
        }
        setCoverUrl(res.data.data.cover)
        const brs = res.data.data.subBrands
        let newBrs: Array<BrandSiteConfig> = []
        brs.forEach((br: any) => {
            let formatedBrand: BrandSiteConfig = {
                logo: br.logo,
                slug: br.slug,
            }
            newBrs.push(formatedBrand)
        })
        setBrands(newBrs)
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const onBrandClick = (slug: string) => {
            window.location.pathname == '/' ? history.push(`/${slug}`) : history.push(`${window.location.pathname}/${slug}`)
    }
    if (hostname != process.env.REACT_APP_PLSORDER_ORIGIN_HOSTNAME && window.location.pathname != '/') {
        return <HomeMenu />
    }
    if (loading) {
        return (
            <Wrap>
                <Loading />
            </Wrap>
        )
    }
    // if (hostname == 'plsorder.me') {
    //     return (
    //         <Wrap>
    //             <p style={{ margin: 16, fontWeight: 700 }}>Plsoder.me</p>
    //             <p>Develop by Caper team</p>
    //         </Wrap>
    //     )
    // }
    return (
        <Wrap className='container'>
            <Background />
            <div className='content'>
                <Cover>
                    <img src={coverUrl} />
                </Cover>
                <p className='heading-2'>Welcome,</p>
                <p style={{ margin: '8px 0px' }}>What brand can we serve you now?</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {brands.map((brand, index) => (
                        <Brand key={index} onClick={() => onBrandClick(brand.slug)}>
                            <img src={brand.logo} />
                        </Brand>
                    ))}
                </div>
            </div>
        </Wrap>
    )
}
export default BrandSite
