import { Box } from '@mui/system'
import {
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Arrow } from '../Home/BrandItem'
import { ReactComponent as Facebook } from '../../assets/icons/fb.svg'
import { ReactComponent as Instagram } from '../../assets/icons/insta.svg'
import { ReactComponent as Zalo } from '../../assets/icons/zalo.svg'
import { ReactComponent as Message } from '../../assets/icons/mes.svg'
import { ReactComponent as Telegram } from '../../assets/icons/tele.svg'
import styled from '@emotion/styled'
import { useLocation, useParams } from 'react-router-dom'
import { useBrand } from '../provider'
import { PLSORDER_DOMAIN } from 'src/utils/constants'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { useState } from 'react'

const IconButtonStyled = styled(IconButton)({
  width: 56,
  height: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F3F3F3',
  borderRadius: 0
})

interface ModalShareProps {
  handleClose: () => void
  name: string
  slug: string
  type: string
}

const ModalShare: React.FC<ModalShareProps> = ({ handleClose, name, slug, type }) => {
  const { brandSlug } = useParams<{ brandSlug: string }>()
  const location = useLocation()
  const { brand } = useBrand()
  const windowSize = useWindowSize()
  const [openTooltip, setOpen] = useState<boolean>(false)

  const defaultValue =
    ((brand?.domain && brand.domain[brand.domain.length - 1] === '/'
      ? brand.domain.substring(0, brand.domain.length - 1)
      : brand?.domain) || PLSORDER_DOMAIN + brandSlug) +
    '/thuc-don' +
    location.search

  const copyToClipboard = () => {
    navigator.clipboard.writeText(defaultValue)
    setOpen(true)
  }

  const onClickSocial = (link: string) => {
    window.open(link)
  }

  return (
    <Box>
      <Stack mb={2}>
        <Typography width="100%" justifyContent="center" display="flex" mb={2}>
          Chia sẻ đặt món từ {name} qua
        </Typography>

        <Stack spacing={3} direction="row" justifyContent="center" mb={3}>
          <IconButtonStyled
            onClick={() =>
              onClickSocial('http://www.facebook.com/sharer/sharer.php?u=' + defaultValue)
            }
          >
            <Facebook />
          </IconButtonStyled>

          {/* <IconButtonStyled
            onClick={() =>
              onClickSocial('https://www.instagram.com/direct/new/?link=' + defaultValue)
            }
          >
            <Instagram />
          </IconButtonStyled> */}

          {/* <IconButtonStyled
            onClick={() =>
              onClickSocial(
                'https://openapi.zalo.me/v2.0/article/create?access_token=579745863508352884&description=' +
                  defaultValue
              )
            }
          >
            <Zalo />
          </IconButtonStyled> */}

          <IconButtonStyled
            onClick={() =>
              onClickSocial(
                !windowSize?.isMobile
                  ? 'https://www.facebook.com/dialog/send?link=' +
                      defaultValue +
                      '&redirect_uri=https://www.facebook.com/' +
                      '&app_id=715545876191739'
                  : 'fb-messenger://share?link=' + defaultValue + '&app_id=715545876191739'
              )
            }
          >
            <Message />
          </IconButtonStyled>

          <IconButtonStyled
            onClick={() => onClickSocial('https://telegram.me/share/url?url=' + defaultValue)}
          >
            <Telegram />
          </IconButtonStyled>
        </Stack>

        <TextField
          id="outlined-basic"
          label="Sao chép đường dẫn"
          variant="outlined"
          defaultValue={defaultValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Tooltip
                    title="Copied"
                    open={openTooltip}
                    onClose={() => setOpen(false)}
                    PopperProps={{
                      disablePortal: true
                    }}
                  >
                    <IconButton size="small" onClick={copyToClipboard}>
                      <Typography
                        width="100%"
                        color="#F05123"
                        fontWeight="bold"
                        justifyContent="center"
                        display="flex"
                      >
                        Copy
                      </Typography>
                      <Arrow />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </InputAdornment>
            )
          }}
        />
      </Stack>

      <Box justifyContent="center" display="flex">
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ px: 5, py: 1, borderRadius: 2, background: '#39B54A', fontWeight: 'bold' }}
        >
          Xong
        </Button>
      </Box>
    </Box>
  )
}

export default ModalShare
