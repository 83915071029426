import React, { memo, useEffect, useState } from 'react'
import { List, ListItem, ListItemButton } from '@mui/material'
import styled from 'styled-components'
import ListImg from '../../assets/icons/black-list.svg'
import MenuItem from './MenuItem'
import { CateItem } from 'src/models/CateItem'
import { StoreInterface } from 'src/models/Store'
import { MenuItemCategory } from 'src/models/MenuItemCategory'
import { useParams } from 'react-router'
import { HubPageInterface } from 'src/models/HubPage'
import { useScrollspy } from 'src/utils/hooks/useScrollspy'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import useDebounce from 'src/utils/hooks/useDebonce'

interface Props {
  data: HubPageInterface
  top?: number
}

const SideMenuFn: React.FC<Props> = ({ data, top }) => {
  const [menu, setMenu] = useState<CateItem[]>()
  const params = useParams<{ hubSlug: string; brandSlug: string }>()
  const [ids, setIds] = useState<string[]>([])
  const [activeId, setActiveId] = useState<string>('')
  const windowSize = useWindowSize()

  const selectItem = (menuIndex: number, itemIndex: number) => {
    if (!menu) return
    menu.forEach((cateItem: CateItem, index: number) => {
      cateItem.child?.forEach((v: CateItem) => {
        v.open = false
      })
      if (index !== menuIndex) {
        cateItem.open = false
      }
    })
    let id: string | undefined
    const m = menu[menuIndex]
    if (m && m.child) {
      const item = m.child[itemIndex]
      m.child[itemIndex].open = true
      id = item._id
    }
    if (!id) return
    var rect = document?.getElementById(id)?.getBoundingClientRect()
    if (rect) {
      let top =
        rect.top + window.scrollY - (windowSize?.height ? windowSize?.height / 2 - 170 : 170 * 2)
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'auto'
      })
    }
  }

  useEffect(() => {
    if (data && data.stores) {
      const arrIds: string[] = []
      if (data.activeStore) {
        !!data.activeStore.categories &&
          arrIds.push(...data.activeStore.categories.map((cate: MenuItemCategory) => `${data.activeStore!.brand?._id || ''}-${cate._id}`))
        setMenu([
          {
            name: data.activeStore?.brand?.name,
            open: true,
            slug: data.activeStore?.brand?.slug,
            _id: data._id,
            child: data.activeStore?.categories?.map((cate: MenuItemCategory) => ({
              _id: `${data.activeStore?.brand?._id || ''}-${cate._id}`,
              name: cate.name
            }))
          }
        ])
      } else {
        const menu = data?.stores.map((store: StoreInterface) => {
          if (store?.categories) {
            arrIds.push(...store.categories.map((cate: MenuItemCategory) => `${store.brand?._id || ''}-${cate._id}`))
          }

          return {
            name: store.brand?.name,
            open: store.brand?.slug === params.brandSlug,
            slug: store.brand?.slug,
            _id: store.brand?._id,
            child: store?.categories?.map((cate: MenuItemCategory) => ({
              _id: `${store.brand?._id || ''}-${cate._id}`,
              name: cate.name
            }))
          }
        })
        setMenu(menu)
      }
      setIds(arrIds)
    }
  }, [data])

  const scrollActiveId = useScrollspy(ids, windowSize.height && windowSize.height / 2)

  const debouncedValue = useDebounce<string>(scrollActiveId, 10)
  useEffect(() => {
    if (scrollActiveId) {
      setActiveId(scrollActiveId)
    }
  }, [debouncedValue])
  return (
    <Wrap style={{ top, height: `calc(100vh - ${(top || 0) + 82}px)`, marginBottom: 16 }}>
      <div style={{height: '100%', overflow: 'scroll'}}>
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component="nav"
          subheader={
            <ListHeader>
              <ListIcon />
              Thực đơn
            </ListHeader>
          }
        >
          {!data.activeStore &&
            menu &&
            menu.map((item: CateItem, index: number) => (
              <MenuItem
                data={item}
                index={index}
                key={index}
                selectItem={selectItem}
                setActiveId={setActiveId}
                activeId={activeId}
              />
            ))}
          {data.activeStore &&
            menu &&
            menu[0] &&
            menu[0].child &&
            menu[0].child.map((item: CateItem, index: number) => (
              <ListItemStyled
                disablePadding
                key={item._id}
                onClick={() => {
                  selectItem(0, index)
                  setActiveId(item._id as string)
                }}
              >
                <ListItemButton selected={activeId === item._id} component="a">
                  <div style={{ fontSize: 14 }}>{item.name}</div>
                </ListItemButton>
              </ListItemStyled>
            ))}
        </List>
      </div>
    </Wrap>
  )
}
const Wrap = styled.div`
  padding: 16px 12px;
  background: white;
  border-radius: 16px;
  position: sticky;
`
const ListHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
`
const ListIcon = styled.div`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ListImg}');
  margin-right: 7px;
`

const ListItemStyled = styled(ListItem)`
  & > .Mui-selected {
    background-color: #eefff4 !important;
  }
`

const SideMenu = memo(SideMenuFn)
export default SideMenu
