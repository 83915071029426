import React, {useEffect} from 'react'
import styled from 'styled-components'
import PointImg from 'src/assets/icons/point.svg'
import ClockImg from 'src/assets/icons/clock.svg'
import ArrowDownImg from 'src/assets/icons/arrow-down.svg'
import ArrowDownImgBlack from 'src/assets/icons/arrow-down-black.svg'
import { OpenTime } from 'src/models/OpenTime'
interface Props {
  name: string
  address: string
  open?: boolean
  todayTime?: OpenTime
  brand?: string
  openPickStore: Function
}

const InforDetail: React.FC<Props> = ({name, address, open, todayTime, brand, openPickStore}) => {
  return (
      <Content>
          <WrapName>
              {brand && <Brand>{brand}</Brand>}
              <H1 onClick={() => openPickStore()}>{name} {brand && <ArrowDown></ArrowDown>}</H1>
          </WrapName>
          <div>
            <DetailItem>
                <AddressIcon></AddressIcon>
                {address}
            </DetailItem>
            <DetailItem>
                <ClockIcon></ClockIcon>
                {open ? 
                    <>  
                      Giờ phục vụ: {todayTime?.times.map((time, index) => `${time[0]} - ${time[1]}${todayTime && index < todayTime?.times.length - 1 ? ', ' : ''}`)}
                    </> 
                : 'Đang đóng cửa'}
            </DetailItem>
          </div>
      </Content>
  )
}
const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Brand = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  height: 32px;
  color: white;
  text-transform: capitalize;
  @media (max-width: 899px){
      height: auto;
      font-size: 14px;
      line-height: 20px;
      color: black;
      font-weight: 400;
  }
`

const H1 = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  @media (max-width: 899px){
      font-size: 16px;
      line-height: 24px;
      color: black;
  }
`

const DetailItem = styled.div`
  display: flex;
  margin-top: 15px;
  @media (max-width: 899px) {
    display: none;
  }
  @media (max-width: 599px){
      font-size: 14px;
      line-height: 20px;
  }
`

const AddressIcon = styled.div`
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${PointImg}');
  margin-right: 10px;
  flex-shrink: 0;
`
const WrapName = styled.div`
  @media (max-width: 899px){
      display: flex;
      flex-direction: column;
      align-items: center;
  }
`
const ClockIcon = styled.div`
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ClockImg}');
  margin-right: 10px;
  flex-shrink: 0;
`

const ArrowDown = styled.div`
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${ArrowDownImg}');
  cursor: pointer;
  margin-left: 10px;
  @media (max-width: 899px){
      background-image: url('${ArrowDownImgBlack}');
  }
`
export default InforDetail