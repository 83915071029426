import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { formatCurrency } from 'src/utils/myUtils';
import CloseIcon from '@mui/icons-material/Close';
import { OptionChoice } from 'src/models/OptionChoice';
interface Props {
  data: OptionChoice,
  onIncrease: () => void
  onClear: () => void
}
const Item: React.FC<Props> = ({data, onIncrease, onClear}) => {
  return (
    <Wrap onClick={() => onIncrease()} style={{fontWeight: data.quantity ? 'bold' : 'normal'}}>
      <Number>{data.quantity ? data.quantity + ' x' : '' }</Number>
      <Name>{data.name}</Name>
      <Price>+{formatCurrency(data.price !== undefined ? data.price : 0)}</Price>
      <IconWrap>
        {data.quantity ? <WrapClose onClick={(e) => {
          onClear();
          e.stopPropagation();
        }}>
          <Close/>
        </WrapClose>: ''}
      </IconWrap>
    </Wrap>
  );
};
const WrapClose = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '14px 0px'
})
const Wrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 14,
  borderBottom: '1px solid #eeeeee',
  cursor: 'pointer'
})

const Number = styled('div')({
  minWidth: 30,
  flexShrink: 0,
  color: '#F05123',
  marginRight: 3
})

const Price = styled('div')({
  display: 'flex',
})

const IconWrap = styled('div')({
  display: 'flex',
  width: 30,
  justifyContent: 'right',
  alignItems: 'center',

})

const Close = styled(CloseIcon)({
  display: 'flex',
  width: 16,
  height: 16,
  backgroundColor: '#EB5757',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  color: 'white',
  cursor: 'pointer'
  
})

const Name = styled('div')({
  flexGrow: 1,
  padding: '14px 0px'
})

export default Item;
