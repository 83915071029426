import styled from 'styled-components'
import Cover from 'src/assets/images/cover.png'
import React, { useEffect, useState } from 'react'
import { useScrollPosition } from 'src/utils/hooks/useScrollPosition'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { useAppSelector } from 'src/store/hooks'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import HalfScreen from 'src/components/Modal/HalfScreen'
import Map from 'src/components/Map'
import { calculateDistance } from 'src/utils/myUtils'
import { _getSchedule } from 'src/utils/api'
import { formatSchedule, Schedule } from 'src/models/Schedule'
import { WEEKDAY } from 'src/utils/constants'
import { useHistory } from 'react-router'

const Wrap = styled.div<{ height: number; image?: string }>`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: ${(props) => props.height + 'px'};
    background-size: cover;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px ${(props) => 16 * ((props.height - 56) / 118)}px
        ${(props) => 16 * ((props.height - 56) / 118)}px;
    overflow: hidden;
    .cover-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    > div {
        max-height: 87px;
    }
    > div.top {
        height: ${(props) => props.height - 87 + 'px'};
        > div {
            position: absolute;
            top: 16px;
            left: 16px;
            > svg {
                border-radius: 50%;
                padding: 2px;
                background: rgba(
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    1
                );
                color: rgba(
                    ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 1 : 1 - 0.5 * (1 - (props.height - 56) / 31))}
                );
            }
        }
    }
    > div.bot {
        background: linear-gradient(180deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 100%) !important;
        padding-left: ${(props) => 16 + 41 * (1 - (props.height - 56) / 118) + 'px'};
        background: linear-gradient(
            180deg,
            rgba(
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 1 - (props.height - 56) / 31)}
                )
                0%,
            rgba(
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    ${(props) => (props.height > 87 ? 0 : 255 * (1 - (props.height - 56) / 31))},
                    1
                )
                100%
        ) !important;
    }
    p {
        color: rgba(
            ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
            ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
            ${(props) => (props.height > 87 ? 255 : 255 * ((props.height - 56) / 31))},
            ${(props) => (props.height > 87 ? 1 : 1 - 0.5 * (1 - (props.height - 56) / 31))}
        );
    }
    p.label {
        font-size: ${(props) => (props.height > 87 ? '14px' : 14 - 2 * (1 - (props.height - 56) / 31) + 'px')};
        line-height: 16px;
    }
    p.brand {
        margin-right: 32px;
        font-size: ${(props) => (props.height > 87 ? '24px' : 24 - 6 * (1 - (props.height - 56) / 31) + 'px')};
        line-height: ${(props) => (props.height > 87 ? '32px' : 32 - 8 * (1 - (props.height - 56) / 31) + 'px')};
        font-weight: bold;
    }
    > .info {
        position: absolute;
        opacity: ${(props) => (props.height > 87 ? 1 : (props.height - 56) / 31)};
        bottom: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
            color: #fff;
            font-size: 20px;
        }
    }
`
const Brand = styled.div`
    flex: 1;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const ModalWrapper = styled.div`
    > .map {
        height: 207px;
    }
    > div:not(.map) {
        padding: 16px;
    }
    .schedule {
        display: flex;
        > .label {
            width: 80px;
            > p {
                line-height: 24px;
            }
        }
        .time {
            background: rgba(107, 7, 114, 0.2);
            border: 1px solid #6b0772;
            border-radius: 8px;
            padding: 2px 4px;
            margin: 0px 16px 16px 0px;
        }
        .no-time {
            border: 1px solid #6b0772;
            border-radius: 8px;
            padding: 2px 4px;
            margin: 0px 16px 16px 0px;
        }
    }
`
const Header = () => {
    const history = useHistory()
    const [height, setHeight] = useState(174)
    const [scheduleData, setScheduleData] = useState<Schedule | null>(null)
    useScrollPosition(
        ({ prevPos, currPos }) => {
            let y = Math.floor(Math.abs(currPos.y))
            if (y <= 118) {
                setHeight(174 - y)
            } else {
                setHeight(56)
            }
        },
        [height]
    )

    const backHandler = () => {
        history.goBack()
    }

    const storeData = useAppSelector((state) => state.store)
    const brandData = useAppSelector((state) => state.brand)
    const orderData = useAppSelector((state) => state.order)
    const [open, setOpen] = useState<boolean>(false)
    const [dis, setDis] = useState<string>('')
    const calculateDis = (pos: any) => {
        const myLocation = { lat: pos.coords.latitude, lng: pos.coords.longitude }
        calculateDistance(myLocation, { lat: storeData.selectedStore?.lat, lng: storeData.selectedStore?.lng }, (d) =>
            setDis(d)
        )
    }
    const getSchedule = async () => {
        const s = await _getSchedule(storeData.selectedStore?.id || '')
        setScheduleData(formatSchedule(s.data.data))
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(calculateDis)
        getSchedule()
    }, [])
    return (
        <Wrap className='limit-width' height={height}>
            <img className='cover-image' src={brandData?.cover || Cover} />
            <div className='top'>
                <div onClick={backHandler}>
                    <ArrowBackRoundedIcon />
                </div>
            </div>
            <Brand className='bot'>
                <p className='label'>Order from</p>
                <p className='brand'>{brandData?.name}</p>
            </Brand>
            <div className='info' onClick={() => setOpen(true)}>
                <InfoOutlinedIcon />
            </div>
            <HalfScreen open={open} onClose={() => setOpen(false)} height={90} defaultClose>
                <ModalWrapper>
                    <div className='map'>
                        <Map
                            origin={{
                                lat: storeData.selectedStore?.lat || 0,
                                lng: storeData.selectedStore?.lng || 0,
                            }}
                        />
                    </div>
                    <div>
                        <p className='heading-3'>{brandData?.name}</p>
                        <p style={{ color: '#999' }}>{storeData.selectedStore?.address}</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {storeData.selectedStore?.isOpen ? (
                                <p className='open' style={{ fontWeight: 'bold', color: '#3DBD00' }}>
                                    Đang mở cửa
                                </p>
                            ) : (
                                <p className='close' style={{ fontWeight: 'bold', color: '#E02020' }}>
                                    Tạm nghỉ
                                </p>
                            )}
                            <p>{dis}</p>
                        </div>
                        <p className='heading-3' style={{ margin: '16px 0px 8px' }}>
                            Giới thiệu
                        </p>
                        <p>{brandData?.intro}</p>
                        <p className='heading-3' style={{ margin: '16px 0px 8px' }}>
                            Giờ hoạt động
                        </p>
                        {scheduleData
                            ? [...Array(7).keys()].map((i) => {
                                  return (
                                      <div className='schedule' key={i}>
                                          <div className='label'>
                                              <p>{WEEKDAY[i]}</p>
                                          </div>
                                          <div style={{ display: 'flex' }}>
                                              {scheduleData[i].length > 0 ? (
                                                  scheduleData[i].map((time: any, index: number) => (
                                                      <p key={index} className='time'>
                                                          {time}
                                                      </p>
                                                  ))
                                              ) : (
                                                  <p className='no-time'>Nghỉ bán</p>
                                              )}
                                          </div>
                                      </div>
                                  )
                              })
                            : null}
                    </div>
                </ModalWrapper>
            </HalfScreen>
        </Wrap>
    )
}
export default React.memo(Header)
