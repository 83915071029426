import React, { ReactElement } from 'react'
import styled from 'styled-components'
const Container = styled.div`
    position: fixed;
    bottom: -120px;
    width: 100%;
    padding: 0px 16px;
    transition: all 0.3s ease-in-out;
    &.show {
        bottom: 24px;
    }
`
const Wrap = styled.div`
    background: ${(props) => props.theme.tintColor};
    border: 1px solid #360167;
    border-radius: 16px;
    padding: 13px 16px;
    z-index: 99;
    width: 100%;
    cursor: pointer;
    > p {
        color: #fff;
    }
`

interface Props {
    shouldDisplay?: boolean
    children?: JSX.Element[] | ReactElement
    btnStyle?: React.CSSProperties
    onClick?: () => void
}
const FloatingButton: React.FC<Props> = (props) => {
    return (
        <Container className={`limit-width ${props.shouldDisplay ? 'show' : ''}`}>
            <Wrap style={props.btnStyle} onClick={props.onClick}>{props.children}</Wrap>
        </Container>
    )
}
export default FloatingButton
