import { Avatar, Button, IconButton, Popover, Typography } from '@mui/material'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { ReactComponent as UserIcon } from 'src/assets/images/user.svg'
import { ReactComponent as LogOutIcon } from 'src/assets/icons/log-out.svg'
import { ReactComponent as MyOrderIcon } from 'src/assets/icons/my-order.svg'
import { ReactComponent as MyUserIcon } from 'src/assets/icons/user.svg'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SideScreen from '../Modal/SideScreen'
import MyOrder from '../MyOrder'
import styled from '@emotion/styled'
import { Box } from '@mui/system'

export const ButtonStyled = styled(Button)({
  width: '100%',
  padding: 16,
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  ':hover': {
    backgroundColor: '#F0FFF3'
  },
  gap: 12,
  justifyContent: 'start',
  textTransform: 'none'
})

const PopupUser: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openMyOrder, setOpenMyOder] = useState<boolean>(false)
  const windowSize = useWindowSize()
  const { brandSlug } = useParams<{ brandSlug: string }>()
  const history = useHistory()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'user-popover' : undefined

  const handleClickUser = () => {
    if (windowSize && windowSize.width && windowSize.width >= 600) {
      setOpenMyOder(true)
    } else {
      history.push(`/${brandSlug}/don-hang`)
    }
    handleClose()
  }
  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          borderRadius: 2,
          mr: {
            md: 3,
            xs: 1
          }
        }}
      >
        <UserIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: { borderRadius: 4, width: '100%', maxWidth: 273 }
        }}
      >
        <ButtonStyled>
          <Avatar
            alt=""
            src={require('../../assets/images/avatar.svg').default}
            sx={{ width: 56, height: 56, border: '1px solid #00000033' }}
          />
          <Box textAlign="start">
            <Typography variant="body1" fontWeight="bold" color={'#F05123'}>
              Đăng nhập
            </Typography>
            <Typography fontSize={12}>để có trải nghiệm tốt hơn</Typography>
          </Box>
        </ButtonStyled>

        <ButtonStyled>
          <MyUserIcon />
          <Typography>Tài khoản</Typography>
        </ButtonStyled>

        <ButtonStyled onClick={handleClickUser}>
          <MyOrderIcon/>
          <Typography>Đơn hàng của bạn</Typography>
        </ButtonStyled>

        {/* <ButtonStyled>
          <LogOutIcon />
          <Typography>Đăng xuất</Typography>
        </ButtonStyled> */}
      </Popover>

      <SideScreen open={openMyOrder} onClose={() => setOpenMyOder(false)} defaultClose={true}>
        <MyOrder />
      </SideScreen>
    </>
  )
}

export default PopupUser
