import React, { useState } from 'react'
import styled from 'styled-components'
import { OutlinedButton } from 'src/components/Button/OutlinedButton'
import { formatCurrency } from 'src/utils/myUtils'
import { useCart } from 'src/components/Cart/CartContext'
import SideScreen from 'src/components/Modal/SideScreen'
import ItemDetail from 'src/components/ItemDetail'
import ItemDetailProvider from 'src/components/ItemDetail/ItemDetailContext'
import mStyled from '@emotion/styled'
import { Box } from '@mui/system'
import flyToCart from 'src/utils/flyToCart/flyToCart'
import Img from 'src/components/Img'
import OutletModal from './OutletModal'
import { useParams } from 'react-router-dom'
import { LocationInterface } from "src/models/Location"
import { StoreInterface } from 'src/models/Store'
import { useHistory } from 'react-router'
import { useBrand } from '../provider'
import StoreChecker from 'src/components/Cart/StoreChecker'
import { store } from 'src/store'
import { StoreOrder } from 'src/models/Cart'

const Wrap = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  background: white;
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`
const WrapImg = styled.div`
  position: relative;
  overflow: hidden;
  > img {
    width: 100%;
    object-fit: cover;
    height: 266.93px;
    transition: transform 0.3s;
    @media (max-width: 899px) {
      height: auto;
    }
  }
`
const Hot = styled.div`
  position: absolute;
  left: 12px;
  top: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  font-size: 12px;
  color: #f05123;
  width: 85px;
  height: 24px;
  line-height: 24px;
  text-align: center;
`
const Content = styled.div`
  padding: 8px 12px 16px;
`

const Brand = styled.div`
  color: ${(props) => props.theme.mainColor};
  font-size: 14px;
  display: -webkit-box;
  height: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 599px) {
    font-size: 12px;
    height: 15.2px;
  }
`

const Name = styled.div`
  font-size: 24px;
  font-weight: 700;
  display: -webkit-box;
  line-height: 32px;
  height: 64px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 599px) {
    font-size: 14px;
    height: 60px;
    line-height: 20px;
    -webkit-line-clamp: 3;
  }
`
const Price = styled.span`
  font-weight: 700;
  margin-right: 8px;
`
const OldPrice = styled.span`
  text-decoration-line: line-through;
  color: #100b00;
  opacity: 0.2;
`

const WrapPrice = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  @media (max-width: 599px) {
    font-size: 14px;
  }
`
interface ProductItemProps {
  item: any
  brandIndex?: number
  configIndex?: number
}

export const BallBox = mStyled(Box)({
  width: 30,
  height: 30,
  backgroundColor: '#39B54A',
  borderRadius: '50%',
  display: 'none'
})

const ProductItem: React.FC<ProductItemProps> = ({ item, brandIndex, configIndex }) => {
  const history = useHistory()
  const { addToCart } = useCart()

  const { pre } = useBrand()

  const [open, setOpen] = useState<boolean>(false)
  const [showSelectOutlet, setShowSelectOutlet] = useState<boolean>(false)
  const [selectOutletCB, setSelectOutletCB] = useState<Function | undefined>()
  const [openStoreCheck, setOpenStoreCheck] = useState<boolean>(false)
  const [handleStoreCheck, setHandleStoreCheck] = useState<() => void>(() => {})
  const [storeToCheck, setStoreToCheck] = useState<any>()

  const { hubs } = useBrand()

  const storeHubs: LocationInterface[] = []
  hubs.length && item.stores.forEach((store: StoreInterface) => {
    const storeHub = hubs.find((hub) => {
      if (!hub.stores) {
        return hub._id === store._id
      }

      return hub.stores.find((hubStore) => hubStore._id === store._id)
    })

    storeHub && storeHubs.push(storeHub)
  })

  const handleAddToCart = () => {
    const it = { ...item, quantity: 1, selected: true }
    if (it.options && it.options.length > 0) {
      setOpen(true)
    } else if (item.stores.length === 1) {
      const storeHub = storeHubs[0]
      setHandleStoreCheck(() => () => {
        addToCart(it, {
          _id: storeHub._id,
          name: storeHub.name,
          slug: storeHub.slug,
          type: storeHub.brand ? 'st' : 'h',
          address: storeHub.address,
          brand: storeHub.brand?._id || storeHub._id,
          location: {lat: storeHub.latitude, lng: storeHub.longitude},
          open: !!storeHub.open,
          openTimes: storeHub.openTimes || [],
        });
        flyToCart(configIndex + '-' + it._id)
        history.push(`${pre}/thuc-don?${storeHub.brand ? 'st' : 'h'}=${storeHub.slug}`)
      })
      setStoreToCheck(storeHub)
      setOpenStoreCheck(true)
    } else {
      setSelectOutletCB(() => (storeHub: LocationInterface) => {
        addToCart(it, {
          _id: storeHub._id,
          name: storeHub.name,
          slug: storeHub.slug,
          type: storeHub.brand ? 'st' : 'h',
          address: storeHub.address,
          brand: storeHub.brand?._id || storeHub._id,
          location: {lat: storeHub.latitude, lng: storeHub.longitude},
          open: !!storeHub.open,
          openTimes: storeHub.openTimes || [],
        });
        flyToCart(configIndex + '-' + it._id)
      })
      setShowSelectOutlet(true)
    }
  }

  const handleCloseSelectOutlet = () => {
    setShowSelectOutlet(false)
  }

  const handleSelectOutlet = (storeHub: LocationInterface) => {
    setHandleStoreCheck(() => () => {
      selectOutletCB && selectOutletCB(storeHub)
      history.push(`${pre}/thuc-don?${storeHub.brand ? 'st' : 'h'}=${storeHub.slug}`)
    })
    setStoreToCheck(storeHub)
    setOpenStoreCheck(true)
  }

  const handleSelectItemDetail = (cb: Function) => {
    setSelectOutletCB(() => (storeHub: LocationInterface) => {
      cb({
        _id: storeHub._id,
        name: storeHub.name,
        slug: storeHub.slug,
        type: storeHub.brand ? 'st' : 'h',
        address: storeHub.address,
        brand: storeHub.brand?._id || storeHub._id,
        location: {lat: storeHub.latitude, lng: storeHub.longitude},
        open: !!storeHub.open,
        openTimes: storeHub.openTimes || [],
      })
    })
    setShowSelectOutlet(true)
  }

  return (
    <Wrap>
      <div
        style={{ cursor: 'pointer' }}
        onClick={(e) => handleAddToCart()}
        id={`${configIndex}-${item._id}`}
        className="fly-to-cart"
      >
        <WrapImg>
          <Img src={item.image} />
          <Hot>🔥Bán chạy</Hot>
        </WrapImg>
        <Content>
          <BallBox className={`ball-${configIndex}-${item._id}`} />
          <Brand>{item?.brand?.name}</Brand>
          <Name>{item.name}</Name>
          <WrapPrice>
            <Price>{formatCurrency(item.price)}</Price>
            {/* <OldPrice>{formatCurrency(item.price)}</OldPrice> */}
          </WrapPrice>

          <OutlinedButton variant="outlined">Đặt món</OutlinedButton>
        </Content>
      </div>
      <SideScreen open={open} onClose={() => setOpen(false)} defaultClose={true}>
        <ItemDetailProvider item={{ ...item, quantity: 1, selected: true }}>
          <ItemDetail
            onClose={() => {
              setOpen(false)
            }}
            configIndex={configIndex}
            handleSelect={handleSelectItemDetail}
          />
        </ItemDetailProvider>
      </SideScreen>
      <OutletModal
        open={showSelectOutlet}
        name={item.brand?.name || item.name}
        onClose={handleCloseSelectOutlet}
        onSelect={handleSelectOutlet}
        hubs={storeHubs}
      />
      {storeToCheck && <StoreChecker
        open={openStoreCheck}
        store={{
          _id: storeToCheck._id,
          name: storeToCheck.name,
          slug: storeToCheck.slug,
          url: `${pre}/thuc-don?${storeToCheck.brand ? 'st' : 'h'}=${storeToCheck.slug}`
        }}
        onOk={handleStoreCheck}
        onClose={() => {
          setOpenStoreCheck(false)
        }}
      />}
    </Wrap>
  )
}

export default ProductItem
