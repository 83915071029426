import React from 'react'
import { useState } from 'react'
import DishPlaceholder from 'src/assets/images/dishplaceholder.png'
import ItemPicker from 'src/components/ItemPicker'
import HalfScreen from 'src/components/Modal/HalfScreen'
import { MenuItem as ItemInterface } from 'src/models/MenuItem'
import { OrderItem } from 'src/models/OrderItem'
import { addItem, removeItem } from 'src/store/features/order/slice'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { formatCurrency } from 'src/utils/myUtils'
import styled from 'styled-components'
import Detail from './Detail'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
interface MenuItemInterface {
    item: ItemInterface
    categoryId: string | null
}

const Wrap = styled.div`
    padding: 16px 0px;
    display: flex;
    border-bottom: 1px solid rgba(89, 89, 89, 0.2);
    > img {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 40px;
        height: 40px;
    }
`
const Header = styled.div`
    display: flex;
    align-items: center;
    margin-left: -5px;
    padding: 16px;
    background: #fafafa;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99;
`
const MenuItem: React.FC<MenuItemInterface> = (props) => {
    const [detailPopupOpen, setDetailPopupOpen] = useState(false)
    const dispatch = useAppDispatch()
    const orderItems = useAppSelector((state) => state.order.items)
    let quantity = 0
    orderItems.forEach((o) =>
        o.itemId === props.item.id && o.categoryId === props.categoryId ? (quantity += o.quantity) : null
    )
    const onItemClick = () => {
        if (typeof props.item.options != 'undefined' && props.item.options.length > 0) {
            setDetailPopupOpen(true)
        }
    }
    const addItemHandler = () => {
        if (typeof props.item.options != 'undefined' && props.item.options.length > 0) {
            setDetailPopupOpen(true)
        } else {
            const orderItem: OrderItem = {
                itemId: props.item.id,
                note: '',
                quantity: 1,
                options: [],
                price: props.item.price,
                name: props.item.name,
                categoryId: props.categoryId,
            }
            dispatch(addItem(orderItem))
        }
    }
    const removeItemHandler = () => {
        dispatch(removeItem({ itemId: props.item.id, categoryId: props.categoryId }))
    }
    return (
        <>
            <Wrap>
                {props.item.image ? (
                    <img src={props.item.image.toString()} alt={props.item.name.toString()} />
                ) : (
                    <img src={DishPlaceholder} alt={props.item.name.toString()} />
                )}
                <div style={{ width: '100%', margin: '0px 8px' }} onClick={onItemClick}>
                    <p>{props.item.name}</p>
                    <p className='heading-4' style={{ marginTop: 4 }}>
                        {formatCurrency(props.item.price)}
                    </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ItemPicker value={quantity} onIncrease={addItemHandler} onDecrease={removeItemHandler} />
                </div>
            </Wrap>
            <HalfScreen open={detailPopupOpen} onClose={() => setDetailPopupOpen(false)}>
                <Header>
                    <CloseRoundedIcon
                        onClick={() => setDetailPopupOpen(false)}
                        style={{ fontSize: 24, color: '#475f7b', marginRight: 12 }}
                    />
                    <p className='heading-3'>Thêm món</p>
                </Header>
                <Detail item={props.item} categoryId={props.categoryId} onClose={() => setDetailPopupOpen(false)} />
            </HalfScreen>
        </>
    )
}
export default MenuItem
