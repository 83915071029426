import { GOOGLE_MAP_KEY } from 'src/utils/constants'
import GoogleMapReact, { Coords } from 'google-map-react'
import SmallPinIcon from 'src/assets/icons/small-pin.svg'
import PinIcon from 'src/assets/icons/pin.svg'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AddressPos } from 'src/models/AddressPos'
import { _getLocationDetailFromLocation } from 'src/utils/api'

interface Props{
    pos: AddressPos,
    onPick: Function,
    apiLoaded: Function
}
const SMap: React.FC<Props> = ({pos, onPick, apiLoaded}) => {
    const [currentPosition, setCurrentPosition] = useState<Coords>({
        lat: 21.0278,
        lng: 105.8342
    });
    const [mapApi, setMapApi] = useState<any>();
    
    const apiHasLoaded = (map: any, mapApi: any) => {
        setMapApi(mapApi);
        apiLoaded(map, mapApi);
    }
    // useEffect(() => {
    //     setCurrentPosition({...pos.pos});
    // }, [pos])

    return (
        <GoogleMapReact
            bootstrapURLKeys={{key: GOOGLE_MAP_KEY}}
            defaultCenter={{lat: 21.0278, lng: 105.8342}}
            center={pos.pos}
            defaultZoom={16}
            onChange={async ({ center, zoom, bounds, marginBounds }) => {
                if (pos.pos.lat === center.lat && pos.pos.lng === center.lng) return
                const { data: location } = await _getLocationDetailFromLocation(`${center.lat},${center.lng}`)
                location.geometry.location = { lat: center.lat, lng: center.lng }
                onPick(location);
                // if (!mapApi) return;
                // const geocoder = new mapApi.Geocoder;
               
                // geocoder.geocode({ 'location': { lat: center?.lat, lng: center?.lng } }, (results: any, status: any) => {
                //     if (status === 'OK') {
                //         if (results[0]) {
                //             onPick(results[0]);
                //             // this.zoom = 12;
                //             // this.setState({ address: results[0].formatted_address });
                //         } else {
                //             console.log('No results found');
                //         }
                //     } else {
                //         console.log('Geocoder failed due to: ' + status);
                //     }

                // });
            }}
            options={{ zoomControlOptions: { position: 7 } }}
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
        </GoogleMapReact>
    )
}
const Marker = styled.div`
    background-repeat: no-repeat;
    border: 1px solid red;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    // margin-top: -24px;
    // margin-left: -24px;
`
const MarkerComponent = ({ selected, select, index }: { lat:number, lng: number, selected?: boolean, select: Function, index: number}) => 
    <Marker onClick={() => select(index)} style={{backgroundImage: `url(${selected ? PinIcon : SmallPinIcon})`, width: selected ? '48px' : '24px', height: selected ? '48px' : '24px'}}></Marker>;
export default SMap
