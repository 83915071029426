import CircularProgress from '@mui/material/CircularProgress'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import React, { useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import { useHistory } from 'react-router-dom'
import FloatingButton from 'src/components/Button/FloatingButton'
import Header from 'src/components/Header'
import Input from 'src/components/Input'
import FullScreen from 'src/components/Modal/FullScreen'
import { OrderItem } from 'src/models/OrderItem'
import {
    changeCusAddress,
    changeCustomerEmail,
    changeCustomerName,
    changeCustomerPhone,
    clearState,
    removeItem,
} from 'src/store/features/order/slice'
import { saveOrder } from 'src/store/features/order/thunks'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { arrayEqual, formatCurrency } from 'src/utils/myUtils'
import styled from 'styled-components'
import SuccessOrder from './SuccessOrder'

import InstantMenuGlobalStyles from '../instantSell'
const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    flex: 1;
    position: relative;
    padding-top: 56px;
`
const ItemSection = styled.div`
    background: #fff;
    padding: 16px;
    color: #475f7b;
    div.d-f {
        display: flex;
        align-items: center;
    }
    > div.header {
        margin: -16px -16px 0px -16px;
        position: sticky;
        top: 56px;
        background: #fff;
        z-index: 9;
        > svg {
            padding: 16px;
        }
    }
`
const ContactSection = styled.div`
    padding: 16px;
    > .title {
        opacity: 0.4;
        text-transform: uppercase;
        font-weight: bold;
    }
`
const Loading = styled.div`
    margin: -5px;
    display: flex;
    justify-content: center;
    > div {
        width: 24px !important;
        height: 24px !important;
        color: #fff;
    }
`
const CustomerInfo: React.FC = () => {
    const history = useHistory()
    const orderData = useAppSelector((state) => state.order)
    const dispatch = useAppDispatch()
    const [showList, setShowList] = useState<boolean>(false)
    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [disableOrderButton, setDisableOrderButton] = useState<boolean>(true)
    let price = 0
    let countOrder = 0
    let orderList: Array<OrderItem> = []
    orderData.items.forEach((item) => {
        countOrder += item.quantity
        price += item.price * item.quantity
        let foundItem = orderList.find((o) => o.itemId === item.itemId && arrayEqual(o.options, item.options))
        if (foundItem) {
            foundItem.quantity += item.quantity
        } else {
            const newItem = { ...item, categoryId: null }
            orderList.push(newItem)
        }
    })
    useEffect(() => {
        setDisableOrderButton(!(orderData.cusAddress && orderData.customerName && orderData.customerPhone))
    }, [orderData.cusAddress, orderData.customerName, orderData.customerPhone])
    useEffect(() => {
        if (countOrder === 0) {
            history.goBack()
        }
    }, [countOrder])
    useEffect(() => {
        if (orderData.saveSuccess) {
            setOpenSuccess(true)
        }
    }, [orderData])
    const orderHandler = () => {
        dispatch(saveOrder({ orderData: orderData }))
    }

    return (
        <>
            <InstantMenuGlobalStyles />
            <Header />
            <Wrap>
                <ItemSection>
                    <div className='d-f header'>
                        <ArrowBackRoundedIcon onClick={() => history.goBack()} />
                        <p className='heading-3'>Thông tin đặt hàng</p>
                    </div>

                    <Collapsible
                        transitionTime={200}
                        trigger={
                            <div className='d-f' style={{ justifyContent: 'space-between', marginBottom: 16 }}>
                                <p
                                    className='caption'
                                    style={{ textTransform: 'uppercase', fontWeight: 700, opacity: 0.4 }}>
                                    Tạm tính
                                </p>
                                <div className='d-f' onClick={() => setShowList(!showList)}>
                                    <p>{countOrder} món</p>
                                    {showList ? (
                                        <ExpandLessRoundedIcon style={{ width: 14, height: 14, marginLeft: 4 }} />
                                    ) : (
                                        <ExpandMoreRoundedIcon style={{ width: 14, height: 14, marginLeft: 4 }} />
                                    )}
                                </div>
                            </div>
                        }>
                        <div>
                            {orderList.map((item, index) => (
                                <ItemOrder key={index} {...item} />
                            ))}
                        </div>
                    </Collapsible>

                    <div
                        className='d-f'
                        style={{
                            justifyContent: 'space-between',
                            paddingTop: 16,
                            borderTop: '1px solid rgb(89 89 89 / 20%)',
                        }}>
                        <p>Tổng cộng</p>
                        <p className='heading-3'>{formatCurrency(price)}</p>
                    </div>
                </ItemSection>
                <ContactSection>
                    <p className='caption title'>Liên hệ</p>
                    <Input
                        required
                        value={orderData.customerName}
                        onChange={(value) => dispatch(changeCustomerName(value))}
                        label='Họ & Tên'
                        placeholder='Nguyễn Văn A'
                        name='fullname'
                    />
                    <Input
                        required
                        value={orderData.customerPhone}
                        onChange={(value) => dispatch(changeCustomerPhone(value))}
                        label='Số điện thoại'
                        placeholder='0987 654 321'
                        name='phone'
                        type='tel'
                    />
                    <Input
                        required
                        value={orderData.cusAddress}
                        onChange={(value) => dispatch(changeCusAddress(value))}
                        label='Địa chỉ giao hàng'
                        placeholder='Số 1 ngõ 234 Cầu Giấy, quận Cầu Giấy, Hà Nội'
                        name='address'
                    />
                    <Input
                        value={orderData.customerEmail}
                        onChange={(value) => dispatch(changeCustomerEmail(value))}
                        label='Email'
                        placeholder='mail@domain.com'
                        name='email'
                    />
                </ContactSection>
                <div style={{ minHeight: 122 }} />
                {disableOrderButton ? (
                    <FloatingButton
                        shouldDisplay={true}
                        btnStyle={{ borderRadius: 24, borderColor: '#F0E6F1', background: '#F0E6F1' }}>
                        <p style={{ fontWeight: 700, textAlign: 'center', color: '#FB8CAB' }}>Đặt hàng</p>
                    </FloatingButton>
                ) : (
                    <FloatingButton shouldDisplay={true} btnStyle={{ borderRadius: 24 }} onClick={orderHandler}>
                        {orderData.loading ? (
                            <Loading>
                                <CircularProgress />
                            </Loading>
                        ) : (
                            <p style={{ fontWeight: 700, textAlign: 'center' }}>Đặt hàng</p>
                        )}
                    </FloatingButton>
                )}
                <FullScreen
                    dialogClassName='limit-width'
                    open={openSuccess}
                    onClose={() => {
                        setOpenSuccess(false)
                    }}>
                    <div style={{ backgroundColor: '#fff' }}>
                        <SuccessOrder
                            onClose={() => {
                                dispatch(clearState())
                            }}
                        />
                    </div>
                </FullScreen>
            </Wrap>
        </>
    )
}

const ItemOrder: React.FC<OrderItem> = (props) => {
    const ItemWrap = styled.div`
        display: flex;
        margin-bottom: 16px;
        svg {
            color: #e73948;
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }
        .item-name {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 70%;
        }
        .caption {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 4px;
        }
    `
    let choiceOrder = ''
    if (props.options && props.options.length > 0) {
        props.options.forEach((op, oi) =>
            op.choices.forEach((c, ci) => {
                if (!(oi === 0 && ci === 0)) {
                    choiceOrder += ' + '
                }
                if (op.maxCount === 1) {
                    choiceOrder += c.name
                } else {
                    choiceOrder += c.quantity + 'x' + c.name
                }
            })
        )
    }
    const dispatch = useAppDispatch()
    return (
        <ItemWrap>
            <div
                style={{ display: 'flex', marginRight: 4, minWidth: ' max-content' }}
                onClick={() => dispatch(removeItem({ itemId: props.itemId }))}>
                <CloseRoundedIcon />
                <p>{props.quantity} x</p>
            </div>
            <div style={{ flex: 1, overflow: ' hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className='item-name'>{props.name}</p>
                    <p className='heading-4'>{formatCurrency(props.price * props.quantity || 0)}</p>
                </div>
                <p className='caption' style={{ opacity: 0.4 }}>
                    {choiceOrder}
                </p>
            </div>
        </ItemWrap>
    )
}

export default CustomerInfo
