import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import MenuItem from './MenuItem'
import FloatingButton from 'src/components/Button/FloatingButton'
import { formatCurrency } from 'src/utils/myUtils'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { useEffect } from 'react'
import { fetchMenuItems } from 'src/store/features/menu/thunks'
import { fetchStoreInfo } from 'src/store/features/store/thunks'
import Header from './Header'
import Stores from './Stores'
import { addItem } from 'src/store/features/order/slice'
import { OrderItem } from 'src/models/OrderItem'
import { useScrollPosition } from 'src/utils/hooks/useScrollPosition'
import SearchInput from 'src/components/SearchInput'
import { clearState } from 'src/store/features/order/slice'
import useWindowSize from 'src/utils/hooks/useWindowSize'
import { getBrandInfo } from 'src/store/features/brand/thunks'
import { MenuItem as ItemInterface } from 'src/models/MenuItem'
import HalfScreen from 'src/components/Modal/HalfScreen'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Detail from './MenuItem/Detail'

const Wrap = styled.div`
    background: ${(props) => props.theme.background};
    flex: 1;
    padding: 16px;
    padding-top: 190px;
`
const Notice = styled.div`
    margin-top: 16px;
    background: #fff5d8;
    padding: 8px;
    border-radius: 8px;
    max-height: 92px;
    > p.content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 51px;
        line-height: 17px;
        margin-top: 5px;
        font-size: 12px;
    }
`
const CategoryBar = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 54px;
    background: #fff;
    padding: 0px 16px;
    margin: -16px;
    z-index: 9;
    white-space: nowrap;
    > div.cate {
        display: flex;
        overflow: scroll;
        > a {
            margin: 0px 16px;
            text-decoration: none;
        }
        > a.active {
            border-bottom: 3px solid ${(props) => props.theme.tintColor};
        }
    }
`
const MenuSection = styled.div`
    margin-top: -112px;
    padding-top: 112px;
`
const CategoryLabel = styled.p`
    margin-top: 16px;
`
const ButtonStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    p,
    svg {
        color: #fff;
    }
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
            margin-left: 4px;
        }
    }
`
const PopupHeader = styled.div`
    display: flex;
    align-items: center;
    margin-left: -5px;
    padding: 16px;
    background: #fafafa;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99;
`
const Menu: React.FC = () => {
    const history = useHistory()
    const params = useParams<Record<string, string | undefined>>()

    const location = useLocation<string>()
    const query = queryString.parse(location.search)
    const menu = useAppSelector((state) => state.menu)
    const storeData = useAppSelector((state) => state.store)
    const orderItems = useAppSelector((state) => state.order.items)
    const [keyword, setKeyword] = useState<string>('')

    const [detailPopupOpen, setDetailPopupOpen] = useState<boolean>(false)
    const [sharedItemCate, setSharedItemCate] = useState<string>()
    const [sharedItem, setSharedItem] = useState<ItemInterface>()
    const [sectionActive, setSectionActive] = useState<number>(0)
    const [scrolling, setScrolling] = useState<boolean>(false)
    const windowSize = useWindowSize()
    const dispatch = useAppDispatch()
    let price = 0
    let countOrder = 0
    orderItems.forEach((item) => {
        countOrder += item.quantity
        if (item.price) {
            price += item.price * item.quantity
        }
    })

    useEffect(() => {
        if (menu.category.length > 0) {
            menu.category.map((c, index) => {
                c.items.map((item, index) => {
                    if (query.item == item.id) {
                        if (orderItems.some((i) => i.itemId == query.item)) {
                            return
                        }
                        setSharedItem(item)
                        setSharedItemCate(c.id || '')
                        setDetailPopupOpen(true)
                    }
                })
            })
        }
    }, [menu])
    useEffect(() => {
        const slug = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        dispatch(getBrandInfo(`slug=${slug}`))
    }, [])

    useEffect(() => {
        if (storeData.selectedStore.slug) {
            dispatch(fetchMenuItems(`slug=${storeData.selectedStore.slug}`))
        }
    }, [storeData.selectedStore.slug])
    const searchHandler = (value: string) => {
        setKeyword(value)
    }
    const nextHandler = () => {
        if (orderItems.length > 0) {
            history.push(`${window.location.pathname}/order-information`)
        }
    }
    useScrollPosition(() => {
        menu.category.forEach((c, index) => {
            let elementY = document.getElementById(`menu_section_${index}`)?.getBoundingClientRect().top || 99999
            if (windowSize.height && elementY < windowSize.height / 2 && !scrolling) {
                setSectionActive(index)
            }
        })
    }, [sectionActive, windowSize.height])
    const onClickMenuTab = (index: number) => {
        setScrolling(true)
        setTimeout(() => setScrolling(false), 2000)
        let bodyY = document.body.getBoundingClientRect().top
        let elementY = document.getElementById(`menu_section_${index}`)?.getBoundingClientRect().top || 99999
        window.scrollTo(0, Math.abs(bodyY - elementY))
    }

    return (
        <>
            <HalfScreen open={detailPopupOpen} onClose={() => setDetailPopupOpen(false)}>
                <PopupHeader>
                    <CloseRoundedIcon
                        onClick={() => setDetailPopupOpen(false)}
                        style={{ fontSize: 24, color: '#475f7b', marginRight: 12 }}
                    />
                    <p className='heading-3'>Thêm món</p>
                </PopupHeader>
                <>
                    {sharedItem && sharedItemCate ? (
                        <Detail
                            item={sharedItem}
                            categoryId={sharedItemCate}
                            onClose={() => setDetailPopupOpen(false)}
                        />
                    ) : null}
                </>
            </HalfScreen>
            <div style={{ position: 'relative' }}>
                <Header />
                <Wrap>
                    <Stores />
                    {storeData.selectedStore.announce && (
                        <Notice>
                            <p className='heading-4'>Thông báo</p>
                            <p className='content'>{storeData.selectedStore.announce}</p>
                        </Notice>
                    )}
                    <CategoryBar>
                        <SearchInput value={keyword} onChange={searchHandler} />
                        <div className='cate'>
                            {menu.category.map((c, index) => {
                                return (
                                    <a
                                        className={sectionActive == index ? 'active' : ''}
                                        key={index}
                                        onClick={() => {
                                            onClickMenuTab(index)
                                            setSectionActive(index)
                                        }}>
                                        <p>{c.name}</p>
                                    </a>
                                )
                            })}
                        </div>
                    </CategoryBar>
                    {menu.category.map((c, index) => {
                        return (
                            <MenuSection key={index} id={'menu_section_' + index}>
                                {c.items.filter((item) => item.name.toLocaleLowerCase().indexOf(keyword) > -1).length >
                                    0 && <CategoryLabel className='heading-3'>{c.name}</CategoryLabel>}
                                {c.items
                                    .filter((item) => item.name.toLocaleLowerCase().indexOf(keyword) > -1)
                                    .map((item, index) => {
                                        return <MenuItem key={index} item={item} categoryId={c.id} />
                                    })}
                            </MenuSection>
                        )
                    })}

                    {orderItems.length > 0 ? <div style={{ minHeight: 122 }} /> : null}
                </Wrap>
                <FloatingButton shouldDisplay={countOrder > 0} onClick={nextHandler}>
                    <ButtonStyle>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <p style={{ color: '#fff', marginBottom: 5, lineHeight: '17px' }}>{countOrder} món</p>
                            <p className='heading-3'>{formatCurrency(price)}</p>
                        </div>
                        <div>
                            <p className='heading-3'>XEM GIỎ HÀNG</p>
                            <ArrowForwardRoundedIcon />
                        </div>
                    </ButtonStyle>
                </FloatingButton>
            </div>
        </>
    )
}
export default Menu
